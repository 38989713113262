import React from 'react'
import { BrowserRouter as Router, NavLink, Route } from "react-router-dom"
import { useTranslation } from 'react-i18next'

// redux
import { useSelector } from 'react-redux'
import { getAuthenticated, getValidated } from './redux/auth/selectors'
// import { getPathwaysLoaded } from './redux/pathways/selectors'
import { getTaskCount, needsIntro, getUser } from './redux/users/selectors'

// Transitions
import { TransitionGroup, CSSTransition } from "react-transition-group";

// components
import PrivateRoute from './components/private-route'
import AnimatedSwitch from './components/animated-switch'
import LanguageDropdown from './components/dropdown-language'
import NavDropdown from './components/dropdown-nav'
import SplashScreen from './components/splash-screen'
import Overlay from './components/overlay'
import RoutedClass from './components/router-class'
import { BleepLogoText } from './components/bleep-logo'

// pages
import Login from './pages/login'
import Home from './pages/home'
import Intro from './pages/intro'
import Logout from './pages/logout'
import Pathways from './pages/pathways'
import Tasks from './pages/tasks'
import Bookmarks from './pages/bookmarks'
import Notes from './pages/notes'

// styles
import './css/App.scss'
import IframeResize from './components/iframe-resize'

/**
 * Get (Private)Routes from routes config
 * @param {*} routes 
 */
const getPageRoutes = routes => (
  routes.map( route => 
  {   
    if ( !route.component )
    {
      // don't render routes without components
      return null
    }
    else if ( route.private )
    {
      // render a private route
      return <PrivateRoute key={ route.path } exact={ route.exact } path={ route.path } component={ route.component } />
    }
    else
    {
      // render a public route
      return <Route key={ route.path } exact={ route.exact } path={ route.path } component={ route.component } />
    }
  } )
)

// get routes to be displayed in the navigation
const getNavRoutes = routes => routes ? routes.filter( route => route.nav ) : null

// get routes to be displayed in the intro ( just logout )
const getIntroRoutes = routes => routes ? routes.filter( route => route.path === '/logout' ) : null

// Export the bleep app
const App = ( props ) =>
{
  // get user stuff
  const authenticated = useSelector( getAuthenticated )
  const validated = useSelector( getValidated )
  const user = useSelector( getUser )

  // hooks
  const { t } = useTranslation()

  // decide which menu to show
  const showIntro = useSelector( needsIntro )
  
  // get task todo count
  const taskCount = useSelector( getTaskCount )

  // define routes inside of the component since we want to use the t() function
  const routes = [
    {
      path: '/login',
      exact: true,
      nav: false,
      private: false,
      name: t( 'nav.login', 'Login' ),
      component: Login,
    },
    {
      path: '/',
      exact: true,
      nav: true,
      private: true,
      name: t( 'nav.home', 'Home' ),
      component: Home,
      className: 'icon-home',
    },    
    // actual Settings item not shown in Nav, points to Intro component and has route params. Question marks = optional params
    {
      path: '/intro/:step?',
      exact: false,
      private: true,
      nav: false,
      name: t( 'nav.intro', 'Settings' ),
      component: Intro,
    },
    // Pathways component, not shown in Nav. Question marks = optional params
    {
      // question marks; optional params
      path: '/pathways/:pathway?/:step?',
      exact: false,
      private: true,
      nav: false,
      component: Pathways
    },
    {
      path: '/tasks/:task?',
      private: true,
      nav: true,
      name: t( 'nav.tasks', 'Tasks' ),
      counter: taskCount,
      component: Tasks,
      className: 'icon-check-on',
    },
    {
      path: '/bookmarks/',
      private: true,
      nav: true,
      name: t( 'nav.bookmarks', 'Favorites' ),
      component: Bookmarks,
      className: 'icon-fav-on',
    },
    {
      path: '/notes/',
      private: true,
      nav: user && user.use_notes,
      name: t( 'nav.notes', 'Notes' ),
      component: Notes,
      className: 'icon-notes',
    },
    
    // dummy Settings item, points to first step in the intro but has no component
    {
      path: '/intro/1',
      exact: false,
      private: true,
      nav: true,
      name: t( 'nav.intro', 'Settings' ),
      component: null, // just show it in nav, don't add to pages
      className: 'icon-cog'
    },
    {
      path: '/logout',
      private: true,
      nav: true,
      name: t( 'nav.logout', 'Logout' ),
      component: Logout,
      className: 'icon-logout',
    }
  ]

  // see if we have any pathways, can't show the intro without them
  // const pathwaysLoaded = useSelector( getPathwaysLoaded )

  // default className
  let className = 'BleepApp'

  // assume user is not logged in yet ..
  let nav_key = 'lang' 

  // .. so show language dropdown
  let nav_component = <LanguageDropdown/> 

  // check if user is authenticated
  if ( authenticated )
  {
    // do we need to show the intro? the no nav will be shown
    if ( showIntro )
    {
      nav_key = 'intro'
      // show a limited set of nav items in the dropdown
      nav_component = <NavDropdown routes={ getIntroRoutes( routes ) } /> 
    }
    else
    {
      // user is logged in and has done the intro so show the regular menu
      nav_key = 'nav'
      nav_component = <NavDropdown routes={ getNavRoutes( routes ) } /> 
    }
  }

  // render the app
  return (

    <div className={ className }>

      { /* Root router, required for all Routes & NavLinks in nested components */}
      <Router>        
    
        { /* Iframe resize script, uses location so should be inside router */ }
        <IframeResize/>

        {/* Ass a div with a classname with the current browser route */}
        <RoutedClass>

          { /* Ads a brush overlay (or underlay in this case) */ }
          <Overlay/>
          
          { /* Add page transitions, for ALL routes. Even those within nested components */}
          <AnimatedSwitch transition='page-transition'>

              { /* Get pages from routes config */ }
              { getPageRoutes( routes ) }

          </AnimatedSwitch>          

          { /* Animate nav, put it below the switch so it'll be shown OVER the pages (not under) */ }
          <TransitionGroup>
              <CSSTransition key={ nav_key } classNames='nav-transition' timeout={ 500 } >
                { nav_component }
              </CSSTransition>
          </TransitionGroup>                

          { /* Bleep logo in top-left corner */ }
          <NavLink to="/">
            <BleepLogoText className="app-logo"/>
          </NavLink>

        </RoutedClass>  

      </Router>

      <SplashScreen className={ validated ? 'hide' : 'show' } >{ t( 'home.loading', 'Loading ...' ) }</SplashScreen>

    </div>
  );
} 

export default App