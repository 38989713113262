import React from 'react'
import { Switch, withRouter } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"

const AnimatedSwitch = withRouter( ( { location, children, timeout = 500, transition = 'transition', animateNested = false, ...rest } ) => 
{  
  // when not animating nested routes, use the root pathname as animation key
  const path = animateNested ? location.pathname : location.pathname.split( '/' )[ 1 ]

  return (
    <TransitionGroup>
    { 
      /*  
        Add classNames twice, 
        the latter gets the -enter, -enter-done, etc classes appended to it 
        while the former stays the same and can be used as a base class for a transition 
      */
    }
    <CSSTransition key={ path } classNames={ `${transition} ${transition}` } timeout={ timeout } >
      <Switch location={ location }>
        { children }
      </Switch>
    </CSSTransition>
  </TransitionGroup>
  )
} )

export default AnimatedSwitch