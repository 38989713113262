import React, { useState, useRef } from 'react'

/**
 * Number in a circle for use in DropDown items, toggles, etc
 * @param {*} param0 
 */
export const DropdownNum = ( { num = 0 } ) => {
  // num could be a funcito
  let number = parseInt( 'function' === typeof num ? num() : num, 10 )
  if ( !isNaN( number ) && number > 0 )
  {
    return <div className="dropdown-num">{ number }</div>
  }
  return null
}

// dropdown toggle button
const DropDownToggle = ( { img_src = '', asset = null, text = '', num = 0, onClick } ) => 
{
  asset = img_src ? <img src={ img_src } alt={ text } /> : asset

  return (
    <div className="dropdown-toggle" onClick={ onClick }>
      { asset ? <div className="dropdown-toggle-img">{ asset }</div> : null }
      <div className="dropdown-toggle-text">{ text }</div>
      <div className="dropdown-toggle-icon">
        <DropdownNum num={ num }/>
      </div>
    </div>
  )
}

// container for the dropdown list items
const DropDownList = ( { items } ) => 
{
  // key counter for items
  let dropDownCount = 0

  const children = items.map( item => {
    // get selectable props from, item
    const { value = '', label = '', asset = null } = item.props
    return <DropDownItem key={ dropDownCount++ } value={ value } label={ label } asset={ asset }>{ item }</DropDownItem> 
  } ) 

  return (
    <div className="dropdown-list">{ children }</div>
  )
}

// container for the dropdown list items
const DropDownItem = ( { children = [] } ) => (
  <div className="dropdown-item">
    { children }
  </div>
)

// dropdown base features
export const Dropdown = ( props ) => 
{
  // set open state and open toggle method
  const [ isOpen, setIsOpen ] = useState( false )
  const toggleDropdown = () => setIsOpen( !isOpen )

  // get props
  const { 
    toggle_asset = null,
    toggle_img_src = '',
    toggle_text = '',
    toggle_num = 0,
    className = '',
    alwaysClose = true,
    animate = 'down',
    size = 'medium',
    children = []
    //, ...rest
  } = props

  // element reference and event for detecting clicks outside the dropdown
  const dropDownRef = useRef( null )
  const dropDownClick = ( e ) =>
  {        
    if ( dropDownRef && dropDownRef.current )
    {
      const clickedInside = dropDownRef.current.contains( e.target );
      const clickedClose = e.target.classList.contains( 'dropdown-toggle-icon' )
      if ( !clickedClose && ( alwaysClose || !clickedInside ) )
      {
        setIsOpen( false )
      }
    }
  }
  
  // add default  classNames
  const classNames = [ 
    'dropdown', 
    className ? className : '', 
    isOpen ? 'dropdown-open' : 'dropdown-closed',
    animate ? 'animate-' + animate : '',
    size ? 'size-' + size : ''
  ]

  if ( isOpen )
  {
    document.addEventListener( 'click', dropDownClick, true);
  }
  else
  {
    document.removeEventListener( 'click', dropDownClick, true);
  }

  return (
    <div className={ classNames.join( ' ' ).replace( '  ', ' ').trim() } ref={ dropDownRef } >
      <DropDownToggle img_src={ toggle_img_src } asset={ toggle_asset } text={ toggle_text } num={ toggle_num } onClick={ toggleDropdown } />
      <DropDownList items={ children } />           
    </div>
  )    
}
