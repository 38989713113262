import React from 'react'

const Notice = ( { type = 'success', message, icon = '' } ) => 
{
    const className = 'notice type-' + type + ' icon-' + icon
    return (
        <div className = { className } dangerouslySetInnerHTML={{ __html: message }}></div>
    )
}

export default Notice