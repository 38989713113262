import React from 'react'

// SVG assets
/*
import { ReactComponent as Cloud1 } from '../assets/clouds/left-big.svg'
import { ReactComponent as Cloud2 } from '../assets/clouds/left-small.svg'
import { ReactComponent as Cloud3 } from '../assets/clouds/right-big.svg'
import { ReactComponent as Cloud4 } from '../assets/clouds/right-small.svg'
*/

// PNG assets
import cloud1 from '../assets/clouds/left-big.png'
import cloud2 from '../assets/clouds/left-small.png'
import cloud3 from '../assets/clouds/right-big.png'
import cloud4 from '../assets/clouds/right-small.png'


/**
 * Randomized cloud
 * @param {*} param0 
 */
const Cloud = ( { className = '', animate = true }) =>
{  
  // some sort of random scale between .5 and 1
  const scale = ( 5 + Math.random() * 5 ) / 10
  // animation time in seconds
  const time = 300

  // random style
  const style = {
    top: Math.round( Math.random() * 100 ) + '%',
    // smaller scale relative to the view height
    width: ( Math.round() * scale * 14 ) + '%',
    // lower z-index for smaller scale as smaller clouds are farther away
    zIndex: Math.round( scale * 100 )
  }

  // default class name
  className += ' cloud'

  // maybe animate?
  if ( animate )
  {    
    // speed is inverse to scale so smaller = slower, this way smaller clouds seem farther away
    const duration = Math.round( time / scale )
    // add animation class to begin the keyframes animation in css
    className += ' animate'
    // set a random negative delay to start at a random position in the animation
    style.animationDelay = Math.round( Math.random() * -1 * duration ) + 's'
    // animation duration in seconds
    style.animationDuration = duration + 's'
  }
  else
  {
    // otherwise, just give it a random spot
    style.left = Math.round( Math.random() * 100 ) + '%';
  }  

  // 50% change of flipped (mirrored cloud)
  if ( Math.random() > .5 )
  {
    className += ' flip'
  }

  // random type
  const type = 1 + Math.round( Math.random() * 3 )

  let src = cloud1
  if ( type === 4 ) src = cloud4
  if ( type === 3 ) src = cloud3
  if ( type === 2 ) src = cloud2

  /*
  let cld = <Cloud1/>
  if ( type === 4 ) cld = <Cloud4/>
  if ( type === 3 ) cld = <Cloud3/>
  if ( type === 2 ) cld = <Cloud2/>
  */

  // return random cloud
  // return <div style={ style } className={ className }>{ cld }</div>
  return <img src={ src } style={ style } className={ className } alt="cloud"/>
}

export default Cloud
