import React, { useEffect, useState } from 'react'
import { Button } from './form'
import { useTranslation } from 'react-i18next'
import { isItReallyAnObject } from '../tools/object'
import { useSelector } from 'react-redux'
import { getLocale } from '../redux/users/selectors'

// check for speech ability
const hasSpeech = window.hasOwnProperty( 'speechSynthesis' )

// list of things being uttered
let utterances = []

// stop speaking
export const stopSpeech = () => 
{
  if ( hasSpeech )
  {      
    // stop speech
    window.speechSynthesis.cancel()

    // reset utterances queue
    utterances = []
  }
}

// build content to speak, add dot-spaces for pauses
const prepText = ( text ) =>
{
  // add pauses after paragraphs and breaks
  text = text.replace( '</p>', ".  ")
  text = text.replace( '</br>', ". ")
  // strip tags
  text = text.replace( /(<([^>]+)>)/gi, "" )
  // remove double dots
  text = text.replace( '..', '.' )
  // cleanup links
  text = text.replace( 'http://', '' )
  text = text.replace( 'https://', '' )
  // return the prepped text
  return text
}

// add text to speech queue
const enqueueText = ( text, lang = '' ) =>
{ 

  // possible linked DomElement
  let el = null
  let elQuery = ''
  let elClass = 'speaking'

  // maybe text is on object like { text: String, el: DomElement }
  if ( isItReallyAnObject( text ) )
  {
    el = text.hasOwnProperty( 'el' ) ? text.el : null
    elQuery = text.hasOwnProperty( 'elQuery' ) ? text.elQuery : ''
    if ( text.hasOwnProperty( 'elClass' ) ) elClass = text.elClass
    text = text.hasOwnProperty( 'text' ) ? text.text : ''
  }

  // find element
  if ( !el && elQuery )
  {
    el = document.querySelector( elQuery )
  }

  // double check text
  if ( text && typeof text === 'string' )
  {
    // create utterance object 
    const utterance = new SpeechSynthesisUtterance() 

    // setup test and lang
    utterance.text = prepText( text )
    if ( lang ) utterance.lang = lang

    // add to list
    utterances.push( utterance )    

    // toggle class when element text is being uttered
    if ( elClass && el )
    {
      // when utterance starts
      utterance.addEventListener( 'start', () => {
        // make sure element is visible
        el.scrollIntoView( {
          behavior: "smooth", 
          block: "nearest", 
          inline: "start"
        } )
        // put focus on read element
        el.classList.add( elClass ) 
      } )

      // remove focus
      utterance.addEventListener( 'end', () => el.classList.remove( elClass ) )
    }

    // start speaking
    window.speechSynthesis.speak( utterance )
  }
}

// start speaking
export const startSpeech = ( text = '', lang = '' ) =>
{
  // bail if speech is not enabled
  if ( !hasSpeech || !text ) return

  // cancel previous speech
  stopSpeech()
  
  if ( Array.isArray( text ) )
  {
    // add multiple texts 
    text.forEach( t => enqueueText( t ) )
  }
  else if ( typeof text === 'string' )
  {
    // add singe text
    enqueueText( text )
  }
}

// speak button
export const SpeechButton = ( { text = '' } ) =>
{
  // speak / speech state
  const [ speaking, setSpeaking ] = useState( false )

  const { t } = useTranslation()

   // language for speech
  const lang = useSelector( getLocale )

  useEffect( () => {
    // stop speaking on component removal
    return () => stopSpeech()
  }, [] )

  // don't render if speech is disabled
  if ( !hasSpeech ) return null

  // toggle speaking
  const onClick = () => 
  {
    speaking ? stopSpeech() : startSpeech( text, lang )    
    setSpeaking( !speaking )    

    if ( utterances &&  utterances.length )
    {
      // turn of reading state when last utterance has been spoken
      const last = utterances[ utterances.length - 1 ]     
      if ( last ) last.addEventListener( 'end', () => setSpeaking( false ) )
    }
  }  

  const className = speaking 
    ? 'speak speaking' 
    : 'speak'

  const label = speaking
    ? t( 'pathways.speak_button_stop', 'Stop reading aloud' )
    : t( 'pathways.speak_button_start', 'Start reading aloud' )

    return <Button className={ className } icon='sound' onClick={ onClick }>{ label }</Button>
}