import { getArrayProp, getObjectProp, getStringProp, isItReallyAnObject } from '../../tools/object'

export const schemaPathwayChapters = ( chapters = [] ) =>
{
  // force an array
  chapters = Array.isArray( chapters ) ? chapters : []

  // schema each chapter
  return chapters.map( schemaPathwayChapter )
}

export const schemaPathwayChapter = ( chapter = {} ) =>
{
  // force an object
  chapter = isItReallyAnObject( chapter ) ? chapter : {}

  // create title if missing
  chapter.title = getStringProp( chapter, 'title' )

  // create text if missing
  chapter.text = getStringProp( chapter, 'text' )

  // create start & end steps if missing
  chapter.start = getStringProp( chapter, 'start' )
  chapter.end   = getStringProp( chapter, 'end' )

  return chapter
}


/**
 * Check required fields for a pathway object
 * @param {*} pathway 
 */
export const schemaPathway = ( pathway = {} ) =>
{
  // create steps object if missing
  pathway.steps = getObjectProp( pathway, 'steps' )

  // create root array if missing
  pathway.root = getStringProp( pathway, 'root' )

  // create title if missing
  pathway.title = getStringProp( pathway, 'title' )

  // create intro if missing
  pathway.text = getStringProp( pathway, 'text' )

  // create chapters if missing
  pathway.chapters = schemaPathwayChapters( getArrayProp( pathway, 'chapters' ) ) 

  // return pathway 
  return pathway
}