import { actionTypes } from './types'

/**
 * Login a user with username and password
 * @param {*} username 
 * @param {*} password 
 */
export const login = ( username, password ) => ({
    type: actionTypes.AUTH_LOGIN,    
    payload: {
        username: username,
        password: password
    },
    meta: {
        offline: {
            effect: {
                url: 'login'
            },
            commit: {
                type: actionTypes.AUTH_LOGIN_SUCCESS
            },
            rollback: {
                type: actionTypes.AUTH_LOGIN_ROLLBACK
            }
        }
    }
})

/**
 * Logout a user, removes token from localStorage and server
 */
export const logout = () => ({
    type: actionTypes.AUTH_LOGOUT, 
    meta: {
        offline: {
            effect: {
                url: 'logout'
            },
            commit: {
                type: actionTypes.AUTH_LOGOUT_SUCCESS
            },
            rollback: {
                type: actionTypes.AUTH_LOGOUT_ROLLBACK
            }
        }
    }
})

/**
 * Test on server if existing token stored in localStorage is still valid
 * Auth token will automatically be added by the API Request if it exists
 */
export const testOnline = () => ({
    type: actionTypes.AUTH_TEST_ONLINE,
    meta: {
        offline: {
            effect: {
                url: 'test'
            },
            commit: {
                type: actionTypes.AUTH_TEST_ONLINE_SUCCESS
            },
            rollback: {
                type: actionTypes.AUTH_TEST_ONLINE_ROLLBACK
            }
        }
    }
} )

/**
 * Allow the online rollback to be called in case we know there is not auth token to be tested
 */
export const testOnlineSkipped = () => ({
    type: actionTypes.AUTH_TEST_ONLINE_ROLLBACK
})

/**
 * Test authentication offline
 */
export const testOffline = () => ({
    type: actionTypes.AUTH_TEST_OFFLINE
})

/**
 * Terminate auth / session when a token invalidation is returned from the server
 * No need to logout as that probably happened on the server anyway
 * @param {*} message 
 */
export const terminate = ( message ) => ({
    type: actionTypes.AUTH_TERMINATE,    
    payload: message
})

/**
 * Clean up data object, called after data is succesfully copied to the user
 * @param {*} message 
 */
export const removeData = () => ({
    type: actionTypes.AUTH_USER_DATA_SUCCESS   
})