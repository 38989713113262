// user stuff
import { setUser, updateProgress } from './actions'
import { actionTypes as userActionTypes } from './types'

// auth stuff
import { removeData } from '../auth/actions'
import { actionTypes as authActionTypes } from '../auth/types'

// remember the authenticated user
let auth_id = ''

// remember last update timestamp
let last_update = 0

// update timeout, prevents many small chanbges being pushed
let update_timeout = 0

// update delay in ms
const update_delay = 2000

/**
 * Push changes to the server when the user makes progress in the pathways
 * @param {*} store 
 */
export const saveProgress  = ( { getState, dispatch } ) =>
{
  // get auth and users from state
  const { auth, users, online } = getState()  

  // get what we need from auth and set defaults
  const { id = '', authenticated = false, validated = false } = auth  

  // define actions when to save progress
  const actions = [
    userActionTypes.USER_SET_AVATAR,
    userActionTypes.USER_SET_TASK,
    userActionTypes.USER_ADD_TASK,
    userActionTypes.USER_FINISH_TASK,
    userActionTypes.USER_REMOVE_TASK,
    userActionTypes.USER_SET_PROGRESS,
    userActionTypes.USER_SET_CURRENT_STEP,
    userActionTypes.USER_SET_HISTORY,
    userActionTypes.USER_SET_STEPS,
    userActionTypes.USER_BEGIN_STEP,
    userActionTypes.USER_COMPLETE_STEP,
    userActionTypes.USER_SET_GOAL,
    userActionTypes.USER_SET_PATHWAY_ORDER,
    userActionTypes.USER_SELECT_PATHWAY,
    userActionTypes.USER_TOGGLE_BOOKMARK
  ]

  // see if we have a validated user and the user is the current authenticated user
  if ( id && authenticated && validated && auth_id === id )
  {
    // get what we need from the online object
    const { last_action = '' } = online

    // see if we have an action that has something to do with updating the progress  
    if ( actions.includes( last_action ) )
    {  
      // get current user
      const user = users && users.hasOwnProperty( id ) ? users[ id ] : null

      // make sure user exists
      if ( user )
      {
        // get what we need from the user
        const { updated = 0, progress = null } = user  

        // see if we have a newer update
        if ( updated > last_update )
        {
          // remember last update
          last_update = updated

          // send progress to the server
          if ( progress )
          {
            // cancel previous update
            if ( update_timeout )
            {
              clearTimeout( update_timeout )
            }

            // wait a whilew before submitting changes to prevent many small changes being pushed to the server
            update_timeout = setTimeout( () => {
              dispatch( updateProgress( progress ) )
            }, update_delay )            
          }
        }
      }
    }
  }
}

/**
 * Update stored user details when logged in user changes
 * Data is received after login or, in case of a valid token, the test request
 * @param {*} store 
 */
export const updateUser = ( { getState, dispatch } ) =>
{
  // get auth and users from state
  const { auth, online } = getState()  

  // get what we need from auth and online and set defaults
  const { 
    id = '', 
    authenticated = false, 
    validated = false, 
    user = null 
  } = auth  

  const { last_action } = online

  // define actions when to update user
  const actions = [
    authActionTypes.AUTH_LOGIN_SUCCESS,
    authActionTypes.AUTH_TEST_ONLINE_SUCCESS,    
  ]

  // we need an authenticated & validated user with an ID we haven't just processed,
  // the last action should be a successfull auth action
  // auth.user should contain all the user data we need   
  if ( id && authenticated && validated && auth_id !== id && user !== null && actions.includes( last_action ) )
  {     
    // remember the current user so we don't keep updating
    auth_id = id

    // set current user data
    dispatch( setUser( user ) )

    // remove data from auth object
    dispatch( removeData() )
  }  
}