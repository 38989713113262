import React from 'react'
import IcomoonIcon from './icomoon-icon'

// pathway icon with icomoon icons
const PathwayIcon = ( { pathway = '', className = '', ...rest } ) => 
{
  if ( pathway )
  {
    className += ' pathway-icon pathway-' + pathway
  }

  switch ( true )
  {
    case pathway.includes( 'money' ):
    case pathway.includes( 'income' ):
      return <IcomoonIcon icon="cash" className={ className } { ...rest }/>

    case pathway.includes( 'work' ):
    case pathway.includes( 'learn' ):
      return <IcomoonIcon icon="learn" className={ className } { ...rest }/>

    case pathway.includes( 'house' ):
    case pathway.includes( 'housing' ):
      return <IcomoonIcon icon="house" className={ className } { ...rest }/>

    case pathway.includes( 'family' ):
    case pathway.includes( 'friend' ):
    case pathway.includes( 'partner' ):
      return <IcomoonIcon icon="family" className={ className } { ...rest }/>

    case pathway.includes( 'health' ):
    case pathway.includes( 'wellbeing' ):
      return <IcomoonIcon icon="health" className={ className } { ...rest }/>

    case pathway.includes( 'hobby' ):
      return <IcomoonIcon icon="hobby" className={ className } { ...rest }/>

    case pathway !== '':
      return <IcomoonIcon icon={ pathway } className={ className } { ...rest } />

    default:
      return null
  }
}

/*

// assets
import { ReactComponent as IconHousing } from '../assets/icons/pathways/housing.svg'
import { ReactComponent as IconMoney } from '../assets/icons/pathways/money-income.svg'
import { ReactComponent as IconFamily } from '../assets/icons/pathways/family.svg'
import { ReactComponent as IconPartner } from '../assets/icons/pathways/partner.svg'
import { ReactComponent as IconHealth } from '../assets/icons/pathways/health.svg'
import { ReactComponent as IconLearn } from '../assets/icons/pathways/work-learn.svg'

// svg icons; use css icons instead
const PathwayIconSVG = ( { pathway = '', className = '', ...rest } ) => 
{
  className += ' pathway-icon pathway-' + pathway
  switch ( true )
  {
    case pathway.includes( 'money' ):
    case pathway.includes( 'income' ):
      return <IconMoney className={ className } { ...rest }/>

    case pathway.includes( 'work' ):
    case pathway.includes( 'learn' ):
      return <IconLearn className={ className } { ...rest }/>

    case pathway.includes( 'house' ):
    case pathway.includes( 'housing' ):
      return <IconHousing className={ className } { ...rest }/>

    case pathway.includes( 'family' ):
    case pathway.includes( 'friend' ):
      return <IconFamily className={ className } { ...rest }/>

    case pathway.includes( 'partner' ):
      return <IconPartner className={ className } { ...rest }/>

   case pathway.includes( 'health' ):
   case pathway.includes( 'wellbeing' ):
      return <IconHealth className={ className } { ...rest }/>

    default:
      return <div className={ className } { ...rest } />
  }
}

*/

export default PathwayIcon
