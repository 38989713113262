import React, { useState } from 'react'
import Cloud from '../../components/cloud'

// get a random number of random clouds
const Clouds = ( { min = 5, max = 7 } ) => 
{
  // get an initial random number of clouds
  const r = Math.round( min + Math.random() * max  )
  // use state for clouds num so it stays the same between re-renders
  const [ numClouds ] = useState( r )
  // add cloud components
  const clouds = []
  while( clouds.length < numClouds )
  {
    clouds.push( <Cloud key={ clouds.length }/> )
  }
  // render the clouds div
  return <div className="clouds">{ clouds }</div>
}

// using memo prevents clouds for jumping around on intermittent rerenders
export default React.memo( Clouds )