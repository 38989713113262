import React from 'react'
import { Button } from './form'

const BookmarkButton = ( { active = false, onToggle = null, className = '', children = null, ...rest } ) => 
{
    className += ' bookmark '
    className += active ? ' active icon-fav-on' : ' icon-fav-off'
    className += children ? ' button' : ' button-round'

    return (
      <Button className={ className } onClick={ () => onToggle ? onToggle() : null } { ...rest}>
      { children }
      </Button>
    )
}

export default BookmarkButton