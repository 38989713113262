import React from 'react'
import { Dropdown } from './dropdown'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// get user for icon 
import { useSelector } from 'react-redux'
import { getFeedback } from '../redux/users/selectors'

// get icons
import { ReactComponent as SmileyAngry } from '../assets/icons/smileys/angry.svg'
import { ReactComponent as SmileyDisapointed } from '../assets/icons/smileys/disapointed.svg'
import { ReactComponent as SmileyIndifferent } from '../assets/icons/smileys/indifferent.svg'
import { ReactComponent as SmileySmirk } from '../assets/icons/smileys/smirk.svg'
import { ReactComponent as SmileyHappy } from '../assets/icons/smileys/happy.svg'

import { DropdownNum } from './dropdown'

// list of smiley names and matching svg components
const smileys = [ 
    { 
        feedback: 1,
        name: 'angry', 
        asset: <SmileyAngry/>
    },
    {
        feedback: 2,
        name: 'disapointed', 
        asset: <SmileyDisapointed/>
    },
    {
        feedback: 3,
        name: 'indifferent', 
        asset: <SmileyIndifferent/>
    },
    {
        feedback: 4,
        name: 'smirk', 
        asset: <SmileySmirk/>
    },
    {
        feedback: 5,
        name: 'happy',
        asset: <SmileyHappy/>
    }
]

/**
 * Return a smiley object for the specified name
 * @param {*} smileyName 
 */
const getSmiley = ( feedback_or_name = '' ) =>
{
    // default to middle smiley
    let smiley = null

    // maybe it's a number?
    const is_feedback = Number.isInteger( feedback_or_name )

    // only do a search if a name was specified
    if ( feedback_or_name )
    {
        smiley = smileys.find( s => (
             is_feedback 
                ? s.feedback === feedback_or_name 
                : s.name === feedback_or_name           
        ) )
    }

    // default to middle / average smiley
    if ( !smiley ) smiley = smileys[ 2 ]

    // return the smiley object
    return smiley
}
/**
 * Get NavItems from routes config
 * @param {*} routes 
 */
const getNavRoutes = routes => routes.map( route => ( <NavItem key={ route.path } { ...route }/> ) )

/**
 * NavItem is a wrapper for NavLink + DropDownNum, uses a react fragment as a wrapper
 * @param {*} param0 
 */
const NavItem = ( { path = '', exact = false, name = '', counter = 0, className = '' } ) => (
  <React.Fragment>
    <NavLink exact={ exact } to={ path } strict={ false } className={ className }>{ name }</NavLink>   
    <DropdownNum num={ counter }/>
  </React.Fragment>
)

// dropdown base features
const NavDropdown = ( props ) => 
{
    // hooks
    const { t } = useTranslation()

    // get feedback from user
    const feedback = useSelector( getFeedback )

    // get a matching smiley object
    const smileyObject = getSmiley( feedback )

    // get properties we need and set defaults
    const { className = '', children = [], routes = [], ...rest } = props

    // see if any of the routes has a counter and get a total
    const count = routes.reduce( ( total = 0, route ) => {
        // try to get an integer counter from the route
        let add = parseInt( 'function' === typeof route.counter ? route.counter() : route.counter, 10 )
        // fix nan issues
        if ( isNaN( add ) )  add = 0
        if ( isNaN( total ) ) total = 0  
        // return the total
        return total + add
     } )

    // get components for routes and add them to children    
    const navItems = [
        ...children,
        ...getNavRoutes( routes )
    ]

    return (
        <Dropdown 
            toggle_asset={ smileyObject.asset }
            toggle_text={ t( 'nav.title', 'Menu' ) }
            toggle_num={ count }
            className={ `${className} nav-dropdown dropdown-large smiley-${smileyObject.name}` } 
            animate='side'
            size='large'
            key='dropdown'
            { ...rest } > 
            { navItems }
        </Dropdown>
    )    
}

export default NavDropdown