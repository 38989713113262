import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'

// redux
import { useSelector } from 'react-redux'
import { getPathways } from '../redux/users/selectors'


// components
import ProgressBar from '../components/progress-bar'
import { Container } from '../components/containers'
import PathwayIcon from '../components/pathway-icon'
import { Button } from '../components/form'
import Timeline from './pathways/timeline'
import Page from '../components/page'

/**
 * Pathways page component
 */
const Pathways = () =>
{
  // hooks
  const { t } = useTranslation()

  // get all pathways and progress for this user
  const pathways = useSelector( getPathways )

  // use location and history to allow the user to use the back button
  const history = useHistory()
  
  // get pathway from url params 
  const { pathway } = useParams()
  
  // use a temporary progress value
  const [ progress, setProgress ] = useState( -1 )  

  // allow real progress to be updated from child components, usually when a popup closes
  const updateProgress = () => setProgress( getProgress() )

  // get progress even if there are no chapters
  const getProgress = () => current_chapter ? current_pathway.chapter_percentage : current_pathway.percentage

  // update temp percentage;
  // - on first render ( progress is negative )
  // - when going backwards ( progress is greater than chapter percentage )
  useEffect( () => {
    if ( current_pathway )
    {
      if ( progress < 0 || getProgress() < progress )
      {
        setTimeout( () => setProgress( getProgress() ), 100 )
      }
    }
  } )

  // find current pathway
  const pathway_index = pathways.findIndex( p => p.pathway_id === pathway )  
  const current_pathway = pathway_index >= 0 ? pathways[ pathway_index ] : null  

  // go home on missing data
  if ( !pathway || pathway_index < 0 || !current_pathway )
  {    
    history.push( '/' )
    return null
  }  

  // find chapter to go with current step
  const current_step = current_pathway.steps[ current_pathway.progress.current ]  
  const current_chapter = current_step && current_step.chapter >= 0 ? current_pathway.chapters[ current_step.chapter ] : null

  return (
    <Page page="pathways">

      { /* Back button goes home */ }
      <Button key="pathways-back" className="back" secondary large icon="prev" onClick={ () => history.push( '/' ) }>{ t( 'pathways.back', 'Back' ) }</Button>

      <Container center key="pathways-container" >
        
        { /* Always show the progress bar on top, outside of the route */ }
        <ProgressBar className="progress-bar-pathways" percentage={ progress } temp_percentage={ getProgress()  }/>

          <PathwayIcon pathway={ pathway } className="header-icon"/>

          <h1 className="pathway-title">{ current_pathway.pathway_name }</h1>
          <h2 className="chapter-title">{  current_chapter ? current_chapter.title : '' }</h2>

          { /* Show timeline based on all the items of the current pathway */ }                             
          <Timeline pathway={ current_pathway } updateProgress={ updateProgress }/>

      </Container>

    </Page>
  )
}

export default Pathways
// export default React.memo( Pathways )