import { actionTypes } from './types'
import { schemaPathwayProgress, schemaUserPathwayProgress, schemaProgressStep, schemaTask, schemaUser } from './schema'
import { isItReallyAnObject } from '../../tools/object'
import i18next from 'i18next'
import { isString, merge } from 'lodash'
// import { setPathwayOrder } from './actions'
// import { updateProgress } from './actions'

// initial state is an empty object
// each user is stored in a key with the id
const INITIAL_STATE = {}

// initial user object
const INITIAL_USER = {
    id: '',
    name: '',
    login: '',
    password: '',
    org_id: '',
    avatar: '',
    // bookmarks object
    bookmarks: {},
    // pathway progress, one object for each pathway in the array, order matters!
    progress: [],
    // last updated
    updated: new Date().getTime()
}

/**
 * Update the current user with data received from Auth
 * Data is received after login or, in case of a valid token, the test request
 * @param {*} state 
 * @param {*} action 
 */
const setUser = ( state, action ) =>
{
    // user id shortcut
    const user_id = action.payload.user.id    

    // user ID is required
    if ( user_id )
    {
        // new / existing user object, always start with a clean slate
        let user = { ...INITIAL_USER }

        // if we already have an existing user
        if ( state.hasOwnProperty( user_id ) )
        {
            // deep merge (using lodash) with whatever is already in state
            merge( user, state[ user_id ] )
        }

        // deep merge (using lodash) with whatever we get from the server
        merge( user, action.payload.user )

        // set last updated timestamp
        user.updated =  new Date().getTime()

        // set user interface language
        if ( user.hasOwnProperty( 'lang' ) && user.lang )
        {
            setTimeout( () => {
                i18next.changeLanguage( user.lang )
            }, 10 )            
        }

        // return the new state
        return ( {
            // get current state
            ...state,
            // and add in the new / updated entry with the dynamic [ user id ] as key
            [ user_id ]: user           
        } )
    }

    // return state if nothing was changed
    return state
}

/**
 * Set a user property. The payload is the property to set
 * Overwrites the entire prop so be careful with objects
 * Assumes the currentUserMiddleware supplies the user_id in the action
 * @param {*} state 
 * @param {*} action 
 * @param {*} key 
 */
const setUserProp = ( state, action, key ) => ({
    ...state,
    [ action.user_id ]: {
        ...getCurrentUser( state, action.user_id ),
        [ key ]: action.payload,
        // last updated
        updated: new Date().getTime()
    }
})

/**
 * Get the current user object from state
 * Always start with the inital user object
 * @param {*} state 
 * @param {*} user_id 
 */
const getCurrentUser = ( state, user_id = '' ) => 
{
    // return existing user
    if ( user_id && state.hasOwnProperty( user_id ) )
    {
        return schemaUser( {
            ...INITIAL_USER,
            ...state[ user_id ]
        } )
    }

    // return new user
    return schemaUser( {
        ...INITIAL_USER,
        id: user_id
    } )
}

/**
 * Set a property on a user pathway
 * Currently supported; steps object and history array
 * @param {*} state 
 * @param {*} action 
 */
const setProgress = ( state, action ) => 
{
    // get what we need from the arguments
    const { 
        user_id = '', 
        payload 
    } = action

    // current timestamp
    const time = new Date().getTime()

    // get what we need from the action
    const { 
        // pathway to modify, this is the name / slug of the pathway
        pathway = '', 
        // steps to overwrite, or none if null
        steps = null, 
        // history to overwrite, or none if null
        history = null,
        // current step id to overwrite, or none if null
        current = '',
        // goal id to overwrite, or none if null
        goal = '',
        // pathway order
        order = -1,
        // entire progress object
        progress = null,
        // step id to modify
        step_id = '',
        // feedback score
        step_feedback = -1,
        // next step
        step_next = '',        
        // step done?
        step_done = false,
        // entire step object
        step = null,
        // task object to be added to pathway step
        task = null,
        // (de)activate a pathway, this indicates the use wishes to work on it, deactivated pathways are still accessible 
        active = null,
        // set step popup state
        step_popup = null
    } = payload

    // pathway and user_id are required
    if ( pathway && user_id && state.hasOwnProperty( user_id ) )
    {
        const current_user = getCurrentUser( state, user_id )

        // get the user, check for pathways
        const user = schemaUserPathwayProgress( current_user, pathway )

        // get index for the current pathway progress
        const index = user.progress.findIndex( p => p.pathway === pathway )

        // overwrite entire progress object
        if ( isItReallyAnObject( progress ) && index >= 0 )
        {
          user.progress[ index ] = schemaPathwayProgress( pathway, {
            ...user.progress[ index ],
            ...progress
          } )
        }

        // shortcut to the current pathway progress
        const current_progress = schemaPathwayProgress( pathway, index < 0 ? null : user.progress[ index ] )

        // overwrite steps
        if ( isItReallyAnObject( steps ) )
        {
          current_progress.updated = time
          current_progress.steps = steps
        }

        // overwrite history
        if ( Array.isArray( history ) )
        {
          current_progress.updated = time
          current_progress.history = history
        }

        // overwrite goal
        if ( goal && typeof goal === 'string' )
        {
          current_progress.updated = time
          current_progress.goal = goal
        }

        // overwrite current
        if ( current && typeof current === 'string' )
        {
          current_progress.updated = time
          current_progress.current = current
        }

        if ( active !== null )
        {
          current_progress.updated = time
          // double NOT is a form of boolean conversion
          current_progress.active = !!active
        }

        // are we doing something with a specific step?
        if ( step_id )
        {
          // get the current step
          let current_step = schemaProgressStep( current_progress.steps[ step_id ] )                

          // find the index in the history of the current step
          const history_index = current_progress.history.findIndex( h => h === step_id )   

          // overwrite entire step
          if ( isItReallyAnObject( step ) )
          {
              current_step = schemaProgressStep( {
                  ...current_step,
                  ...step
              })
          }

          // maybe disable all other popups first when:
          // - opening from bookmark
          if ( [ 'bookmark' ].includes( step_popup ) )
          {
            for( const s in current_progress.steps )
            {
              if ( s !== step_id )
              {
                //current_progress.steps[ s ].popup = '' 
              }
            }
          }

          // set popup state 
          if ( isString( step_popup ) )
          {            
            current_step.popup = step_popup
          }

          // set next step, or "beginStep"
          if ( step_next )
          {
            // (re)set feedback and set next step in the current step
            current_step.feedback = 0;
            current_step.next = step_next
            current_step.done = false   
            // set popup animation hint, exit to the left
            current_step.popup = 'exit-left'
            
            // remove history items after the current item and add the next item
            current_progress.history = current_progress.history.slice( 0, history_index + 1 )
            current_progress.history.push( step_next )

            // make sure the current step is this step
            current_progress.current = step_id

            // make sure next step isn't DONE yet
            if ( current_progress.steps.hasOwnProperty( step_next ) )
            {
              current_progress.steps[ step_next ].done = false
              // set popup animation hint, enter from the right 
              current_progress.steps[ step_next ].popup = 'enter-right'
            }
          }

          // set feedback, or "completeStep" or "beginStep" with done or feedback set
          if ( step_feedback >= 0 || step_done )
          {               
            current_step.updated = time

            // store feedback
            if ( step_feedback >= 0 )
            {
              current_step.feedback = step_feedback
            }

            // set step done state 
            current_step.done = true     

            // once feedback is set, move to the next step
            if ( current_step.next )
            {
              // add next step to the history
              current_progress.history.push( current_step.next )
              
              // set next step as the current step
              current_progress.current = current_step.next                     
          
              // make sure the next steps next step is empty to prevent loops
              if ( !step_next )
              {
                let next_step = schemaProgressStep( current_progress.steps[ current_step.next ] )  
                next_step.next = ''
                current_progress.steps[ current_step.next ] = next_step
              }
            }
          }

          // set or update task step
          if ( task && isItReallyAnObject( task ) )
          {
            // merge existing task with new task
            current_step.task = {
                // begin with existing task, or create one if needed
                ...schemaTask( current_step.hasOwnProperty( 'task' ) ? current_step.task : null, true ),
                // merge in new task fields
                ...task,
                // set the update timestamp
                updated: time
              }
          }
          // remove task
          else if ( task === null )
          {
            // merge existing task with new task
            current_step.task = null
          }

          // set step last updated timestamp
          current_step.updated = time

          // put step back
          current_progress.steps[ step_id ] = current_step
        }       
        
        // set progress last updated timestamp
        current_progress.updated = time

        // put the current progress object back into the array
        user.progress[ index ] = current_progress        

        // change order if needed
        if ( typeof order === 'number' && order >= 0 )
        {
            // remove existing pathway from user progress
            const new_progress = user.progress ? user.progress.filter( p => typeof p.pathway === 'string' && p.pathway && p.pathway !== pathway ) : null

            // change pathway order by injecting the current progress
            new_progress.splice( order, 0, current_progress )

            // update progress
            user.progress = new_progress
        }  

        // return the modified state
        return updateUser( state, user_id, user )
    }

    // return original state by default
    return state;
} 

// add a task 
const addTask = ( state, action ) =>
{
  // update task with added timestamp
  action.payload.task = {
    ...schemaTask( action.payload.task ),
    added: new Date().getTime()
  }

  return setProgress( state, action )
}

// set a task as finished
const finishTask = ( state, action ) =>
{
  // update task with done boolean
  action.payload.task = {
    ...schemaTask( action.payload.task ),
    done: new Date().getTime()
  }
  return setProgress( state, action )
}

// set a task as finished
const removeTask = ( state, action ) =>
{
  return setProgress( state, action )
}

/**
 * Replace user with id in state and set updated timestamp
 * @param {*} state 
 * @param {*} user_id 
 * @param {*} user 
 * @returns 
 */
const updateUser = ( state, user_id, user ) =>
{
  // return the new state
  return {
    // get current state
    ...state,
    // update specified user ..
    [ user_id ]: {
      // .. with new user data ..
      ...user,
      // .. and set the updated timestamp
      updated: new Date().getTime()
    }
  } 
}

/**
 * Add a bookmark
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const setBookmarks = ( state, action ) =>
{
  // get what we need from action & payload
  const { user_id = '', payload, type } = action
  const { pathway, step_id } = payload

  // get the current user
  const user = getCurrentUser( state, user_id )  

  // get bookmarks object
  let { bookmarks } = user

  // make sure it's an object and not an array
  bookmarks = isItReallyAnObject( bookmarks ) ? bookmarks : {}

  // add pathway if needed
  if ( !bookmarks.hasOwnProperty( pathway ) || !isItReallyAnObject( bookmarks[ pathway ] ) )
  {
    bookmarks[ pathway ] = {}
  }

  const exists = bookmarks[ pathway ].hasOwnProperty( step_id ),
        date = new Date().getTime()

  // add, remove or toggle
  switch( type )
  {
    case actionTypes.USER_ADD_BOOKMARK:
      if ( !exists ) bookmarks[ pathway ][ step_id ] = date      
      break

    case actionTypes.USER_REMOVE_BOOKMARK:
      if ( exists ) delete bookmarks[ pathway ][ step_id ]
      break

    case actionTypes.USER_TOGGLE_BOOKMARK:
      if ( !exists ) bookmarks[ pathway ][ step_id ] = date  
      if ( exists )  delete bookmarks[ pathway ][ step_id ]
      break

    default:
      break
  }

  // return the new state with the modified user
  return updateUser( state, user_id, {
    ...user,
    bookmarks: bookmarks
  } )
}

// (de)select a pathway, ie set the active state 
const selectPathway = ( state, action ) => 
{
  // activating a pathway puts it first, deactivating puts it last
  action.payload.order = action.payload.active ? 0 : 999
  // use set progress to handle the rest
  return setProgress( state, action )
}

/**
 * User reducer to be exported
 * @param {*} state 
 * @param {*} action 
 */
const usersReducer = ( state = INITIAL_STATE, action ) =>
{    
    switch ( action.type )
    {        
        case actionTypes.USER_SET:
            return setUser( state, action )

        case actionTypes.USER_SET_AVATAR:
            return setUserProp( state, action, 'avatar' )

        case actionTypes.USER_SET_INTRO:
            return setUserProp( state, action, 'intro' )

        case actionTypes.USER_SELECT_PATHWAY:
          return selectPathway( state, action )

        // case actionTypes.USER_SET_TASK:
        case actionTypes.USER_SET_GOAL:
        case actionTypes.USER_SET_HISTORY:
        case actionTypes.USER_SET_STEPS:
        case actionTypes.USER_SET_PROGRESS:
        case actionTypes.USER_SET_PATHWAY_ORDER:
        case actionTypes.USER_BEGIN_STEP:
        case actionTypes.USER_COMPLETE_STEP:
        case actionTypes.USER_SET_CURRENT_STEP:
        case actionTypes.USER_SET_PATHWAY_POPUP:
            return setProgress( state, action )

        case actionTypes.USER_ADD_TASK:
            return addTask( state, action )

        case actionTypes.USER_FINISH_TASK:
          return finishTask( state, action )

          case actionTypes.USER_REMOVE_TASK:
            return removeTask( state, action )
        
        case actionTypes.USER_ADD_BOOKMARK:
        case actionTypes.USER_REMOVE_BOOKMARK:
        case actionTypes.USER_TOGGLE_BOOKMARK:
          return setBookmarks( state, action )

        default:
            return state;
    }
}

export default usersReducer