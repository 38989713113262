// app specific config
const config_app = {
  store: {
    log_redux: false,
    log_requests: false
  },

  // remote stuff
  remote: {
    // server path, include http prefix so we don't get automatically redirected to https
    server: 'https://bleepsite.eu'
  }
}

if ( false && process && process.env.NODE_ENV === 'development' )
{
  // test localhost when in development
  config_app.remote.server = 'http://bleep.local'

  // allow speech in development mode only
  config_app.speech = true
}

export default config_app