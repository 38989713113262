
import { actionTypes } from './types'
import LanguageData from '../../i18n/languages.json'
import i18n from 'i18next'
import config from '../../config'

// reuse language config
const INITIAL_STATE = LanguageData;

/**
 * Handle language successfully loaded
 * @param {*} state 
 * @param {*} action 
 */
const languagesLoaded = ( state, action ) => 
{
  const languages = {}

  // add language resources,
  // not that the API returns an ARRAY  so use for .. of
  for ( var lang of action.payload.data.data  )
  {
    // try to add the language to i18next
    if ( addLanguage( lang.strings, lang.locale ) )
    {
      // basically and array to object conversion here
      languages[ lang.locale ] = {
        common: {
          // language is it's own subset used for the language chooser
          ...{  language: {
            locale: lang.locale,
            name: lang.name,
          } },
          // add other subsets
          ...lang.strings
        }
      }
    }
  }

  // trigger a language change so the newly added languages can be rendered
  reloadCurrentLanguage()

  // return merged state
  return ({
    ...state,
    ...languages
  })
}

/**
 * Add languages to i18n from a rehydrated state
 * @param {*} state 
 * @param {*} action 
 */
const languageRehydrated = ( state, action ) =>
{
  // add languages to i18n,
  // not that state is an OBJECT so use for .. in and lang is the key of the array item
  for ( var lang in action.payload.language  )
  {
    addLanguage( action.payload.language[ lang ].common, lang )
  }

  // trigger a language change so the newly added languages can be rendered
  reloadCurrentLanguage()

  // return state, no need to merge
  return state
}

/**
 * Add languages to i18n and return a new state object
 * @param {*} data 
 */
const addLanguage = ( data, locale ) =>
{
  // only include language if specified in config file 
  if ( locale && typeof data === 'object' && !Array.isArray( data ) && config.language.locales.hasOwnProperty( locale ) )
  {    
    // add bundle doesn't work as expected so use addResourceBundle
    // add the bundle to the default namespace (NS) since we don't really use namespaces in this app
    // the final 2 true's add deep overwrite
    i18n.addResourceBundle( locale, i18n.options.defaultNS, data, true, true);

    return true
  }
  return false
}

/**
 * Set the current language
 * @param {*} state 
 * @param {*} action 
 */
const setLanguage = ( state, action ) =>
{
  // try to change the current language
  i18n.changeLanguage( action.payload ? action.payload : i18n.options.fallbackLng[ 0 ] )

  // no change in state
  return state
}

/**
 * Reload the current language so changes in state become visible
 * the timeout is a bit of a hack but it works
 */
const reloadCurrentLanguage = () =>
{
  // trigger a language change so the newly added languages can be rendered
  setTimeout( () => {
    i18n.changeLanguage( i18n.language ? i18n.language : i18n.options.fallbackLng[ 0 ] )
  }, 10 )
}
  
/**
 * Language reducer
 * @param {*} state 
 * @param {*} action 
 */
const languageReducer = ( state = INITIAL_STATE, action ) => 
{
  switch ( action.type ) 
  {
    case 'persist/REHYDRATE':
      return languageRehydrated( state, action ) 

    case actionTypes.GET_LANGUAGES_SUCCES:
      return languagesLoaded( state, action )

    case actionTypes.SET_LANGUAGE:
      return setLanguage( state, action )

    default:
      return state
  }
}

export default languageReducer