import React from 'react'
import { useSelector } from 'react-redux'
import config from '../config'
import { getToken } from '../redux/auth/token'

/**
 * Convert encoded text to html
 * @param {*} param0 
 */
const Html = ( { children = '', tag = 'span', ...rest } ) => 
{
  const token = useSelector( getToken )

  // create a dynamic tag
  const Tag = `${tag}`
  
  const doc = new DOMParser().parseFromString( children, 'text/html' )
  const links = doc.getElementsByTagName( 'a' )

  // add target="_blank" to all links
  for ( const link of links)
  {
    // remove all attributes
    for( const attr of link.attributes )
    {
      if ( attr.name !== 'href' )
      {
        link.removeAttribute( attr.name )
      }
    }
    // add blank target
    link.setAttribute( 'target', '_blank' )

    // add user token to outgoing urls of the same domain
    let href = link.getAttribute( 'href' )    
    const key = '__bapt';
    if ( href.indexOf( config.remote.server ) >= 0 )
    {
      if ( href.indexOf( '?' ) >= 0 )
      {
        href += '&' + key + '=' + token
      }
      else
      {
        href += '?' + key + '=' + token
      }
      link.setAttribute( 'href', href )
    }
  }

  // return the html
  // return <Tag { ...rest} >{ htmlDecode( children ) }</Tag>
  return <Tag { ...rest} dangerouslySetInnerHTML={{ __html: doc.body.outerHTML }} />
  
}

export default Html