import { isArray } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BorderContainer } from './containers'
import { Button } from './form'
import { ToggleButtonGroup } from './toggle-buttons'

const FilterList = ( { children = null, filters = null, className = '', onReset = null, disabled = false, resetText = '', ...rest } ) => {

  const { t } = useTranslation()

  if ( !resetText ) resetText = t( 'tasks.filters_reset', 'Reset filters')

  const filtersClass = 'filters ' + ( disabled ? 'disabled' : '' )

  // in case a single element is returned convert it to a list
  if ( !isArray( children ) ) children = [ children ]

  return(
    <BorderContainer className={ 'filter-list ' + className } center={ false } { ...rest } >
      <div className={ filtersClass }>
        { filters }
        <Button icon="reset" className="reset" onClick={ onReset }>{ resetText }</Button>
      </div>
      <ToggleButtonGroup toggle={ false }>
        { children }
      </ToggleButtonGroup>
    </BorderContainer>
  )
}

export default FilterList