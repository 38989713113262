
import { getPathways } from './actions'

let id = 0

/**
 * Update pathways on organization change
 * @param {*} store 
 */
export const updatePathways = ( { getState, dispatch } ) =>
{
  const { offline, auth } = getState()  
  if ( offline.online && auth.id && auth.authenticated && auth.validated && auth.org_id && id !== auth.org_id )
  {  
    // store new org id
    id = auth.org_id
    // update pathways from server
    dispatch( getPathways( id ) )
  }
}