import { createSelector } from 'reselect'

// get auth from state
const selectAuth = ( state ) => state.auth

// memoize object
export const getAuth = createSelector(
    [ selectAuth ],
    ( auth ) => auth
)

// get user id
export const getId = createSelector(
    [ getAuth ],
    ( auth ) => auth.id
)

// get organization id
export const getOrgId = createSelector(
    [ getAuth ],
    ( auth ) => auth.org_id
)

// get user name
export const getName = createSelector(
    [ getAuth ],
    ( auth ) => auth.name
)

// get authenticated state 
export const getAuthenticated = createSelector(
    [ getAuth ],
    ( auth ) => auth.authenticated
)

// get processing state 
export const getProcessing = createSelector(
  [ getAuth ],
  ( auth ) => auth.processing
)

// get user valid state 
export const getValidated = createSelector(
    [ getAuth ],
    ( auth ) => auth.validated
)
