import React, { useState, useEffect } from 'react'
import { isItReallyAnObject } from '../tools/object'
import { Dropdown } from './dropdown'

/**
 * Item for use in dropdown list
 * @param {*} param0 
 */
export const DropdownListItem = ( { value = '', label = '', asset = null, className = '', ...rest } ) =>
{
  return (
    <div className={ `${className} dropdown-list-item` } { ...rest }>{ asset }{ label }</div>
  ) 
}

// Dropdown list, much like a HTML select
export const DropdownList = ( props ) => 
{
    // get properties we need and set defaults
    let { 
      className = '', 
      children = [], 
      label = '', 
      prefix = '', 
      value = '', 
      asset = null, 
      onChange = null,
      disabled = false,
      ...rest } = props

    const [ selected_value, setSelectedValue ] = useState( value )

    // maybe reset value
    useEffect( () => {  
      if ( value !== selected_value )
      {
        setSelectedValue( value )
      }
    }, [ value ] )

    if ( disabled ) className += ' disabled'

    // get children with value matching the root value
    const selected_children = children.filter( ( { props = {} } ) => isItReallyAnObject( props ) && props.hasOwnProperty( 'value' ) && props.value === selected_value )

    // get first child or null
    const first_child = children.length > 0 ? children[ 0 ] : null

    // get the first selected child, or the first child
    const selected_child = selected_children.length > 0 ? selected_children[ 0 ] : first_child

    // set label text based on selected child
    let toggle_text = label
    if ( selected_child && selected_child.props.hasOwnProperty( 'label' ) )
    {
      toggle_text = prefix ? prefix + ' ' + selected_child.props.label.toLowerCase() : selected_child.props.label
    } 

    // add className to a child
    const addClassName = ( child, className ) =>
    (
      child.props.hasOwnProperty( 'className' ) ? child.props.className + ' ' + className : className
    )

    // add unique key and change handler
    const items = children.map( ( child, index ) => (
      React.cloneElement( child, { 
        ...child.props,
        key: 'item-' + index,
        className: addClassName( child, selected_value === child.props.value ? 'active' : '' ),
        onClick: () => {
          if ( disabled ) return false
          // set selected value state
          setSelectedValue( child.props.value )
          // pass changed value to onChange handler
          if ( onChange ) onChange( child.props.value )
        }
      } )
    ) )
  
    return (
      <Dropdown 
        toggle_asset={ selected_child && selected_child.props.hasOwnProperty( 'asset' ) ? selected_child.props.asset : asset }
        toggle_text={ toggle_text }
        className={ `${className} dropdown-list` } 
        { ...rest } > 
        { items }
      </Dropdown>
    )    
}

export default DropdownList