import { isArray } from 'lodash'
import React from 'react'
import { Button } from '../../components/form'
import { isItReallyAnObject } from '../../tools/object'

const TaskButton = ( { task = null, className = '', children = null, ...rest } ) =>
{
  // bail if there is no task
  if ( !task || !isItReallyAnObject( task ) ) return null

  // catch nested task 
  if ( task.hasOwnProperty( 'task' ) ) task = task.task

  // add task class
  className += ' task'

  // add added / done state
  if ( !task.added ) className += ' adding'
  else className += task.done  ? ' done' : ' not-done'

  // add icon
  if ( task.added && !task.done ) className += ' icon-check-off'
  if ( task.added && task.done )  className += ' icon-check-on'
  
  // return the button
  return (
    <Button square className={ className } { ...rest }>              
      { isArray( children ) ? children : [ children ] }
    </Button>
  )
}

export default TaskButton