import { combineReducers } from 'redux';

import onlineReducer from './online/reducer'
import authReducer from './auth/reducer'
import languageReducer from './language/reducer'
import usersReducer from './users/reducer'
import pathwayReducer from './pathways/reducer'

const rootReducer = combineReducers({
  // online reducer adds some extra offline features such as last action rehydration state
  online: onlineReducer,
  // auth reducer holds current user ID, organization, credentials
  auth: authReducer,
  // language reducer holds translated strings
  language: languageReducer,
  // users reducer holds a list of users that have logged in before, including user details, goals and pahtway progress
  users: usersReducer,
  // pahways reducer holds a list of pathways, goals and steps
  pathways: pathwayReducer
});

export default rootReducer;