import React, { useState } from 'react'
import { Dropdown } from './dropdown'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import LanguageFlag from './language-flag'

// get language by locale
const getLangName = ( locale ) => 
{
    return i18n.options.resources[ locale ].common.language.name;
}

const LanguageItem = ( { locale, name = getLangName( locale ), active, onClick } ) => 
{
    return (
        <div className={`lang-item lang-${ locale } ${ active ? 'lang-active' : '' }`} onClick={ onClick }>
            <LanguageFlag locale={ locale } alt={ name } />
            <span className="lang-name">{ name }</span>
        </div>        
    )
}

// dropdown base features
const LanguageDropdown = ( props ) => 
{
    const { t, i18n } = useTranslation();
    const [ currentLang, setLang ] = useState( i18n.language ? i18n.language : 'en' )

    // change language on click
    const changeLang = ( locale ) => {
        setLang( locale )
        i18n.changeLanguage( locale )
    }   

    let { className, ...rest } = props
    className += ' language-dropdown'
    
    // build a list of language items
    const items = []
    for( let locale in i18n.options.resources )
    {
        items.push( 
            <LanguageItem 
                key={ locale } 
                locale={ locale } 
                active={ locale === currentLang }
                onClick={ () => changeLang( locale ) } 
            /> )
    }    

    const name = getLangName( currentLang )
    const flag = <LanguageFlag locale={ currentLang } alt={ name } /> 

    return (
        <Dropdown 
            toggle_asset={ flag } 
            toggle_text={ t( 'nav.change_language', 'Change language' ) } 
            className={ className } 
            animate='side'
            size='large'
            { ...rest } > 
            { items } 
        </Dropdown>
    )    
}

export default LanguageDropdown