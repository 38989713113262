const token_name = 'auth-token'

/**
 * Define the storage engine to use for storing the authentication token
 * sessionStorage; stores data during a browser sessions, data is removed when the browser (or tab) closes
 * localStorage; stores data longer, but on Android the data can be removed by the OS if storage is running low
 */
const engine = sessionStorage // localStorage or sessionStorage

export const setToken = ( token = '' ) => 
{
  engine.setItem( token_name, token )
}

export const getToken = () => 
{
  const token = engine.getItem( token_name )
  return token ? token : ''
}

export const removeToken = () => 
{
  return engine.removeItem( token_name )
}