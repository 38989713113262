import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import all_languages from './languages.json'
import config from '../config'
import { cloneObject } from '../tools/object'

// only add languages defined in config
let app_languages = {}
for( const locale in all_languages )
{
    // check if locale is defined in config
    if ( locale && config.language.locales.hasOwnProperty( locale ) )
    {
        // add it if so
        app_languages[ locale ] = cloneObject( all_languages[ locale ] )
    }
}

const debug = process.env.NODE_ENV !== 'production' && config.language.debug

// initialize translations
i18n
    .use( initReactI18next )
    // startup languages init
    .init({
        // debug issues
        debug: debug,
        // React already does escaping
        interpolation: { escapeValue: false },
        // use one namespace for everything
        ns: ['common'],
        defaultNS: 'common',
        // key seperator is a dot, this also means the API shouldn't call a flat but a nested JSON
        keySeparator: '.',
        // wait for resourceBundles to load
        initImmediate: false,
        // show whats going on
        // debug: true,
        // use English as the fallback language
        fallbackLng: 'en', 
        // define some resources, we will fill these later
        resources: cloneObject( app_languages ), 
        // maybe this fixes some bugs
        returnObjects: false,
        // use suspense
        react: {
            // wait: true,
            useSuspense: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: [ 'br', 'strong', 'i', 'b', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'em' ],
        }
    })

export default i18n;