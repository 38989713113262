import { getArrayProp, getBooleanProp, getNullProp, getNumberProp, getObjectProp, getStringProp, isItReallyAnObject } from '../../tools/object'

/**
 * Make sure user has progress array with pathway items with steps and history, optionally add step ids
 * @param {*} user 
 * @param {*} pathway 
 * @param {*} steps 
 */
export const schemaUserPathwayProgress = ( user = {} , pathway = '', steps = [] ) =>
{    
    // create progress object if missing
    user.progress = getArrayProp( user, 'progress')

    // find the index for the current pathway
    const index = user.progress.findIndex( p => p.pathway === pathway )

    // check or create pathway progress object, don't pass a negative indexed progress!
    const progress = schemaPathwayProgress( pathway, index < 0 ? null : user.progress[ index ] )

    // append or update the checked progress object
    if ( index < 0 )
    {
        user.progress.push( progress ) 
    }
    else
    {
        user.progress[ index ] = progress
    }

    // add steps if needed
    if ( steps && Array.isArray( steps ) )
    {
        for ( const step of steps )
        {
            // create step object if it's not there yet
            if ( !user.progress[ index ].steps.hasOwnProperty( step ) )
            {
                user.progress[ index ].steps[ step ] = {}
            }
        }
    }

    // return the modified user object
    return user
}

/**
 * Check required fields for a pathway progress object
 * @param {*} progress 
 */
export const schemaPathwayProgress = ( pathway = '', progress = {}  ) =>
{
    // progress needs to be an object
    progress = isItReallyAnObject( progress ) ? progress : {}

    // add pathway name
    if ( typeof pathway === 'string' && pathway )
    {
      progress.pathway = getStringProp( progress, 'pathway', pathway )
    }

    // create history array if missing
    progress.history = getArrayProp( progress, 'history' )

    // create steps object if missing
    progress.steps = getObjectProp( progress, 'steps' )

    // schema steps
    for( const step_id in progress.steps )
    {
        progress.steps[ step_id ] = schemaProgressStep( progress.steps[ step_id ] )
    }

    // create current prop if missing
    progress.current = getStringProp( progress, 'current' )

    // create goal prop if missing
    // progress.goal = getStringProp( progress, 'goal' )

    // create active boolean if missing
    progress.active = getBooleanProp( progress, 'active' )

    // add 'updated' timestamp, default to zero
    progress.updated = getNumberProp( progress, 'updated' )

    // fix a bug where pathway contains the progress???
    if ( pathway && isItReallyAnObject( pathway ) )
    {
        progress = {
            ...progress,
            ...pathway
        }
    }

  return progress
}

/**
 * Check a user step 
 * @param {*} step 
 */
export const schemaProgressStep = ( step = {} ) => 
{
    // progress needs to be an object
    step = isItReallyAnObject( step ) ? step : {}
    
    // create next ID if missing
    step.next = getStringProp( step, 'next' )

    // create feedback if missing
    step.feedback = getNumberProp( step, 'feedback' )

    // create task if missing
    step.task = getNullProp( step, 'task' ) 
    
    // create done state if missing
    step.done = getBooleanProp( step, 'done' )

    // create bookmark boolean if missing
    step.bookmark = getBooleanProp( step, 'bookmark' )

    // create popup instructions if missing
    step.popup = getStringProp( step, 'popup', '' )

    // add 'updated' timestamp, default to zero
    step.updated = getNumberProp( step, 'updated' )

    return step
}

/**
 * Schema the task object
 * @param {*} task 
 */
export const schemaTask = ( task = {}, add_dates = false ) =>
{
  // force an object
  task = isItReallyAnObject( task ) ? task : {}

  // add task name
  task.name = getStringProp( task, 'name' )

  // add task description
  task.content = getStringProp( task, 'content' )

  // add task repeat pattern
  task.repeat = getStringProp( task, 'repeat' )

  // add task 'done' timestamp ( or zero when not done )
  task.done = getNumberProp( task, 'done' )

  // add task 'required' boolean
  task.required = getBooleanProp( task, 'required' )

  // add task 'added' timestamp
  task.added = getNumberProp( task, 'added', add_dates ? new Date().getTime() : 0 )

  // add 'updated' timestamp, default to zero
  task.updated = getNumberProp( task, 'updated', add_dates ? new Date().getTime() : 0 )

  return task
}

export const schemaUser = ( user = {} ) => 
{
  user.id = getStringProp( user, 'id' )
  user.login = getStringProp( user, 'login' )
  user.name = getStringProp( user, 'name' )
  user.bookmarks = getObjectProp( user, 'bookmarks' )
  user.progress = getArrayProp( user, 'progress' )
  user.is_external = getBooleanProp( user, 'is_external', false )
  user.use_notes = getBooleanProp( user, 'use_notes', false )
  user.use_messages = getBooleanProp( user, 'use_messages', false )
  user.intro = getArrayProp( user, 'intro', false )
  return user
}