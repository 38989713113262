import React from 'react'
import Html from '../../components/html-content';
import { getBooleanProp } from '../../tools/object';
import ProgressBar from '../../components/progress-bar';

const PathwayChapters = ( props ) => 
{
  const { 
    chapters = [], 
    withText = true, 
    withCheck = true,
    withProgress = false,
    tag = 'h2',
    onClick = null, 
    className = '', 
    ...rest 
  } = props

  // don't render if there are no chapters
  if ( !chapters || chapters.length === 0 ) return null

  // build classNames
  let classNames = className + ' chapters-list'

  // header tag component
  const HeaderTag = `${tag}`;

  // get a checkbox
  const getCheckbox = ( on ) => <div className={ on ? 'checkbox icon-check-on ' : 'checkbox icon-check-off' }></div>

  return (
    <div className={ classNames } { ...rest }>
      {  
        chapters.map( ( chapter, index ) => {
          const done = getBooleanProp( chapter, 'done' )
          return <div className={ 'chapter ' +  ( done ? 'done' : '' ) } key={ index } onClick={ ( e ) => onClick( e, chapter ) }>
            { withCheck ? getCheckbox( done )  : null }
            <HeaderTag>{ chapter.title }</HeaderTag>
            { withText ? <p className="text"><Html>{ chapter.text }</Html></p> : null }
            { withProgress ? <ProgressBar percentage={ chapter.percentage }/> : null }
          </div>
        } )
      }
    </div>

  )
}

export default PathwayChapters