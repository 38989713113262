import React from 'react'

// PNG assets
import family from '../assets/achievements/family-fs8.png'
import health_front from '../assets/achievements/health-front-fs8.png'
import health_back from '../assets/achievements/health-back-fs8.png'
import hobby from '../assets/achievements/hobby-fs8.png'
import house from '../assets/achievements/house-fs8.png'
import money from '../assets/achievements/money-fs8.png'
import study from '../assets/achievements/study-fs8.png'

// place => pathway => asset, order matters!
const all_visuals = {
  // behind the mountain
  behind: {
    'house': house 
  },
  // between mountain and avatar
  'middle': {
    'health': health_back,
    'family': family,
    'study': study
  },
  // in front of avatar
  front: {    
    'hobby': hobby,
    'money' : money,
    'health': health_front
  }
}

// convert pathway to visual name
const pathway2visual = ( pathway = '' ) => 
{
  switch ( pathway )
  {
    case 'money':
    case 'income':
    case 'money-income':
    case 'money_income':
      return 'money'

    case 'work-learn':
    case 'work_learn':
    case 'work-learning':
    case 'work_learning':
    case 'learn':
    case 'learning':
    case 'work':
    case 'study':
      return 'study'

    case 'house':
    case 'housing':
      return 'house'

    case 'family':
    case 'friends':
    case 'family-friends':
    case 'family_friends':
    case 'friends-family':
    case 'friends_family':
    case 'partner':
    case 'partners':
      return 'family'

    case 'health':
    case 'wellbeing':
    case 'health-wellbeing':
    case 'health_wellbeing':
    case 'wellbeing-health':
    case 'wellbeing_health':
      return 'health'

    case 'hobby':
      return 'hobby'

    default:
      return ''
  }
}

// show all pathways as completed, for testing only!
const dummyPathways = () =>
{
  const pathways = [ 'hobby', 'health', 'family', 'house', 'study', 'money' ]
  return pathways.map( p => ( {
    pathway_id: p,
    percentage: 100 // Math.random() * 50 + 50
  }))
}

const ProgressVisuals = ( { pathways, className = '', place = '' } ) => {

  // set classnames
  className += ' progress-visuals place-' + place

  // get visuals for placement
  const visuals = all_visuals.hasOwnProperty( place ) ? all_visuals[ place ] : null
  if ( !visuals ) return null

  // for testing only
  // pathways = dummyPathways()

  // render the visuals
  const renderVisuals = () =>
  {
    let images = []

    // make sure that visuals is not empty
    if ( visuals )
    {
      // go over each possible visual
      for ( let visual in visuals )
      {
        const src = visuals[ visual ],
              pathway = pathways.filter( ( p ) => pathway2visual( p.pathway_id ) === visual )

        // make sure pathway exists for visual
        if ( pathway.length )
        {
                // opacity will be square rooted later for a more dramatic effect
          const opacity = pathway[ 0 ].percentage / 100,
                // alt of the image is the pathway name
                alt = pathway[ 0 ].pathway_name
          images.push( <img src={ src } className={ visual } alt={ alt } key={ visual } style={ { opacity: opacity * opacity } }/>)
        }
      }
    }

    return images
  }

  // render the visuals wrapper
  return (
    <div className={ className }>
      { renderVisuals() }
    </div>
  )
}

export default ProgressVisuals