import React from 'react'
import { useTranslation } from 'react-i18next'

// components
import { SpeechContainer } from '../../components/containers'
import { Button } from '../../components/form'
import Html from '../../components/html-content'

/**
 * Timeline tooltip, can take many forms
 * @param {*} props 
 */
export const Tooltip = ( props ) =>
{  
  // hooks
  const { t } = useTranslation()

  // get many things from the props
  const { 
    item = null, 
    className = '', 
    setPopup = null
  } = props ;  
  
  // no data? nothing to show!
  if ( !item )
  {
    return null
  }

  // default button text, differs for first step
  const step_button = item.index === 0
    ? t( 'pathways.button_step_first', 'Take the first step' ) 
    : t( 'pathways.button_step', 'Take this step' ) 

  // tell parent which popup to show
  const showPopup = () => setPopup( item.id, 'show' )

  // get the item tooltip
  const getItemTooltip = () =>
  (
    /* tooltip, if available */
    item.time && item.time.tip && item.time.tip.trim()
    ? <p><Html>{ item.time.tip }</Html></p>
    : null
  )

  const getButtonText = () =>
  {
    if ( item.done )
    {
      return t( 'pathways.button_again', 'Take this step again' )
    }
    return item.time && item.time.button ? item.time.button : step_button
  }

  // render the tooltip
  return (
    <SpeechContainer className={ `tooltip ${className}` } center>
      { getItemTooltip() }
      <Button key="step" onClick={ showPopup }>{ getButtonText() }</Button>
    </SpeechContainer>   
  )
}