import { useEffect } from 'react'
import { useLocation } from 'react-router';

const IframeResize = () =>
{
  // get location
  const location = useLocation()

  // iframe resize script
  const resizeIframes = () =>
  {
    const iframes = document.getElementsByTagName( 'iframe' )
    if ( iframes )
    {
      for ( const iframe of iframes )
      {
        // skip resize if iframe has no source
        if ( iframe.getAttribute( 'src' ) )
        {
          // get dimensions
          const w = parseInt( iframe.getAttribute( 'width' ) ),
                h = parseInt( iframe.getAttribute( 'height' ) )

          if ( w && h && !isNaN( w ) && !isNaN( h ) && w > 0 && h > 0 )
          {
            iframe.style.height = iframe.offsetWidth / ( w / h ) + 'px'
          }
        }
      }
    }
  }

  // maybe resize embeds on page load
  useEffect( () => {  
    // resize iframes on load
    resizeIframes()
    // resize iframes on window resize
    window.addEventListener( 'resize', resizeIframes )      
    // remove resize event on unload
    return () => window.removeEventListener( 'resize', resizeIframes )
  }, [ location ] )

  // run once now since location change doesn't trigger on the 'first' pages that gets loaded
  resizeIframes()

  // no need to render anything
  return null
}

export default IframeResize