import React from 'react'
import AvatarChooser from '../../components/avatar-chooser'
import { useTranslation } from 'react-i18next';
import { IntroButtons } from './buttons'
import { SpeechContainer } from '../../components/containers'
import { useSelector } from 'react-redux'
import { getAvatar, getName } from '../../redux/users/selectors'
import Html from '../../components/html-content';

const IntroAvatar = ( { goNext = null, hideNext = false, doSkip = null } ) => {
  const { t } = useTranslation()
  const name  = useSelector( getName )
  const avatar = useSelector( getAvatar )

  return (
    <div className="intro-avatar-chooser">
      <h1><Html>{ t( 'intro.welcome', 'Hello' ) }</Html> <span className="name">{ name }</span></h1>      
      <p><Html>{ 
        // avatar state decides the subtitle
        avatar === '' 
        ? t( 'avatar.subtitle_new', "Let's choose your avatar" ) 
        : t( 'avatar.subtitle_change', "Let's change your avatar" ) 
      }</Html></p> 
      <SpeechContainer center>
        <AvatarChooser onDone={ goNext }/>  
      </SpeechContainer>
      <IntroButtons key="intro-buttons" disablePrev={ true } hideNext={ hideNext } goNext={ goNext } doSkip={ doSkip }/>
    </div>    
  )
}

export default IntroAvatar