import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addTask, finishTask } from '../../redux/users/actions'

// components
import Popup from '../../components/popup'
import { Button } from '../../components/form'
import Html from '../../components/html-content'
import IcomoonIcon from '../../components/icomoon-icon'
import { getPathways } from '../../redux/pathways/selectors'
import PathwayIcon from '../../components/pathway-icon'
import { useHistory, useLocation } from 'react-router'
import { DateText } from '../../components/date'
import TaskButton from './task-button'
import { SpeechButton } from '../../components/speech-button'
import TaskTrashConfirm from './task-trash-confirm'

/**
 * Timeline popup, popups when button is clicked
 * @param {*} props 
 */
const TaskPopup = ( { data = null, className = '', onClose = null, ...rest } ) =>
{
  // hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pathways = useSelector( getPathways )
  const history = useHistory()
  const location = useLocation()

  const [ showTrash, setShowTrash ] = useState( false )

  // data is a step object with a task prop
  const task = data && data.hasOwnProperty( 'task' ) ? data.task : null

  const pathway = pathways.hasOwnProperty( data.pathway ) ? pathways[ data.pathway ] : null
  const pathway_title = pathway ? pathway.title : ''
  const step = pathway && pathway.steps.hasOwnProperty( data.id ) ? pathway.steps[ data.id ] : null
  const image = step ? step.img : null

  // popup needs data
  if ( !task ) return null  

  // update task form
  const doFinishTask = () => {
    // store the modified task
    dispatch( finishTask( data.pathway, data.id, task ) )
  }

  // update task form
  const doAddTask = () => {
    // store the modified task
    dispatch( addTask( data.pathway, data.id, task ) )
  }

  // go to the pathway of the current task
  const toPathway = () => 
  {
    // always close this popup
    if ( onClose ) onClose()
    // go to the root of the pathway; let the timeline component figure out which step needs to be displayed
    const path = '/pathways/' + data.pathway
    // make sure we're not already on that pathway
    if ( !location.pathname.includes( path ) )
    {
      // go there
      history.push( path )
    }    
  }

  // get date element
  const getDate = () =>
  {
    if ( task.done ) return <DateText timestamp={ task.done }/>
    if ( task.added ) return <DateText timestamp={ task.added }/>
    return null
  }

  // get button text before getting text2speak
  let task_button_text = t('task.add_button', 'Add task')
  if ( task.added )
  {
    task_button_text = task.done
      ? t('task.finished_button', 'Task finished') 
      : t('task.finish_button', 'Finish task') 
  }

  // get button text before getting text2speak
  const pathway_button_text = t('task.pathway_button', 'To') + ' ' + pathway_title

  // get text2speak contents
  const getText2Speak = () => 
  {
    const popup_id = '.popup.task-' + data.id + ' '

    // initial array contains title, intro & text
    let content = [
      {
        text: pathway_title + '.  ', 
        elQuery: popup_id + 'h1',
      },
      {
        text: task.name + '.  ', 
        elQuery: popup_id + 'h2'
      },
      {
        text: task.content + '.  ',
        elQuery: popup_id + '.task-content'
      }
    ] 

    // add task button
    content.push( {
      // build dom query
      elQuery: popup_id + '.button.task',
      // use hover class for focus
      elClass: 'hover',
      // add text
      text: task_button_text + '.  '
    } )
    
    // add pathway button
    content.push( {
      // build dom query
      elQuery: popup_id + '.button.secondary',
      // use hover class for focus
      elClass: 'hover',
      // add text
      text: pathway_button_text + '.  '
    } )

    return content
  }

  // get popup bar content
  const getBar = () =>
  {
    let children = []

    if ( task.added )
    {
      children.push(
        task.done 
          ? <Button icon="check-on" onClick={ null }>{ t( 'task.finished_task', 'Task finished' ) }</Button>
          : <Button icon="check-off" onClick={ doFinishTask }>{ t( 'task.finish_task', 'Finish task' ) }</Button>
      )
    }
    else
    {
      children.push(
         <Button icon="plus" onClick={ doAddTask }>{ t( 'task.add_task', 'Add task' ) }</Button>
      )
    }

    children.push(
      <SpeechButton text={ getText2Speak() } />
    )

    if ( task.added )
    {
      // add delete button
      children.push(
        <Button icon="trash" onClick={ onTrash }>{ t( 'task.remove_task', 'Remove task' ) }</Button>
      )
    }

    return children
  }

  // get popup footer content
  const getFooter = () =>  
  (
    <React.Fragment>

      { /* Show add task / finish task button */}
      { task.added 
        ? <TaskButton key="finish" onClick={ doFinishTask } task={ task }>              
            { task_button_text }
          </TaskButton>
        : <TaskButton key="add" onClick={ doAddTask } task={ task }>
            { task_button_text }
          </TaskButton>
      }
      
      { /* Always show pathway button */}
      <Button key="pathway" secondary large square onClick={ toPathway }>
        <PathwayIcon pathway={ data.pathway} /> 
        { pathway_button_text }
        <IcomoonIcon icon="arrow" right/>
      </Button>

    </React.Fragment>
  )

  // init trash confirm
  const onTrash = ( e ) => 
  {
    setShowTrash( true )
    e.stopPropagation()     
  }

  // close trash
  const onCloseTrash = () =>
  {
    setShowTrash( false )
    if ( onClose ) onClose()
  }

  return (
    <Popup 
      className={ className + ' popup task-popup task-' + data.id } 
      onClose={ onClose } 
      bar={ getBar() } 
      image={ image }
      { ...rest }
      >
      <h2 className="pathway-title"><PathwayIcon pathway={ data.pathway} />{ pathway_title } { getDate() }</h2>
      <h1 className="task-title">{ task.name }</h1>
      <Html className="task-content">{ task.content }</Html>  
      { getFooter() }
      <TaskTrashConfirm task={ data } show={ showTrash } onClose={ onCloseTrash }/>
    </Popup>
  )
}

export default TaskPopup