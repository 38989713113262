// redux
import { applyMiddleware, createStore, compose } from 'redux'

// app config
import config from '../config'

// redux offline 
import offlineConfig from '@redux-offline/redux-offline/lib/defaults'
import { createOffline } from '@redux-offline/redux-offline'

// middlewares
import thunk from 'redux-thunk'
import logger from 'redux-logger'

// App detector
import { Capacitor } from '@capacitor/core';

// indexDB instead of localStorage
import * as localforage from 'localforage'

// session storage on browsers
import { asyncSessionStorage } from 'redux-persist/lib/storage'

// root reducer
import rootReducer from './root-reducer'

// use api request to handle calls
import { Request } from './api' 

// subscriptions
import { subscriptionMiddleware, subscribe } from './middleware/subscriptions'
import { updateLanguagesOnce } from './language/subscriptions'
import { validateAuthOnline, validateAuthOffline } from './auth/subscriptions'
import { updatePathways } from './pathways/subscriptions'
import { saveProgress, updateUser } from './users/subscriptions'

// current user injects his user_id into the action
import { currentUserMiddleware } from './middleware/current-user'

// set redux-persist options
offlineConfig.persistOptions = { 
  // don't persist auth, online
  blacklist: [
    'auth',
    'online'
  ]
}; 

// check for old IE
const isIE = /*@cc_on!@*/false || !!document.documentMode;

/*
  Set offline / local storage option depending on platform
*/

// use indexDB / localForage as storage in App
if ( Capacitor.isNative )
{
  offlineConfig.persistOptions.storage = localforage
}
else if ( !isIE )
{
  // store offline data in session storage when using a non-IE browser;
  // this way data is removed when the browser or tab is closed
  // thus preventing possible privacy issues
  offlineConfig.persistOptions.storage = asyncSessionStorage
}
else
{
  // In IE browsers, don't use storage at all since it's not supported and causes errors
}

// use axios instead of fetch
offlineConfig.effect = ( effect, action ) =>
{  
  const { url, method, data } = effect,
        { payload } = action

  return Request( {
    url: url,
    data: data ? data : payload,
    method: method,
    dispatch: store.dispatch
  } )
}

// use offline enhancer otherwise succes and rollback actions don't fire
const { middleware, enhanceReducer, enhanceStore } = createOffline( offlineConfig )

// required middlewares
const middlewares = [
  thunk, 
  middleware, 
  subscriptionMiddleware, 
  currentUserMiddleware 
]

// add middlewares when NOT in production, can be configure in config.js file
if ( process.env.NODE_ENV !== 'production' && config.store.log_redux )
{
  middlewares.push( logger )
}

// create store
const store = createStore(
  // add reducer
  enhanceReducer( rootReducer ),
  // inital state
  {},
  // add middleware; enhanceStore always comes first
  compose(
    enhanceStore,
    applyMiddleware( ...middlewares )
  )
)

// validate any existing token once
subscribe( validateAuthOnline )
// or check it exising user is still valid
subscribe( validateAuthOffline )
// update languages for login and dropdown once
subscribe( updateLanguagesOnce )
// watch for user change
subscribe( updateUser )
// watch for organization change so we can get updated pathways data
subscribe( updatePathways )
// save user progress
subscribe( saveProgress )

export default store;