import React from 'react'
import { ReactComponent as IconArrow } from '../assets/icons/arrow.svg'
/*
 * Form mith styles
 */
export const Form = ( { className = '', children, ...rest } ) => (
    <form className={ `form ${className}` } { ...rest}>
        { children }
    </form>
)

/*
 * Base button
 */
export const Button = ( props ) => {
    
    let { 
        className = '', 
        primary = false,
        secondary = false, 
        large = false,
        fullwidth = false, 
        disabled = false,
        hidden = false,
        left = false,
        right = false,
        empty = false,
        square = false,
        round = false,
        icon = '',
        children, 
        asset = null,
        href=null,
        ...rest 
    } = props

    // convert props to class names, mind the SPACE before each class name!
    className += ' button'
    if ( primary && !secondary ) className += ' primary'
    if ( secondary && !primary ) className += ' secondary'
    if ( large ) className += ' large'
    if ( square ) className += ' square'
    if ( round ) className += ' round'
    if ( fullwidth ) className += ' fullwidth'
    if ( disabled ) className += ' disabled'
    if ( hidden ) className += ' hidden'
    if ( left && !right ) className += ' left'
    if ( right && !left ) className += ' right'
    if ( icon ) className += ' icon-' + icon
    if ( empty ) className += ' empty'
    if ( children ) className += ' with-text'
    if ( children ) className += ' no-text'

    // maybe add arrow icon asset
    if ( [ 'left', 'right', 'prev', 'next', 'up', 'down' ].includes( icon ) )
    {   
        asset = <IconArrow key="icon-arrow" className={ icon }/>
    }

    // no children on empty
    if ( empty )
    {
      children = null
    }

    // don't put active in the props, it triggers an error
    if ( rest.hasOwnProperty( 'active' ) )
    {
        delete rest.active
    }

    const Tag = href ? 'a' : 'button'

    // return an html button
    return (
      <Tag className={ className } href={ href } { ...rest }>
        { [ asset ] }
        { children ? <span className="text">{ children }</span> : null }
      </Tag>
    )
}

/**
 * Base arrow button
 * @param {*} props 
 */
const ArrowButton = ( props ) => 
{
    // remove everything from the props we don't want
    const { 
        children, 
        empty, 
        secondary, 
        primary, 
        icon = 'right',
        ...rest 
    } = props  
    
    return <Button secondary={ true } primary={ false } empty={ true } icon={ icon } { ...rest }>{ null }</Button>
}

/*
 * Left arrow button
 */
export const LeftArrowButton = props => {
  return <ArrowButton icon='left' { ...props }/>
}

/*
 * Right arrow button
 */
export const RightArrowButton = props => {
  return <ArrowButton icon='right' { ...props }/>
}

/*
 * Up arrow button
 */
export const UpArrowButton = props => {
  return <ArrowButton icon='up' { ...props }/>
}

/*
 * Down arrow button
 */
export const DownArrowButton = props => {
  return <ArrowButton icon='down' { ...props }/>
}

/*
 * Primary button
 */
export const PrimaryButton = ( { children, ...rest } ) => {
  return <Button secondary={ false } { ...rest }>{ children }</Button>
}

/*
 * Secondary button
 */
export const SecondaryButton = ( { children, ...rest } ) => {
  return <Button secondary={ true } { ...rest }>{ children }</Button>
}

/*
* Form input field
*/ 
export const Input = ( { className, children, ...rest } ) => (
    <input className={ `input ${className}` } { ...rest}/>
)

/*
* Form textarea field
*/ 
export const Textarea = ( { className, rows = 4, ...rest } ) => (
    // value is set by the value prop, just like a regular input
    <textarea className={ `input textarea ${className}` } rows={ rows } { ...rest}/>
)

/**
 * Form select field
 * @param {*} param0 
 */
export const Select = ( { className = '', options = [], value = '', ...rest } ) => (
    <select value={ value } className={ `input select ${className}` } { ...rest }>
    { options.map( ( { value ='', label = '' } ) => <option value={ value }>{ typeof label === 'string' ? label : '' } </option> ) }
    </select>
)

/*
* Form label
*/
export const Label = ( { className, children, ...rest } ) => (
    <label className={ `label ${className}` } { ...rest}>
        { children }
    </label>
)

/*
* Form input with label
*/
export const LabeledInput = ( props ) => 
{
    let {
        className = '',
        type = 'text',
        children = null,
        required = false,
        ...rest
    } = props;

    className += 'label-input type-' + type;
    if ( required ) className += ' required';

    return (
        <div className={ className }>
            <Label>
                <span>{ children }</span>
                <Input type={ type } { ...rest } />
            </Label>
        </div>
    )
}

/*
* Form input with label
*/
export const LabeledTextarea = ( props ) => 
{
    let {
        className = '',
        required = false,
        children = null,
        ...rest
    } = props;

    className += 'label-input type-textarea';
    if ( required ) className += ' required';

    return (
        <div className={ className }>
            <Label>
                <span>{ children }</span>
                <Textarea { ...rest }/>
            </Label>
        </div>
    )
}

/*
* Form input with label
*/
export const LabeledSelect = ( props ) => 
{
    let {
        className = '',
        required = false,
        children = null,
        ...rest
    } = props;

    className += 'label-input type-select';
    if ( required ) className += ' required';

    return (
        <div className={ className }>
            <Label>
                <span>{ children }</span>
                <Select { ...rest }/>
            </Label>
        </div>
    )
}