
const lang = navigator.language || navigator.userLanguage;

// timestamp to date string
export const timestamp2string = ( timestamp ) => (
  timestamp2day( timestamp ) + ' ' + 
  timestamp2month( timestamp ) + ' ' + 
  timestamp2year( timestamp )
)

// timestamp to day number
export const timestamp2day = ( timestamp ) => new Date( timestamp ).getDate() 

// timestamp to full month name
export const timestamp2month = ( timestamp ) =>  new Date( timestamp ).toLocaleDateString( lang, { month: 'long' } )

// timestamp to year
export const timestamp2year = ( timestamp ) => new Date( timestamp ).getFullYear()
