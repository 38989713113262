import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// components
import ProgressBar from './progress-bar'
import { Button } from './form'
import Html from './html-content'
import { ReactComponent as IconDone } from '../assets/icons/done.svg'
// import PathwayChapters from '../pages/intro/pathway-chapters'

const ProgressDone = ( { children = null, icon = null, className = '', ...rest } ) => {
  const { t } = useTranslation()
  return (
    <div className={ `progress-bar-done ${className}` }>
      { icon ? icon : <IconDone className="icon"/> }
      <Html className="text">{ children ? children : t('pathways.done', 'All done!') }</Html>
    </div>
  )
}

/**
 * Basic toggle button
 * @param {*} props 
 */
export const ToggleButton = ( props ) => 
{
  let  {      
    active = false, 
    disabled = false, 
    className = '',
    children,
    ...rest 
  } = props

  className += ' toggle-button'
  if ( !!active ) className += ' active';
  if ( !!disabled ) className += ' disabled';

  return (
    <div className={ className } { ...rest }>
        { children }
    </div> 
  )
}

/**
 * Toggle button with asset, title and subtitle
 * @param {*} param
 */
export const AssetButton = ( props ) => 
{
  let  { 
    title = '',
    subtitle = '',
    className = '',
    asset = null, 
    active = false,
    children,
    ...rest 
  } = props

  // maybe convert asset to image
  if ( 'string' === typeof asset )
  {
    asset = <img src={ asset } alt={ title }/>
  }

  className += ' asset-button'
  className += asset ? ' has-asset' : ' no-asset'
  className += title ? ' has-title' : ' no-title'
  className += subtitle ? ' has-subtitle' : ' no-subtitle'
  className += title && subtitle  ? ' has-content' : ' no-content'
  // className += active ? ' is-active' : ''

  return (
    <ToggleButton active={ active } className={ className } { ...rest }>
        { asset ? <div className="asset">{ asset }</div> : null }
        <div className="content">
          { title ? <Html className="title">{ title }</Html> : null }
          { subtitle ? <Html className="subtitle">{ subtitle }</Html> : null }
          { children }
        </div>        
    </ToggleButton> 
  )
}

/**
 * Toggle button with an asset, title, progress bar and button
 * @param {*} props 
 */
export const ToggleProgressButton = ( props ) =>
{
  let  { 
    className = '',
    button_text = '',
    button_icon = '',
    active = false,
    progress = 0,
    children = null,
    ...rest 
  } = props

  // active is always false 
  active = false

  className += ' progress-button'
  // add 0 to 10 class, maybe for giving color to the progress bar
  className += ' progress-' + Math.round( progress / 10 )
  className += 100 === progress ? ' progress-done' : ''
  // button or no?
  className += button_text || button_icon ? ' has-button' : ' no-button'

  return (
    <AssetButton active={ active } className={ className } { ...rest }>
        { progress < 100
          ? <ProgressBar percentage={ progress }/>
          : <ProgressDone/>
        }
        { button_text || button_icon ? <Button disabled={ rest.disabled } icon={ button_icon }><Html>{ button_text }</Html></Button> : null }
        { children }
    </AssetButton>
  )
}

/**
 * Toggle button group
 */
export const ToggleButtonGroup = ( props ) => 
{
  let { 
    type = 'medium', 
    selected = '', 
    onChange = null, 
    noClick = false, 
    className = '', 
    children = null,
    toggle = true,
    ...rest
  } = props

  // build classNames
  className += ' toggle-button-group'
  if ( type ) className += ' type-' + type
  if ( noClick ) className += ' noclick'

  // use state to set the active button
  // start with the selected value from props
  const [ active, setActive ] = useState( selected )

  // button click event
  const onClick = ( key = '' ) =>
  {
    // maybe pass change to parent
    if ( onChange )
    {
      onChange( key )
    }

    // update state to reflect change
    if ( toggle )
    {
      setActive( key )
    }
  }

  // decide what happens on click
  const getOnClick = ( child ) =>
  {
    // return null when specifically told not to click at all
    if ( noClick ) return null
    // maybe user child onClick
    if ( child.props.onClick ) return child.props.onClick 
    // otherwise use default onClick
    return () => onClick( child.key )
  }

  if ( Array.isArray( children ) )
  {
    // sort so disabled items are last
    children.sort( ( a, b ) => {
      if ( a.props.disabled && !b.props.disabled ) return 1
      if ( !a.props.disabled && b.props.disabled ) return -1
      return 0
    } )
  }

  // add onClick and set active on mapped children
  // return null if there are no children, it happens
  const new_children = Array.isArray( children ) ? children.map( ( child, index ) => (
    React.cloneElement( child, { 
      ...child.props,
      ...{
        key: child.key ? child.key : index,
        // only overwrite onClick if child doesn't have one yet
        onClick: getOnClick( child ),
        // set active state
        active: child.key === active 
      }
    } )
   ) ) : null

  return (
    <div className={ className } { ...rest }>{ new_children }</div>
  )
}