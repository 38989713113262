import React from 'react'

// assets
import { ReactComponent as FlagEN } from '../assets/flags/en.svg'
import { ReactComponent as FlagIT } from '../assets/flags/it.svg'
import { ReactComponent as FlagNL } from '../assets/flags/nl.svg'
import { ReactComponent as FlagPT } from '../assets/flags/pt.svg'
import { ReactComponent as FlagRO } from '../assets/flags/ro.svg'
import { ReactComponent as FlagTR } from '../assets/flags/tr.svg'

const LanguageFlag = ( { locale = '', className = '', ...rest } ) => 
{
  className += ' lang-flag flag-' + locale
  switch ( locale )
  {
    case 'en':
      return <FlagEN className={ className } { ...rest }/>

    case 'it':
      return <FlagIT className={ className } { ...rest }/>

    case 'nl':
      return <FlagNL className={ className } { ...rest }/>

    case 'pt':
      return <FlagPT className={ className } { ...rest }/>

    case 'ro':
      return <FlagRO className={ className } { ...rest }/>

    case 'tr':
      return <FlagTR className={ className } { ...rest }/>

    default:
      return null
  }
}

export default LanguageFlag
