import { actionTypes } from './types'

/**
 * Load languages from server
 */
export const getLanguages = () => ({
  type: actionTypes.GET_LANGUAGES,
  payload: {},
  meta: {
    offline: {
      effect: {
        url: 'languages'
      },  
      commit: { 
        type: actionTypes.GET_LANGUAGES_SUCCES 
      },
      rollback: { 
        type: actionTypes.GET_LANGUAGES_ROLLBACK
      }
    }
  }
});

/**
 * Set current language
 * @param {*} locale 
 */
export const setLanguage = ( locale = 'en' ) => ({
  type: actionTypes.SET_LANGUAGE,
  payload: locale
})