/**
 * Because we really need to know sometimes
 * @param {*} object 
 */
export const isItReallyAnObject = object => typeof object === 'object' && object !== null && !Array.isArray( object )

/**
 * Return object property if key is an object, otherwise return empty object
 * @param {*} obj 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
export const getObjectProp = ( obj = {}, key = '', value = {} ) => obj.hasOwnProperty( key ) && isItReallyAnObject( obj[ key ] ) ? obj[ key ] : value

/**
 * Return object property if key is an array, otherwise return empty array
 * @param {*} obj 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
 export const getArrayProp = ( obj = {}, key = '', value = [] ) => obj.hasOwnProperty( key ) && Array.isArray( obj[ key ] ) ? obj[ key ] : value

 /**
 * Return object property if key is a string, otherwise return empty string
 * @param {*} obj 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
export const getStringProp = ( obj = {}, key = '', value ='' ) => obj.hasOwnProperty( key ) && typeof obj[ key ] === 'string' ? obj[ key ] : value

/**
 * Return object property if key is a boolean, otherwise return false
 * @param {*} obj 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
export const getBooleanProp = ( obj = {}, key = '', value = false ) => obj.hasOwnProperty( key ) && typeof obj[ key ] === 'boolean' ? obj[ key ] : value

/**
 * Return property of any kind, otherwise return false
 * @param {*} obj 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
 export const getAnyProp = ( obj = {}, key = '', value = false ) => obj.hasOwnProperty( key ) ? obj[ key ] : value

/**
 * Return object property if key is an integer , otherwise return 0
 * @param {*} obj 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
 export const getNumberProp = ( obj = {}, key = '', value = 0 ) => obj.hasOwnProperty( key ) && typeof obj[ key ] === 'number' ? obj[ key ] : value

 /**
 * Return object property if key exists , otherwise return null
 * @param {*} obj 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
  export const getNullProp = ( obj = {}, key = '', value = null ) => obj.hasOwnProperty( key ) ? obj[ key ] : value

/**
 * Deep clone an object
 * @param {*} object 
 */
export const cloneObject = object => JSON.parse( JSON.stringify( object ) )

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export const mergeObjects = ( target, source ) => 
{
  if ( isItReallyAnObject( target ) && isItReallyAnObject( source ) )
  {
    for (const key in source) {
      if (isItReallyAnObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeObjects(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return target;
}

/**
 * Filter an object on value
 * See https://stackoverflow.com/questions/5072136/javascript-filter-for-objects
 * Option 2 also returns an object if everything is filtered out
 * @param {*} obj 
 * @param {*} predicate 
 * @returns 
 */
export const FilterObject = ( obj = {}, predicate = n => n !== '' ) => 
  Object.keys( obj )
    .filter( key => predicate( obj[ key ] ) )
    .reduce( ( res, key ) => Object.assign( res, { [ key ]: obj[ key ] } ), {} )
