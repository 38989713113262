import React, { useState } from 'react'

import config from '../config'
import { useLocation, useHistory, useParams } from "react-router-dom"

// components
import ProgressBar from '../components/progress-bar'
import { Container } from '../components/containers'
import AnimatedSwitch from '../components/animated-switch'
import PrivateRoute from '../components/private-route'
import Page from '../components/page'

// sub pages
import IntroAvatar from './intro/avatar'
import IntroText from './intro/text'
import IntroPathways from './intro/pathways-overview'
import IntroSummary from './intro/summary-pathways'
import { useDispatch, useSelector } from 'react-redux'
import { getAvatar } from '../redux/users/selectors'
import { setAvatar } from '../redux/users/actions'

const Intro = () => 
{
  // step always starts at zero if you (re)enter the intro
  const [ step, setStep ] = useState( 0 )

  // get intro step number from params, STRING not INT
  // NOTE: this is a 1-based number, state step is a ZERO based number
  let { step:step_param = '' } = useParams()
  step_param = parseInt( step_param, 10 )
  step_param = isNaN( step_param) ? 0 : step_param

  // animation direction is stored in state
  const [ direction, setDirection ] = useState( 'next' )

  // use location and history to allow the user to use the back button
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()  
  const avatar = useSelector( getAvatar )

  // step parameter is leading; change page if the step param differs from the state step
  // this way user can jump into the intro in any step
  if ( step !== step_param - 1 && step_param > 0 )
  {
    setStep( step_param - 1 )
    return null
  }

  // go to step 1
  if ( location.pathname === '/intro' )
  {
    // replace not push, otherwise animation gets messed up
    history.replace( '/intro/1' )
    return null
  }

  // setting avatar to none once means intro is done, 
  // user can still choose an avatar but intro will only be forced once this way
  if ( !avatar )
  {
    dispatch( setAvatar( 'none' ) )
  }

  // clicking next button
  const goNext = () => goTo( limitStep( step + 1 ) )

  // clicking prev button
  const goPrev = () => goTo( limitStep( step - 1 ) )

  // skip intro and go home
  const doSkip = () => history.push( '/' )

  // go to a specific step
  const goTo = ( num = 0 ) => 
  {    
    if ( num !== step )
    {
      // always scroll to top so progress bar can be seen
      window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } )
      // decide on the animation direction
      setDirection( num < step ? 'prev' : 'next' )
      // go to the step
      setStep( limitStep( num ) )
      // show in browser url
      history.push( '/intro/' + ( num + 1 ) )
    }
  }

  // keep new step within step bounds
  const limitStep = ( s ) => Math.max( 0, Math.min( steps, parseInt( s ) ) )

  // cannot destructure components in a component, but this works
  const components = [
    // maybe use avatar
    config.intro.use_avatar ? <IntroAvatar key="step-1" goNext={ goNext } doSkip={ doSkip } /> : null, 
    // add the intro if user hasn't been here before
    <IntroText key="step-2" goNext={ goNext } goPrev={ goPrev } hidePrev={ !config.intro.use_avatar } doSkip={ doSkip } />,
    // add pathways if user hasn't been here before
    <IntroPathways key="step-3" goNext={ goNext } goPrev={ goPrev } doSkip={ doSkip }/>,
    // add pathways summary if user hasn't been here before
    <IntroSummary key="step-4" goPrev={ goPrev } doSkip={ doSkip } />
    // remove null values
  ].filter( c => c !== null )

  // calculate percentage based on current step and components added
  const steps = components.length

  // remove one from steps since we're done at the last step
  const percentage = Math.round( 100 * step / ( steps - 1 ) )
  
  // convert components to routers
  const routes = components.map( ( component, index ) => (
    <PrivateRoute 
      key={ `intro-${index+1}` } 
      exact={ true } 
      path={ `/intro/${index+1}`  }
    >{ component }</PrivateRoute>
  ) )

  // render
  return (
    <Page page="intro">

      <Container center>

        { /* Always show the progress bar on top, outside of the route */ }
        <ProgressBar className="progress-bar-intro" percentage={ percentage }/>

        { /* Wrapper div for setting the animation direction */}
        <div className={`animation-dir-${direction}`}>
    
          { /* Animate changes */}
          <AnimatedSwitch transition='step-transition' animateNested={ true } timeout={ 500 }>
            { routes  }
          </AnimatedSwitch> 

        </div>

      </Container>

    </Page>
  )
}

export default Intro