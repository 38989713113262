import { createSelector } from 'reselect'
import { isItReallyAnObject } from '../../tools/object'

// get all pathways from state
const getPathwaysState = ( { pathways } ) => pathways

// get user pathways from state
export const getUserOrgPathways = ( { auth, pathways } ) => (  
  // check (in auth) if current user is set, authenticated and validated and exists
    auth.id && auth.authenticated && auth.validated && pathways.hasOwnProperty( auth.org_id ) 
    // return stored user if so
    ? pathways[ auth.org_id ]
    // otherwise return null; make sure to check for this in the other selectors!
    : null
)

// get all pathways for the user organization
export const getPathways = createSelector(
  [ getUserOrgPathways ],
  ( pathways ) => pathways ? pathways : {}
)

// see if we have any pathways
export const getPathwaysLoaded = createSelector(
  [ getUserOrgPathways, getPathwaysState ],
  ( pathways, state ) => {
    // if we have pathways, great!
    if ( pathways ) return true
    // no longer processing but if we have errors? loading is done. 
    if ( !state.processing && !state.success ) return true
    // otherwise we're still loading
    return false
  }
)

// get only goals; no longer supported by the API
export const getGoals = createSelector(
    [ getPathways ],
    ( pathways ) => {
      // goals to be returned
      let goals = {}
      // go over each pathway
      for( const name in pathways )
      {
        // skip pathways without a goal object
        if ( pathways[ name ].goal && 'object' === typeof pathways[ name ].goal && !Array.isArray( pathways[ name ].goal ) )
        {
          // add the goals object
          goals[ name ] = pathways[ name ].goal
        }
      }
      // return the new goals
      return goals
    }
)

// get pathways + chapters details
export const getPathwaysDetails = createSelector(
  [ getPathways ],
  ( pathways ) => {
    // goals to be returned
    let details = {}
    // go over each pathway
    for( const name in pathways )
    {
      if ( pathways[ name ] && isItReallyAnObject( pathways[ name ] ) )
      {
        // add the details object
        details[ name ] = {
          pathway: name,
          title: pathways[ name ].title,
          text: pathways[ name ].text,
          chapters: pathways[ name ].chapters
        }
      }
    }
    // return the new goals
    return details
  }
)

// get only steps
export const getSteps= createSelector(
  [ getPathways ],
  ( pathways ) => {
    // steps to be returned
    let steps = {}
    // go over each pathway
    for( const name in pathways )
    {
      // skip pathways without steps
      if ( pathways[ name ].steps )
      {
        // add the goals object
        steps[ name ] = pathways[ name ].steps
      }
    }
    // return the steps
    return steps
  }
)