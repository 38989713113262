export const actionTypes = 
{
    USER_SET: 'USER_SET',

    USER_SET_AVATAR: 'USER_SET_AVATAR',  
    USER_SET_AVATAR_SUCCES: 'USER_SET_AVATAR_SUCCES',  
    USER_SET_AVATAR_ROLLBACK: 'USER_SET_AVATAR_ROLLBACK', 

    USER_SET_PROGRESS: 'USER_SET_PROGRESS',
    USER_SET_CURRENT_STEP: 'USER_SET_CURRENT_STEP',
    USER_SET_HISTORY: 'USER_SET_HISTORY',
    USER_SET_STEPS: 'USER_SET_STEPS',
    USER_BEGIN_STEP: 'USER_BEGIN_STEP',
    USER_COMPLETE_STEP: 'USER_COMPLETE_STEP',
    USER_SET_GOAL: 'USER_SET_GOAL',
    USER_SET_PATHWAY_ORDER: 'USER_SET_PATHWAY_ORDER',
    USER_SELECT_PATHWAY: 'USER_SELECT_PATHWAY',

    USER_SET_PATHWAY_POPUP: 'USER_SET_PATHWAY_POPUP',

    USER_ADD_TASK: 'USER_ADD_TASK',
    USER_FINISH_TASK: 'USER_FINISH_TASK',
    USER_REMOVE_TASK: 'USER_REMOVE_TASK',

    USER_UPDATE_PROGRESS: 'USER_UPDATE_PROGRESS',
    USER_UPDATE_PROGRESS_SUCCES: 'USER_UPDATE_PROGRESS_SUCCES',
    USER_UPDATE_PROGRESS_ROLLBACK: 'USER_UPDATE_PROGRESS_ROLLBACK',

    USER_ADD_BOOKMARK: 'USER_ADD_BOOKMARK',
    USER_REMOVE_BOOKMARK: 'USER_REMOVE_BOOKMARK',
    USER_TOGGLE_BOOKMARK: 'USER_TOGGLE_BOOKMARK'
  
}
