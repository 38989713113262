import { createSelector } from 'reselect'
import { getPathways as getUserOrgPathways } from '../pathways/selectors'
import { schemaPathway } from '../pathways/schema'
import { schemaPathwayProgress, schemaTask, schemaUser } from './schema'
import { isItReallyAnObject } from '../../tools/object'
import config from '../../config'

// get current logged in user
const getCurrentUser = ( { auth, users } ) => (    
    // check (in auth) if current user is set, authenticated and validated and exists
    auth && auth.id && auth.authenticated && auth.validated && users.hasOwnProperty( auth.id ) 
    // return stored user if so
    ? users[ auth.id ]
    // otherwise return null; make sure to check for this in the other selectors!
    : null
)

// get user
export const getUser = createSelector(
    [ getCurrentUser ],
    ( user ) => isItReallyAnObject( user ) ? schemaUser( user ) : null
)

// get user id
export const getId = createSelector(
    [ getUser ],
    ( user ) => user ? user.id : ''
)

// get user name
export const getName = createSelector(
    [ getUser ],
    ( user ) => user ? user.name : ''
)

// get user login name
export const getLogin = createSelector(
    [ getUser ],
    ( user ) => user ? user.login : ''
)

// get user language
export const getLang = createSelector(
  [ getUser ],
  ( user ) => user ? user.lang : 'en'
)

// get user locale
export const getLocale = createSelector(
  [ getLang ],
  ( lang ) => config.language.locales.hasOwnProperty( lang ) ? config.language.locales[ lang ] : lang
)

// Get bookmarks for the current user
export const getBookmarks = createSelector(
  [ getUser, getUserOrgPathways, ],
  ( user, pathways ) => {

    // return an array of bookmarks
    const output = []

    // get user bookmarks
    const bookmarks = user && user.hasOwnProperty( 'bookmarks' ) && isItReallyAnObject( user.bookmarks ) ? user.bookmarks : {}

    // go over each pathway in the bookmarks
    for ( const pathway_id in bookmarks )
    {
      // make sure the pathway exists
      if ( pathways.hasOwnProperty( pathway_id ) )
      {
        // shortcut to the pathway
        const pathway = pathways[ pathway_id ]

        // go over each step in the bookmarks
        for ( const step_id in bookmarks[ pathway_id ] )
        {
          // make sure the step exists
          if ( pathway.steps.hasOwnProperty( step_id ) )
          {
            // shortcut to the step
            const step = pathway.steps[ step_id ]

            // gather all relevant info for the bookmark
            output.push({
              pathway: pathway_id,
              pathway_title: pathway.title,
              step: step_id,
              step_title: step.title,
              step_intro: step.intro,
              date: bookmarks[ pathway_id ][ step_id ]
            })
          }
        }
      }
    }
    // return the array of bookmark details
    return output
  }
)

// Get progress for the current user
// Progress array has order, most important first (according to the user)
// Each pathway object contains { goal: '', steps: {}, current: '', history: [] }
export const getProgress = createSelector(
    [ getUser ],
    ( user ) => {
      // get progress or return an empty array
      const progress = user && user.hasOwnProperty( 'progress' ) && Array.isArray( user.progress ) ? user.progress : []
      // make sure all required fields are present
      progress.map( p => schemaPathwayProgress( p.pathway, p ) )
      // return the modified object
      return progress      
    }
)

export const getFeedback = createSelector(
    [ getUser ],
    ( user ) => {

        // double check user && progress
        if ( !user || !user.hasOwnProperty( 'progress' ) || !Array.isArray( user.progress ) ) return 0

        // feedback counted
        let count = 0
        // feedback total
        let total = 0

        // go over pathways array
        for( const p of user.progress )
        {
            // go over steps object ( s is the key )
            for( const s in p.steps )
            {
                // shortcut to the step
                const step = p.steps[ s ]
                // count steps with feedback
                if ( step.hasOwnProperty( 'feedback' ) )                
                {
                    // make sure its a number greater than zero
                    const feedback = 1 * step.feedback
                    if ( !isNaN( feedback ) && feedback > 0 )
                    {
                        count++;
                        total += feedback
                    }
                }
            }
        }

        // return feedback number
        return Math.round( total / count )
    }

)

// get pathways for the current user
// has steps merged with history so history is chronological with detailed step data
// include user so updates to the user trigger selector refresh
export const getPathways = createSelector(
    [ getUserOrgPathways, getUser ],
    ( all_pathways, user ) => {    

        // output data is an array with pathways
        // pathways have an order; most important one first (as chosen by the user)
        const data = []

        // return an empty array if we're missing data
        if ( !user || !all_pathways || !isItReallyAnObject( all_pathways ) ) return data

        // get progress from user
        const { progress: all_progress } = user

        // all pathways should be available in progress so add them when missing
        Object.keys( all_pathways ).forEach( pathway_id => 
        {
          // shortcut to the pathway
          const pathway = all_pathways[ pathway_id ]
          // make sure pathway has steps and a title
          if ( Object.keys( pathway.steps ).length && pathway.root  && pathway.title )
          {
            // see if progress contains pathway
            if ( all_progress.filter( p => p.pathway === pathway_id ).length === 0 )
            {
              // just add the id, schema will be applied in the next loop
              all_progress.push( { pathway: pathway_id } )
            }
          }
        } )

        // go over all the pathways in the user progress
        for( let progress of all_progress )
        {
            // get the pathway id
            const pathway_id = progress.hasOwnProperty( 'pathway' ) ? progress.pathway : ''

            // make sure the pathway exists in the all_pathways object
            if ( pathway_id && all_pathways.hasOwnProperty( pathway_id ) )
            {
                // make sure all props are in progress before continuing
                progress = schemaPathwayProgress( pathway_id, progress ) 

                // shortcut to the current pathway
                const pathway = schemaPathway( all_pathways[ pathway_id ] )

                // get first pathway step, it's required so should not be empty
                const first_step = pathway.root 

                // if current is not yet set, use the first step as the current step
                if ( !progress.current ) 
                {
                    progress.current = first_step
                }

                // make sure the first / current step exists in the steps object
                if ( !progress.steps.hasOwnProperty( progress.current ) )
                {
                    progress.steps[ progress.current ] = {}
                }

                // go over progress steps and make sure it matches up with the schema
                progress = schemaPathwayProgress( pathway_id, progress )

                // get bookmarks for this pathway, default to null
                const bookmarks = user.bookmarks.hasOwnProperty( pathway_id ) && isItReallyAnObject( user.bookmarks[ pathway_id ] ) 
                  ? user.bookmarks[ pathway_id ] 
                  : null 

                // set bookmark booleans
                if ( bookmarks )
                {
                  for( const step_id in progress.steps )
                  {
                    progress.steps[ step_id ].bookmark = bookmarks.hasOwnProperty( step_id )
                  }
                }

                // remove empty values, duplicates and anything that's not a string, and check that the value is a step ID
                progress.history = progress.history.filter( ( h, i ) => (
                    h && typeof h === 'string' && progress.history.indexOf( h ) === i && progress.steps.hasOwnProperty( h )
                ) )

                // add first step if history is empty
                if ( progress.history.length === 0 && first_step )
                {
                    progress.history.push( first_step )
                }

                // check to make sure that timeline items after a not-done item never have the done state set to true
                let timeline_done = true
                
                // get timeline augmented with step data
                let timeline = progress.history.map( ( step_id, index ) => 
                {
                    // skip broken / empty history items
                    if ( step_id && pathway.steps.hasOwnProperty( step_id ) )
                    {                        
                        const merged_step = {
                            // use pathway step as a base and  merge in user data, if any
                            ...mergeStep(
                                pathway.steps[ step_id ],
                                progress.steps.hasOwnProperty( step_id ) ? progress.steps[ step_id ] : {} 
                            ),
                            // if this is not the end of the history, the next step should be done
                            // next_done: index < progress.history.length - 1,
                            // set current value
                            current: step_id === progress.current,
                            // set step id
                            id: step_id,
                            // set pathway id
                            pathway: pathway_id,
                            // set timeline index
                            index: index           
                        } 

                        // make sure steps after a not-done step are also not done
                        if ( !timeline_done && merged_step.done )
                        {
                          merged_step.done = false
                        }
                        else if ( !merged_step.done )
                        {
                          timeline_done = false
                        }

                        return merged_step
                    }
                    // return null on failure, will be filtered out below
                    return null
                } ).filter( i => i !== null )
                
                // get the last item in the timeline
                let last_item = timeline.length >= 0 ? timeline[ timeline.length - 1 ] : null
                
                // by now we should have at least one 'current' step in the timeline, if so find them
                let current_items = timeline ? timeline.filter( t => t.current ) : null

                // we can only have one current item ...
                let current_item = null

                // ... so use the first of the current items
                if ( current_items.length > 0 )
                {
                    current_item = current_items[ 0 ]
                }
                
                // when nothing was found, assume the last step is the current step
                if ( current_items.length === 0 && last_item )
                {
                    // last item must be the current item
                    last_item.current = true
                    current_item = last_item
                }

                // get current index
                const current_index = current_item ? current_item.index : 0

                // go over the timeline to apply offset relative to the current item
                // so current item has index 0, the one before that -1, the one after the current item +1
                for ( const item of timeline )
                {
                    item.offset = item.index - current_index
                }

                // the last item in the history sets the total progress of this pathway
                const percentage = last_item ? last_item.percentage : 0

                // add future steps but limit to max_before_after
                let last_item_count = 0
                while ( last_item && last_item_count )
                {
                    // only continue if step has ONE next step
                    if ( !last_item.steps || last_item.steps.length !== 1 )
                    {
                        last_item = null
                    }
                    else
                    {
                        // get next ID
                        const next_id = last_item.steps[ 0 ].next

                        // stop if history already contains this id
                        if ( progress.history.includes( next_id ) )
                        {
                            last_item = null
                        }
                        else
                        {
                            const merged_step =  {                                
                              // add pathway step and merge in user data, if any
                              ...mergeStep( 
                                  pathway.steps.hasOwnProperty( next_id ) ? pathway.steps[ next_id ] : {},
                                  progress.steps.hasOwnProperty( next_id ) ? progress.steps[ next_id ] : {}
                              ),
                              // default data
                              id: next_id,
                              index: timeline.length,
                              pathway: pathway_id,
                              // this is the future so steps can never be current
                              current: false,
                              // offset relates to the current item
                              offset: timeline.length - current_index
                            }

                            // make sure steps after a not-done step are also not done
                            if ( !timeline_done && merged_step.done )
                            {
                              merged_step.done = false
                            }
                            else if ( !merged_step.done )
                            {
                              timeline_done = false
                            }

                            // add future history item to timeline
                            timeline.push( merged_step )
                            // continue the loop
                            last_item = timeline[ timeline.length - 1 ]
                            // limit loop
                            last_item_count++
                        }
                    }
                }

                // current chapter number and percentage
                let curr_chapter_num = 0
                let curr_chapter_perc = 0

                // get the last step in the history
                const last_step_id = progress.history.length > 0 ? progress.history.slice( -1 )[ 0 ] : ''
                const last_step = last_step_id && pathway.steps.hasOwnProperty( last_step_id ) ? pathway.steps[ last_step_id ] : null
                const last_percentage = last_step ? last_step.percentage : 0

                // add chapter progress
                const chapters = pathway.chapters.map( ( chapter, chapter_index ) => 
                {
                  let chapter_percentage = 0
                  const start_step = pathway.steps.hasOwnProperty( chapter.start ) ? pathway.steps[ chapter.start ]  : null                  
                  const start_percentage = start_step ? start_step.percentage : 0
                  const next_chapter = pathway.chapters[ chapter_index + 1 ]
                  const end_step = next_chapter && pathway.steps.hasOwnProperty( next_chapter.start ) ? pathway.steps[ next_chapter.start ] : null
                  const end_percentage = end_step ? end_step.percentage : 100
                  const chapter_range = end_percentage - start_percentage

                  // no need to calculate if last step indicates this chapter has been done
                  if ( last_percentage >= end_percentage )
                  {
                    // all done!
                    chapter_percentage = 100                     
                  }
                  // no use in calculating if start step is not present or chapter wasn't started
                  else if ( last_step && start_step && progress.history.includes( chapter.start ) )
                  {                      
                    // current percentage is the difference between the percentage of the chapter start step and the percentage of the current step
                    chapter_percentage = ( last_step.percentage - start_step.percentage ) / chapter_range
                  }          

                  // remember current chapter percentage and number
                  if ( chapter_percentage > 0 )
                  {
                    curr_chapter_num = chapter_index
                    curr_chapter_perc = chapter_percentage
                  }
                  
                  // return a new object
                  return {
                    ...chapter,       
                    pathway: pathway_id,              
                    done: chapter_percentage >= 100,
                    percentage: chapter_percentage
                  }
                } )

                // return a lot of data
                data.push({
                    // pathway id / slug
                    pathway_id: pathway_id,
                    // pathway title comes from the details as it differs per language / organization
                    pathway_name: pathway.title, // previously pathway.goal.title,
                    // chapter titles and steps
                    chapters: chapters,
                    // goal title, has been removed
                    // goal_name: goal ? goal.name : '',
                    // pathway steps
                    steps: pathway.steps,
                    // get first step, either as specified in the goal or just the first root step from the pathway
                    first: first_step,
                    // timeline is about the same as history but with step data added and a projection into the future
                    timeline: timeline,                    
                    // user progress, this is the object that can be modified and update in different screens
                    progress: progress,
                    // done percentage based on the last step in the history, not the current step
                    percentage: percentage,
                    // current chapter number
                    chapter: curr_chapter_num,
                    // current chapter percentage
                    chapter_percentage: curr_chapter_perc
                })
            }
        }    
        
        return data
    }
)

/**
 * Merge pathway and progress step
 * @param {*} pathway_step 
 * @param {*} progress_step 
 */
const mergeStep = ( pathway_step, progress_step ) => {

    // build base step by overwriting pathway step with progress step
    const step = {
        ...pathway_step,
        ...progress_step
    }
    
    // merge in task properties instead of overwriting
    step.task = {
        ...getStepTask( pathway_step ),
        ...getStepTask( progress_step ),
    }

    // clean up again
    step.task = getStepTask( step )

    // return the new step
    return step;
}

/**
 * Try to get a task from a step, or null on any failure or doubt
 * @param {*} step 
 */
const getStepTask = ( step = null ) => (
  // step and task need to be an object, task needs to have keys
  isItReallyAnObject( step ) && step.hasOwnProperty( 'task' ) && isItReallyAnObject( step.task ) && Object.keys( step.task ).length > 0
    // return the task if all conditions are met
    ? schemaTask( step.task )
    // return null on any failure
    : null
)

// get avatar
export const getAvatar = createSelector(
    [ getUser ],
    ( user ) => user ? user.avatar : ''
)

// see if this user needs to do the intro
export const needsIntro = createSelector(
    [ getUser ],
    ( user ) => {

        // bail early if there is no user yet
        if ( !user ) return false

        // get what we need from the user
        const { 
            avatar = '', 
            //progress = null
        } = user

        // intro is needed when the avatar is empty
        return !avatar

        /*

        // see if the user has chosen at least one active pathway to work on
        // if there is no progress yet we definitely need an intro
        const active = progress 
          ? progress.filter( p => p.active === true ).length > 0 
          : true

        // show intro when:
        // - no avatar is chosen
        // - no progress has been made
        // - no active pathway exists ih the progress
        return !avatar || !Array.isArray( progress ) || progress.length === 0 || !active
        */
    }
)

// get tasks
export const getTasks = createSelector(
  [ getUser ],
  ( user ) => {
      // empty list when there's no user
      if ( !isItReallyAnObject( user ) ) return []

      // get progress from user
      const { progress = null } = user

      // empty list when there's no progress
      if ( !Array.isArray( progress ) ) return []

      // task list
      const tasks = []

      // go over each pathway
      progress.forEach( pathway => 
      {        
        // check pathway
        if ( pathway.hasOwnProperty( 'steps' ) && isItReallyAnObject( pathway.steps ) )
        {
          // go over each pathway step
          for( const step_id in pathway.steps )
          {
            // we need a step id, sometimes it's an empty string?
            if ( step_id )
            {
              // get the step object
              const step = pathway.steps[ step_id ]
              
              // we need a valid task object, step.task can be null when the user has not added the task yet
              if ( step.hasOwnProperty( 'task' ) && isItReallyAnObject( step.task ) )
              {                
                // add task object that can later be modified and passed to an action
                const new_task = {
                  // pathway id
                  pathway: pathway.pathway,
                  // step id
                  id: step_id,
                  // task object
                  task: {
                    ...step.task,
                    // add summary without tags
                    summary: step.task.content.replace(/(<([^>]+)>)/gi, " ")
                  }
                }
                tasks.push( new_task )
              }
            }
          }
        }
      } )

      // return the tasks list
      return tasks
  }
)

/**
 * Count the number of tasks still todo
 */
export const getTaskCount = createSelector(
  [ getTasks ],
  ( tasks ) => {    
    let total = 0
    for ( const task of tasks )
    {
      if ( task && task.hasOwnProperty( 'task' ) && task.task.hasOwnProperty( 'done' ) && !task.task.done)
      {
        total++
      }
    }
    return total
  }
)

/**
 * Get pathways with tasks
 */
export const getTaskPathways = createSelector(
  [ getTasks, getUserOrgPathways ],
  ( tasks, pathways ) => {  
    const data = {}
    for( const task of tasks )
    {
      data[ task.pathway ] = pathways[ task.pathway ].title
    }
    return data
  }
)