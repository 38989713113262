import React from 'react'
import Confirm from '../../components/confirm'
import IcomoonIcon from '../../components/icomoon-icon'
import { useDispatch } from 'react-redux'
import { removeTask } from '../../redux/users/actions'
import { useTranslation } from 'react-i18next'

const TaskTrashConfirm = ( { task = null, onClose = null, ...rest } ) => 
{
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // bail if there's no task
  if ( !task ) return null

  // confirm trash
  const onTrashConfirm = () =>
  {
    dispatch( removeTask( task.pathway, task.id ) )
    if ( onClose ) onClose()
  }

  // cancel trash
  const onTrashCancel = () =>
  {
    if ( onClose ) onClose()
  }

  // get trash confirm bar contents
  const getTrashBar = ( task ) => 
  (
    <React.Fragment>
      <IcomoonIcon icon="check-on"/>
      { t( 'tasks.trash_title', 'Remove task' ) } 
    </React.Fragment>
  )
  return (
    <Confirm
      bar={ getTrashBar( task ) }
      onConfirm={ onTrashConfirm }           
      onCancel={ onTrashCancel }
      { ...rest }        
      >
      { t( 'tasks.trash_confirm', 'Are you sure you want to delete this task?' ) }
    </Confirm>
  )
}

export default TaskTrashConfirm