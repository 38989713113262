import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// redux
import { useSelector } from 'react-redux'
import { getTaskPathways, getTasks } from '../redux/users/selectors'

// components
import { Container } from '../components/containers'
import Page from '../components/page'
import { AssetButton } from '../components/toggle-buttons'
import PathwayIcon from '../components/pathway-icon'
import { DropdownList, DropdownListItem } from '../components/dropdown-list'
import Popup from './tasks/task-popup'
import IcomoonIcon from '../components/icomoon-icon'
import { DateBlock } from '../components/date'
import FilterList from '../components/filter-list'
import { Button } from '../components/form'
import TaskTrashConfirm from './tasks/task-trash-confirm'
import TaskButton from './tasks/task-button'

// tasks page component
const Tasks = () => 
{
  // hooks
  const { t } = useTranslation()

  // current task
  const [ active_task, setActiveTask ] = useState( '' )
  const [ trash_task, setTrashTask ] = useState( '' )

  // type shown; '' = all, 'done', 'todo'
  const [ filter_status, setFilterStatus ] = useState( '' )

  // pathways filter
  const [ filter_pathway, setFilterPathway ] = useState( '' )

  // get all tasks from user
  const tasks = useSelector( getTasks )

  // get pathways with tasks
  const task_pathways = useSelector( getTaskPathways )

  // filter tasks
  const filtered_tasks = tasks.filter( task => 
  {
    let show = true

    // filter status
    if ( filter_status )
    {
      show &= ( filter_status === 'todo' && task.task.done === 0 ) || 
              ( filter_status === 'done' && task.task.done !== 0 )
    }

    // filter pathway
    if ( filter_pathway )
    {
        show &= filter_pathway === task.pathway
    }

    return show
  } )

  // get pathways items for the dropdown
  const getPathwayItems = () =>
  {
    const items = [ 
      <DropdownListItem 
        key='all' 
        value='' 
        asset={ <PathwayIcon pathway="none"/> }
        label={ t( 'tasks.filter_pathways_all', 'All pathways') }
      /> 
    ]

    for( const pathway in task_pathways )
    {
      items.push( 
        <DropdownListItem 
          key={ pathway }
          value={ pathway } 
          asset={ <PathwayIcon pathway={ pathway }/> }
          label={ task_pathways[ pathway ] }
        /> )
    }
    return items
  }

  // clos modal by setting the task to null
  const closeModal = () =>
  {    
    // something weird in the modal prevents the state to be set if not wrapped in a timeout
    setTimeout( () =>  setActiveTask( '' ), 0 )
  }

  // reset filters
  const onReset = () =>
  {
    setFilterPathway( '' )
    setFilterStatus( '' )
  }

  const getAsset = ( task ) => (
    <React.Fragment>    
      <PathwayIcon pathway={ task.pathway } />
      { task.task.done > 0 ? <IcomoonIcon icon='done'/> : null }  
    </React.Fragment>
  )

  // filter dropdowns
  const getFilters = () =>
  {
    return (
      <React.Fragment>
        <DropdownList 
          /* prefix={ t( 'tasks.show_status_prefix', 'Show') } */
          className="filter filter-status" 
          onChange={ setFilterStatus }
          value={ filter_status }
        >

          <DropdownListItem 
            key='all' 
            value='' 
            label={ t( 'tasks.filter_status_all', 'Done & todo') }
            asset={ <IcomoonIcon/> }
          />

          <DropdownListItem 
            key='done' 
            value='done' 
            label={ t( 'tasks.filter_status_done', 'Done') }
            asset={ <IcomoonIcon icon="check-on"/> }
          />

          <DropdownListItem 
            key='todo' 
            value='todo' 
            label={ t( 'tasks.filter_status_todo', 'Todo') }
            asset={ <IcomoonIcon icon="check-off"/> }
          />

        </DropdownList>

        <DropdownList 
          /* prefix={ t( 'tasks.show_prefix', 'Show') } */
          className="filter filter-pathway" 
          onChange={ setFilterPathway }
          value={ filter_pathway }
        >
          { getPathwayItems() }
        </DropdownList>
      </React.Fragment>
    )
  }

  const onTrash = ( task = null, e = null ) => 
  {
    if ( task ) setTrashTask( task.id )
    if ( e )    e.stopPropagation()     
  }

  const onCloseTrash = () =>
  {
    setTrashTask( '' )
  }

  // get filtered items
  const getItems = () => 
  {
    if ( !tasks || tasks.length === 0 )
    {
      return (
        <div className="empty">
          <h2>{ t( 'tasks.empty_title', 'You have no tasks yet' ) }</h2>
          <p>{ t( 'tasks.empty_text', 'You can find tasks in the pathways, look for the button below to add them.' ) }</p>   
          <TaskButton task={ {} } className="dummy">{ t( 'tasks.add_dummy', 'Add a task' ) }</TaskButton>    
        </div> 
      )
    }

    if ( !filtered_tasks || filtered_tasks.length === 0 )
    {
      return (
        <div className="empty">
          <h2>{ t( 'tasks.filters_empty_title', 'No tasks found' ) }</h2>
          <p>{ t( 'tasks.filters_empty_text', 'Please use a different combination of filters.' ) }</p>          
        </div>
      )
    }

    return filtered_tasks.map( ( task, index ) => (
      <AssetButton
        key={ index }
        asset={ getAsset( task ) }
        className={ task.task.done > 0 ? 'done' : '' }
        onClick={ () => setActiveTask( task.id ) }
      >         
        <div className="text">
          <div className="title">
            <span className='pathway'>{ task_pathways[ task.pathway ] } <IcomoonIcon icon="arrow"/> </span>{ task.task.name }
          </div>
          <div className="summary">{ task.task.summary.slice( 0, 150 ) }</div>
        </div>
        <DateBlock timestamp={ task.task.added }/>  
        <Button key="trash" icon="trash" round onClick={ ( e ) => onTrash( task, e ) }/>              
        <Popup 
          show={ task.id === active_task } 
          onClose={ closeModal } 
          onTrash={ onTrash }
          data={ task }
        />
        <TaskTrashConfirm task={ task } show={ task.id === trash_task  } onClose={ onCloseTrash }/>
      </AssetButton> 
    ) )
  }

  // render tasks page
  return (

    <Page page="tasks">

      <Container center={ true } large={ true }>

        <h1 className="icon-check-on page-title">{ t( 'tasks.page_title', 'Task list' ) }</h1>
        <p>{ t( 'tasks.page_description', 'Do these tasks to complete your pathways.' ) }</p>

        <FilterList filters={ getFilters() } className="tasks-list" onReset={ onReset } disabled={ !tasks || !tasks.length }>
          { getItems() }
        </FilterList>

      </Container>      

    </Page>
  )
}

export default React.memo( Tasks )