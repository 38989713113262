import React from 'react'

// components
import { Tooltip } from './tooltip'
import { LeftArrowButton, RightArrowButton } from '../../components/form'
import Html from '../../components/html-content'

/**
 * Timeline item, with a dot, tooltip and popup
 * @param {*} props 
 */
 const TimelineItem = ( props ) => 
 {  
   let { 
     item = null, 
     className = '', 
     doPrev = null, 
     doNext = null, 
     disablePrev = false,
     disableNext = false,
     ...rest
   } = props
 
   if ( !item )
   {
     className += ' item item-empty'
     // return an empty div when we have no data
     return <div className={ className }/>
   }
 
   // add default classes
   className += ' item item-' + item.id + ' offset-'  + Math.abs( item.offset )
 
   // add before / after current class
   if ( item.offset > 0 ) className += ' after-current' 
   if ( item.offset < 0 ) className += ' before-current' 
 
   // if ( item.index === 0 ) className += ' first'
   if ( item.current ) className += ' active'

   // done means green, not 100% finished
   if ( item.done )      className += ' done'
   if ( item.next_done ) className += ' next-done'
   if ( !item.steps || !item.steps.length ) className += ' end done'

   let icon = item.done ? 'icon-done' : 'icon-question' 
   if ( !item.steps || !item.steps.length ) icon = 'icon-smiley'
 
   return (
     <div className={ className }>
 
       { /* Show smiley icon when user has given feedback */}
       { <div className={ 'status ' + icon } /> }
 
       { /* content div for centering stuff underneath the dot */}
       <div className='content'>
 
         { /* label, use title as a fallback */ }
         <div className='label'><Html>
           { item.time && item.time.label ? item.time.label : item.title }
         </Html></div>

          { /* Wrapper div so we can align tooltip & buttons, set fade based on current state */}
          <div className="tooltip-wrap">          
      
            { /* tooltip contains useful stuff */ }
            <Tooltip key="tooltip" item={ item } { ...rest} />
    
            { /* show left arrow if we have a doPrev method */ }
            { doPrev ? <LeftArrowButton key="left" disabled={ disablePrev || item.index === 0 } onClick={ doPrev }/> : null }
    
            { /* show right arrow if we have a doPrev method */ }
            { doNext ? <RightArrowButton key="right" disabled={ disableNext } onClick={ doNext }/> : null }

          </div>
 
       </div>
 
     </div>
   )
 }

 export default TimelineItem