import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

// redux
import { useSelector } from 'react-redux'
import { getPathwaysDetails } from '../../redux/pathways/selectors'
import { getProgress } from '../../redux/users/selectors'

// components
import { ToggleButtonGroup, AssetButton } from '../../components/toggle-buttons'
import PathwayIcon from '../../components/pathway-icon'
import Html from '../../components/html-content';
import PathwayPopup from './popup'
import { IntroButtons } from './buttons'

const IntroPathways = ( { goPrev = null, goNext = null, doSkip = null } ) => 
{
  // add hooks
  const { t } = useTranslation()

  // use state to control which popup to show
  const [ popup, showPopup ] = useState( null )

  // get all pathways from store
  const details = useSelector( getPathwaysDetails )

  // get all progress, includes pathway order and active state
  const progress = useSelector( getProgress )

  // see if any pathway has been marked active
  const hasActive = progress.filter( p => p.active ).length > 0

  // see if a pathway has been activated
  const isActive = ( pathway ) => (
    progress.filter( p => p.pathway === pathway && p.active ).length > 0
  )

  // get pathway buttons
  const getButtons = () => 
  {    
    const buttons = []
    for ( const pathway in details )
    {
      const pathway_details = details[ pathway ]
      // check for a valid pathway, we need goals and a title
      if ( pathway_details && pathway_details.title )
      {
        buttons.push( 
          <React.Fragment>
            <AssetButton 
              key={ pathway }          
              title={ pathway_details.title } 
              asset={ <PathwayIcon pathway={ pathway }/> } 
              onClick={ () => showPopup( pathway ) }
              //disabled={ pathway_details.text === '' }
              active={ isActive( pathway ) }
            /> 
            <PathwayPopup 
              data={ pathway_details }
              asset={ <PathwayIcon pathway={ pathway }/> } 
              show={ popup === pathway } 
              onClose={ () => showPopup( null ) }
            />
          </React.Fragment>
        )
      }
    }

    return buttons
  }

  return (
    <div className='goals-step goals-step-pathway'>      

      <h1>
        <Html className="title">{ t('intro.pathways_title', 'Pathways') }</Html>
      </h1>

      <p><Html>{  t( 'intro.pathways_intro', 'Which pathways would you like to work on? Click on a pathway to learn more about it. There you can choose if you want to work on this pathway.' ) }</Html></p>  

      { /* Button group with all the pathways, eachg button opens a popup */}
      <ToggleButtonGroup type='large'>
        { getButtons() }
      </ToggleButtonGroup>

      { /* Prev / skip / next buttons, next is disabled if no pathway has been chosen yet */ }
      <IntroButtons goNext={ goNext } goPrev={ goPrev } doSkip={ doSkip } disableNext={ !hasActive } />
   
    </div>
  )
}

export default IntroPathways