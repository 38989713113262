import React from 'react'
import { useTranslation } from 'react-i18next'
import { IntroButtons, IntroConfirm } from './buttons'
import { Container } from '../../components/containers'

const IntroText = ( { name = '', goNext = null, goPrev = null, doSkip = null } ) => {
  const { t } = useTranslation()
  return (
    <div key="step-1" className="intro-goals-text"> 

      <Container center padding>

        { /** Dangerous! But the only way to get the HTML example into the export file */} 
        <div  dangerouslySetInnerHTML={ {__html: t( 'intro.goals', `<h1>Introduction</h1>
        <h2>What comes after freedom?</h2>
        <p>You will soon be free again, but what comes after that?<br/>
        It's an opportunity to start with a clean slate and think about what you actually want with your life.</p>
        <h1>What steps do you need to take?</h1>
        <p>You will determine the step you need to take by choosing from several pathways you would like to progress in.</p>` ) } }/>    

        <IntroConfirm onClick={ goNext } fullwidth={ false } center>{ t( 'intro.button_pathways', "Let's choose some pathways" ) }</IntroConfirm>

      </Container>

      <IntroButtons goPrev={ goPrev } goNext={ goNext } doSkip={ doSkip } nextText={ t( 'intro.button_next', "Next step" ) }/>

    </div> 
  )
}

export default IntroText