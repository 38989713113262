import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'
import { selectPathway } from '../../redux/users/actions'

// components
import Popup from '../../components/popup'
import { Button } from '../../components/form'
import Html from '../../components/html-content'

/**
 * Timeline popup, popups when button is clicked
 * @param {*} props 
 */
const PathwayPopup = ( { data = {}, onClose = null, asset = null, className = '', ...rest } ) =>
{  
  // hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // get default stuff from data
  const {
    pathway = '',
    title = '',
    text = '',
    chapters = []
  } = data

  // get chapters html
  const getChapters = () => 
  {
    return chapters.map( ( chapter, index ) => 
    {
      if ( !chapter.text || !chapter.title ) return null;

      return (
        <div className="chapter" key={ index }>
          <h2>{ chapter.title }</h2>
          <p className="text"><Html>{ chapter.text }</Html></p>   
        </div>
      )
   } )
  }

  // click the thumbs buttons
  const onClick = ( e ) => {
    // (de)select the pathway ( true on thumb_up, false in all other cases )
    dispatch( selectPathway( pathway, e.currentTarget.classList.contains( 'icon-thumb_up' ) ) )
    // close the popup
    onClose()
  }

  const getButtons = () => (
    <div className="buttons">
      <Button secondary icon="thumb_down" onClick={ onClick }>{ t('intro.pathways_not_important', "I don't want to work on this" ) }</Button>
      <Button icon="thumb_up" onClick={ onClick }>{ t('intro.pathways_important', "I want to work on this" ) }</Button>
     </div>
  )

  // add classnames
  className += 'pathway-details-popup'

  // get chapters content, if any
  const chapters_content = getChapters()

  // don't show an empty popup
  if ( !text && !chapters_content ) return null
  
  // show pathway popup if it's the current step
  return (
    <Popup className={ className } center={ false } footer={ getButtons() } onClose={ onClose } { ...rest }>   
      <h1>{ asset }{ title }</h1>
      <p className="text"><Html>{ text }</Html></p>        
      { chapters_content }        
    </Popup>
  )
}

export default PathwayPopup