// app inspecific config, overwrite these values in config-app.js
const config_global = {

  // store & api related
  store: {
    // log redux state changes? logger middleware
    log_redux: false,
    // log api request data?
    log_requests: false,
    // use redux offline, doesn't do anything yet
    use_offline: true
  },

  // intro related
  intro: {
    // use avatar or not
    use_avatar: true 
  }, 

  // goals related
  goals: {
    // let user pick a max number of goals
    max: 4,
    // pick at least this number of goals
    min: 2,
    // show goals in header?
    show_header_goals: false,
    // show goal in progress button?
    show_button_goal: false
  },

  // avatar related
  avatar: {
    // maybe not show gender names?
    show_names: false,
    // allow user to skip making a choice?
    show_cancel: true,
    // options
    options: [ 'male', 'female' ]
  },

  // pathways related
  pathways: {
    // number of items to show before the current item
    max_before: 1,
    // number of items to show after the current item
    max_after: 1
  },

  // language related
  language: 
  {
    // available languages, locales not in this list will not be used even if they get loaded from the server
    locales: { 
      'en': 'en-GB', // UK english
      'nl': 'nl-NL', // Dutch, not Belgian
      'it': 'it-IT', 
      'pt': 'pt-PT', 
      'ro': 'ro-RO',  
      'tr': 'tr-TR', 
    },
    // enable i18next debug?
    debug: false
  },

  // remote stuff
  remote: {
    // server path
    server: 'https://bleepsite.eu',
    // api url, prefixed with server path
    api: '/wp-json/bleep/v1/'
  },

  // use speech synthesis?
  speech: false,
}

export default config_global