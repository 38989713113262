import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getAvatar } from '../redux/users/selectors'
import { setAvatar } from '../redux/users/actions'
import { ToggleButtonGroup, AssetButton } from './toggle-buttons'
import { Button } from './form'
import { ReactComponent as Male } from '../assets/avatar/male.svg'
import { ReactComponent as Female } from '../assets/avatar/female.svg'
import config from '../config'

export const AvatarMale = ( props ) => <Male { ...props}/>
export const AvatarFemale = ( props ) => <Female { ...props}/>

// wrapper component for showing asset
export const AvatarAsset = ( { type, ...rest } ) => 
{
  switch( type )
  {
    case 'male':
    case 'man':
      return <Male className="avatar avatar-male" { ...rest} />

    case 'female':
    case 'woman':
      return <Female  className="avatar avatar-female" { ...rest} />

    default:
      return null
  }
}

const AvatarChooser = ( { onDone } ) => 
{
  // avatar can be m or f, empty when no choice was made, dash(-) when user decided not to make a choice
  // use old avatar as default state for new avatar
  const[ avatar, changeAvatar ] = useState( useSelector( getAvatar ) )
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // listen to onchange from button group and update local state
  const onChange = ( a ) => 
  {
    if ( a !== avatar )
    {
      // update local state
      changeAvatar( a )
    }
    else
    {
      // continue on double (or second) click
      onConfirm()
    }
  }

  // store avatar in store
  const onConfirm = () => {
    dispatch( setAvatar( avatar ) )
    onDone()
  }

  // cancel stores a near empty value
  const onCancel = () => {
    changeAvatar( '-' )
    dispatch( setAvatar( '-' ) )
    onDone()
  }

  // get list of buttons from config
  const getButtons = () =>
  {
    let buttons = []
    for ( const index in config.avatar.options )
    {
      const name = config.avatar.options[ index ]
      const title = config.avatarshow_names ? t( `avatar.${name}`, `{name}`) : ''

      buttons.push(
        <AssetButton active={ avatar === name } key={ name } title={ title } asset={ <AvatarAsset type={ name } /> } />
      )
    }
    return buttons
  }

  return (
    <div className="avatar-chooser">    
        <ToggleButtonGroup type='large' selected={ avatar } onChange={ onChange }>
          { getButtons() }
        </ToggleButtonGroup>
        <Button key="submit" fullwidth={ true } onClick={ onConfirm } disabled={ avatar === ''  }>{ t('avatar.button_confirm', 'Ok, this looks nice') }</Button>
        <Button key="cancel" secondary={ true } fullwidth={ true } onClick={ onCancel }>{ t('avatar.button_cancel', "I don't want to choose an avatar") }</Button>
    </div>
  )
}

export default AvatarChooser