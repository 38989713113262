import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'
//import config from '../config'

// redux
import { useSelector } from 'react-redux'
import { getName, needsIntro, getPathways, getAvatar } from '../redux/users/selectors' 

// components
import { Container } from '../components/containers'
import { AssetButton, ToggleButtonGroup } from '../components/toggle-buttons'
import PathwayIcon from '../components/pathway-icon'
import { AvatarAsset } from '../components/avatar-chooser'

import Overlay from '../components/overlay'
import Page from '../components/page'
import ProgressVisuals from '../components/progress-visuals'
import PathwayChapters from '../pages/intro/pathway-chapters'
import ProgressBar from '../components/progress-bar'
import Html from '../components/html-content'
import Clouds from './pathways/clouds'
import { Button } from '../components/form'

/**
 * Sort pathways on progress and disabled
 * @param {*} a 
 * @param {*} b 
 */
const sortPathways = ( a, b ) => 
{
  // check if pathway is disabled
  const a_disabled = !a.first || a.steps.length === 0 
  const b_disabled = !b.first || b.steps.length === 0 
  
  // put disabled items at the bottom
  if ( a_disabled && !b_disabled ) return 1
  if ( !a_disabled && b_disabled ) return -1
  
  // put fully completed pathways at the bottom
  if ( a.percentage >= 100 && b.percentage < 100 ) return 1
  if ( a.percentage < 100 && b.percentage >= 100 ) return -1

  // put more recent things at the top
  if ( a.progress.updated < b.progress.updated ) return 1
  if ( a.progress.updated > b.progress.updated ) return -1

  // otherwise leave unchanged
  return 0
 }


const Home = () => 
{
  // hooks
  const { t } = useTranslation()
  const history = useHistory()

  // selectors
  const name = useSelector( getName )
  const avatar = useSelector( getAvatar )
  const showIntro = useSelector( needsIntro )
  const pathways = useSelector( getPathways )

  // make an avatar first and set some goals, if needed
  if ( showIntro )
  {
    return <Redirect to="/intro/1"/>
  }

  // allow chapter to be clicked when done
  const onClickChapter = ( e, chapter ) =>
  {
    // chapter needs to be done and start step needs to be known
    if ( chapter.done && chapter.start )
    {
      // go to the first step of the chapter
      history.push( '/pathways/' + chapter.pathway + '/' + chapter.start )
      // prevent pathway button click
      e.stopPropagation()
    }
  }

  // sort pathways
  const sortedPathways = pathways.sort( sortPathways )

  return (

    <Page page="home">

      <Container center={ true } large={ true }>

        { /* Show text first */ }

        <div className="header">

          <h2>{ t('home.title', 'Welcome') } { name }</h2>

        </div>

        { /* Next show background, it will be pushed back by z-index */ }

        <div className="background">
          <div className="sky" key="sky"></div>
          <Clouds key="clouds"/>

          { /* Progress visuals that go behind the mountain */ }
          <ProgressVisuals pathways={ pathways } place="behind"/>

          <div className="mountain" key="mountain"></div>

          { /* Progress visuals that go between mountain and avatar */ }
          <ProgressVisuals pathways={ pathways } place="middle"/>

          <AvatarAsset type={ avatar } className="avatar animate-intro" key="avatar"/>

          { /* Progress visuals that go in front of the avatar */ }
          <ProgressVisuals pathways={ pathways } place="front"/> 
        </div>

        { /* Overlay goes over background but underneath header and buttons */ }

        <Overlay/>

        { /* Buttons go at the bottom */ }

        <ToggleButtonGroup className="progress">
          {
            // go over all pathways
            sortedPathways
              // map pathways to return progress buttons
              .map( ( pathway, index ) => <AssetButton
                key={ pathway.pathway_id + '-' + index }
                className={ 'progress-button ' + ( pathway.percentage === 100 ? 'done' : '' ) }
                active={ false }
                asset={ <PathwayIcon pathway={ pathway.pathway_id }/> }
                disabled={ !pathway.first || pathway.steps.length === 0 }
                onClick={ () => { history.push( '/pathways/' + pathway.pathway_id + '/' + ( pathway.progress.current ? pathway.progress.current : pathway.first ) ) } }
              >
                <Html className="title" tag="h2">{ pathway.pathway_name }</Html>
                <ProgressBar percentage={ pathway.percentage } withCheck={ true }/>
                <Button icon="arrow" className="round go"/>
                <PathwayChapters 
                  tag="b"
                  chapters={ pathway.chapters }
                  withText={ false } 
                  withCheck={ true } 
                  onClick={ onClickChapter }
                />
              </AssetButton> )
          }
        </ToggleButtonGroup>

      </Container>

    </Page>
  )
}

export default React.memo( Home )