/*
  Listen to redux-offline changes 
  and put it into it's own state
*/

const INITIAL_STATE = {
  rehydrated: false,
  last_action: ''
};

/**
 * Get last action, filter out offline & persist
 * @param {*} state 
 * @param {*} action 
 */
const getLastAction = ( state, action = '' ) =>
{
  if ( !action.type || action.type.indexOf( 'Offline/' ) === 0 || action.type.indexOf( 'persist/' ) === 0 )
  {
    return state.last_action
  }
  return action.type
}
  
const onlineReducer = ( state = INITIAL_STATE, action ) => 
{
  switch ( action.type ) 
  {
    case 'persist/REHYDRATE':
      return {
        ...state,
        ...INITIAL_STATE,
        ...{ 
          last_action: getLastAction( state, action ),
          rehydrated: true 
        }
      }

    default:
      return {
        ...state,
        ...{ last_action: getLastAction( state, action ) }
      }
  }
}

export default onlineReducer