import { getLanguages } from './actions'

/**
 * Update languages once from the server when the store rehydrates
 * @param {*} store 
 */
export const updateLanguagesOnce = ( { getState, dispatch, unsubscribe } ) => 
{
  const { online } = getState() 
  if ( online.rehydrated )
  { 
    // remove event before dispatch
    unsubscribe()
      
    // update languages 
    dispatch( getLanguages() )
  }
}