import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// redux
import { useDispatch } from 'react-redux'
import { toggleBookmark } from '../../redux/users/actions'

// components
import Popup from '../../components/popup'
import { Button, LeftArrowButton, RightArrowButton } from '../../components/form'
import { AssetButton, ToggleButtonGroup } from '../../components/toggle-buttons'
import Html from '../../components/html-content'
import TaskPopup from '../tasks/task-popup'
import BookmarkButton from '../../components/bookmark-button'
import { stopSpeech, SpeechButton } from '../../components/speech-button'
import TaskButton from '../tasks/task-button'

/**
 * Timeline popup, popups when button is clicked
 * @param {*} props 
 */
const PathwayPopup = ( props ) =>
{  
  // hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()  

  // popup state
  const [ showTaskPopup, toggleTaskPopup ] = useState( false )

  const { 
    data = {}, 
    onClose = null, 
    doPrev = null, 
    doNext = null,
    doStep = null, 
    animationTime = 500,
    className = '', 
    ...rest 
  } = props

   // get default stuff from data
   const {
    steps = [],
    task = null,
    pathway = '',
    title = '',
    intro = '',
    text = '',
    img = '',
    // current step id
    id = '',
    // next step id
    next = '',
  } = data  

  // click bookmark button
  const clickBookmark = () => dispatch( toggleBookmark( pathway, id ) )

  // show task popup
  const onShowTaskPopup = () => 
  {
    stopSpeech()
    toggleTaskPopup( true )    
  }

  // on close popup event
  const onClosePopup = () =>
  {
    stopSpeech()
    if ( onClose ) onClose()
  }

  // hide task popup
  const onHideTaskPopup = () => toggleTaskPopup( false )
  
  // check if the current item has a valid task
  const has_task = task && ( !steps || steps.length <= 1 ) 

  // task text needs to be set before render so we can include it in text2speech
  let task_text = ''
  if ( has_task )
  {
    // task button text
    task_text = task.added 
      ? t( 'pathways.task_show_button', 'Show task:' )
      : t( 'pathways.task_add_button', 'Add task:' )

    task_text += ' ' + task.name
  } 

  // get bookmark button
  const getBookMarkButton = () =>
  {
    const text = data.bookmark
      ? t( 'pathways.bookmark_button_remove', 'Remove from favorites' )
      : t( 'pathways.bookmark_button_add', 'Add to favorites' )

    return <BookmarkButton onToggle={ clickBookmark } active={ data.bookmark }>{ text }</BookmarkButton>
  }

  const getText2Speak = () => 
  {
    // initial array contains title, intro & text
    let content = [
      {
        text: title + '.  ', 
        elQuery: '.popup-' + id + ' h1',
      },
      {
        text: intro + '.  ', 
        elQuery: '.popup-' + id + ' .intro'
      },
      {
        text: text + '.  ',
        elQuery: '.popup-' + id + ' .text'
      }
    ] 

    // add task
    if ( task_text )
    {
      content.push( {
        // build dom query
        elQuery: '.popup-' + id + ' button.task',
        // use hover class for focus
        elClass: 'hover',
        // add text
        text: task_text + '.  '
      } )
    }
    
    // add steps 
    steps.forEach( ( step, index ) => {
      content.push( {
        // build dom query
        elQuery: '.popup-' + id + ' .step-' + index,
        // use hover class for focus
        elClass: 'hover',
        // add text
        text: step.button + '.  '
      } )
    })
    return content
  }

  // top bar contents
  const getBar = () => (
    <React.Fragment>
      { getBookMarkButton() }
      <SpeechButton text={ getText2Speak() } /> 
    </React.Fragment>
  )

  // get nav buttons to be placed outside of popup content
  const getNav = () => (
    <div className="nav">
      <LeftArrowButton className="step-prev" onClick={ doPrev } disabled={ !doPrev }/>
      <RightArrowButton className="step-next" onClick={ doNext } disabled={ !doNext }/>
    </div>
  )

  /**
   * Get item buttons
   */
  const getSteps = () =>
  {
    // maybe bail early
    if ( !steps || steps.length === 0 ) return null

    // return a list of buttons
    return steps.map( ( step, index ) => {
       return <AssetButton 
        active={ step.next === next }
        className={ 'step-' + index + ( step.next === next ? ' active' : '' ) }
        key={ 'step-' + index } 
        onClick={ () => doStep( step.next ) }
        title={ step.button }
        square='true'
      ><Button icon="arrow" className="round"/></AssetButton>
     } )
  }
  
  return (
    <React.Fragment>

      { /* Pathway popup */ }
      <Popup 
        className={ className + ' pathways-popup popup-' + id }
        transitionTime={ animationTime }
        image={ img } 
        onClose={ onClosePopup } 
        bar={ getBar() }
        after={ getNav() }
        { ...rest }
      >
      
        <h1>{ title }</h1>
        <div className="intro"><p><Html>{ intro }</Html></p></div>
        <div className="text"><Html>{ text }</Html></div>      
        <TaskButton task={ task } onClick={ onShowTaskPopup }>{ task_text }</TaskButton>
        <ToggleButtonGroup type="medium">{ getSteps() }</ToggleButtonGroup>           

      </Popup>
      
      { /* Task popup goes outside of the pathway popup otherwise we can never close the pathway popup without removing the task popup as well */ }
      <TaskPopup data={ data } show={ showTaskPopup } onClose={ onHideTaskPopup }/>

    </React.Fragment>
  )
}

export default PathwayPopup