import { actionTypes } from './types'
import { getToken, setToken, removeToken } from './token'

// used for storing hashed passwords
import sha256 from 'crypto-js/sha256';

const token = getToken()

// initial state is the user object itself
const INITIAL_STATE = {
    // current or last auth token
    token: token ? token : '',    
    // authenticated or not? depends if the token has been verified  
    authenticated: false,    
    // validated is set to false when user is being validated, ie; waiting for a response from the server
    // it's set to true if token has been validated or when authentication failed
    // can be true even if there is no token and auth is false
    validated: false, 
    // processing is true if waiting for a server response
    processing: false,
    // username for greeting the user (this is not the same as the username!)
    name: '',     
    // user id, so far not used 
    id: '',          
    // organisation id, so far not used
    org_id: '',  
    // username last used for login  
    login: '',
    // password last used for login 
    password: '',
    // terminated is true when server returned an unauthenticated message
    terminated: false,
    // any message to display, maybe on termintation or failed login
    message: '',
    // user data retrieved from login or test, we'll update the users model with this data later
    user: null,
    // last error message from server
    error: ''
}

/**
 * Login user
 * - action.payload contains form data
 * - reset current user data
 * - set username
 * - save hashed password
 * @param {*} state 
 * @param {*} action 
 */
const login = ( state, action ) => ({
    ...state,
    // overwrite with default state
    ...INITIAL_STATE,
    // overwite specific values
    processing: true,
    // reset token
    token: '',
    // assume name is the same as the login
    name: action.payload.username,
    // prestore login name
    login: action.payload.username,
    // store hashed password, it's not very secure though ...
    password: sha256( 'bleepthis' + action.payload.password + action.payload.username ).toString()              
})

/**
 * Extract auth data from action payload
 * @param {*} action 
 */
const getAuthData = ( payload ) =>
{
    let data = {}

    // move user data to auth state if they have matching keys
    if ( payload.data.user )
    {
        // go over all keys
        for( const key in payload.data.user  )
        {
            // if key exists in initial state
            if ( INITIAL_STATE.hasOwnProperty( key ) )
            {
                // copy it over
                data[ key ] = payload.data.user[ key ]
            }
        }

        // add full user object
        data.user = payload.data.user
    }    

    // set a default error message if none was passed on an invalid request
    if ( !payload.success && !payload.hasOwnProperty( 'error' ) )
    {
        data.error = 'error'
    }

    // return the data
    return data;
}

/**
 * Handle login success
 * - action.payload contains full request
 * - action.action.payload.data.user contains new user data
 * @param {*} state 
 * @param {*} action 
 */
const loginSuccess = ( state, action ) => {

    // get token
    let token = ''
    if ( action.payload.data.success && action.payload.data.user.token )
    {
        // store token
        token = action.payload.data.user.token 
        setToken( token  )
    }

    // return new user object
    return ({
        ...state,
        // merge in auth data
        ...getAuthData( action.payload ),
        // assume user is authenticated and validated after login
        processing: false,
        authenticated: token.length > 0, 
        validated: true
    })
}

/**
 * Rollback login attempt
 * @param {*} state 
 * @param {*} action 
 */
const loginRollback = ( state, action ) => ( {
    ...state,
    error: action.payload.hasOwnProperty( 'message' ) ? action.payload.message.toLowerCase() : 'network error',
    processing: false,
    authenticated: false,
    validated: true,
    net_error: true
} )

/**
 * Get user was success, aka the auth token was valid
 * @param {*} state 
 * @param {*} action 
 */
const testOnlineSuccess = ( state, action ) => ({
    ...state,
    // merge in auth data
    ...getAuthData( action.payload ),
    // assume test was a success when server returned succes
    processing: false,
    authenticated: action.payload.data.success,
    validated: true
})

/**
 * When get user failed, validation took place but authentication failed
 * @param {*} state 
 * @param {*} action 
 */
const testOnlineRollback = ( state, action ) => ({
    ...state,
    // user is not authenticated but validation did take place
    processing: false,
    authenticated: false,
    validated: true
})

/**
 * Test authentication offline
 * @param {*} state 
 * @param {*} action 
 */
const testOffline = ( state, action ) => {
    return state
}

/**
 * Logout user; remove token and revert to initial state
 * @param {*} state 
 * @param {*} action 
 */
const logout = ( state, action ) => {
    removeToken()
    return ( {
        ...state,
        ...INITIAL_STATE,
        validated: true
    } )
}

/**
 * Logout success; just set a success message
 * @param {*} state 
 * @param {*} action 
 */
const logoutSuccess = ( state, action ) => {
    return ( {
        ...state,
        message: 'loggedout' // just set the default message, assume logout was a succes regardless of server results
    } )
}

/**
 * Reset auth and validated on rehydrate since user needs to be rechecked after rehydrate
 * @param {*} state 
 * @param {*} action 
 */
const rehydrate = ( state, action ) => ( {
    ...state,
    processing: false,
    authenticated: false,
    validated: false
} )

/**
 * Reset auth state and token on terminate
 * @param {*} state 
 * @param {*} action 
 */
const terminate = ( state, action ) => {
    // remove token
    removeToken()
    // reset some of the state
    return ( {
        ...state,
        user: null,
        processing: false,
        authenticated: false,
        // validated needs to be true to remove the slash screen
        validated: true,
        terminated: true,
        message: action.payload ? action.payload : ''
    })
}

/**
 * Reset user data in auth object
 * @param {*} state 
 * @param {*} action 
 */
const removeData = ( state, action ) => ( {
    ...state,
    user: null
} )

/**
 * User reducer to be exported
 * @param {*} state 
 * @param {*} action 
 */
const authReducer = ( state = INITIAL_STATE, action ) =>
{    
    switch ( action.type )
    {
        case 'persist/REHYDRATE':
            return rehydrate( state, action )

        case actionTypes.AUTH_LOGIN:
            return login( state, action )

        case actionTypes.AUTH_LOGIN_SUCCESS:
            return loginSuccess( state, action )

        case actionTypes.AUTH_LOGIN_ROLLBACK:
            return loginRollback( state, action )

        case actionTypes.AUTH_TEST_ONLINE_SUCCESS:
            return testOnlineSuccess( state, action )
        
        case actionTypes.AUTH_TEST_ONLINE_ROLLBACK:
            return testOnlineRollback( state, action )

        case actionTypes.AUTH_TEST_OFFLINE: 
            return testOffline( state, action )
            
        case actionTypes.AUTH_LOGOUT:        
            return logout( state, action )

        case actionTypes.AUTH_LOGOUT_SUCCESS:        
        case actionTypes.AUTH_LOGOUT_ROLLBACK:
            return logoutSuccess( state, action )

        case actionTypes.AUTH_TERMINATE:        
            return terminate( state, action )

        case actionTypes.AUTH_USER_DATA_SUCCESS:
            return removeData( state, action )
        
        default:
            return state;
    }
}

export default authReducer