import React, { useState, useLayoutEffect, useRef } from 'react'

// components
import Modal from 'react-modal';

/**
 * General popup
 * @param {*} props 
 */
const Popup = ( props ) =>
{   
  // default properties
  const { 
    children = null, 
    show = false, 
    onClose = null, 
    image = null,
    center = true,
    header = null,
    footer = null,
    before = null,
    after = null,
    bar = null,
    transitionTime = 500,
    ...rest
  } = props

  // modal / popup open state
  const [ showPopup, setShowPopup ] = useState( false )

  // use a ref to set some styles and classes
  const popupRef = useRef( null )

  // set styles and classes
  const setStyles = () =>
  {
    // bail early if popupRef is empty
    if ( !popupRef || !popupRef.current ) return
      
    const elOverlay = popupRef.current.node.querySelector( '.ReactModal__Overlay' )  
    if ( elOverlay )
    {
      // modal doesn't allow style to be set so we hack it this way
      elOverlay.style.setProperty( '--transition-time', transitionTime + 'ms' );
    }

    // find the content element
    const elContent = popupRef.current.node.querySelector( '.content' )   
    if ( elContent )
    {   
      // check if a footer was set
      if ( footer )
      {
        // find the footer element
        const elFooter = elContent.querySelector( '.footer' )
        if ( elFooter )
        {
          elContent.style.paddingBottom = elFooter.offsetHeight  + 'px'
        }
      }
      // check if a header was set
      if ( header )
      {
        // find the header element
        const elHeader = elContent.querySelector( '.footer' )
        if ( elHeader )
        {
          elContent.style.paddingTop = elHeader.offsetHeight + 'px'
        }
      }
    }
  }

  // update state when show property changes
  useLayoutEffect( () => {    

    // update local state
    setShowPopup( show )

    // use a timeout to allow the modal component to do it's thing before styles and classes can be set on modal content elements
    setTimeout( setStyles, 0 )    

  }, [ show ] )

  // add classnames to the actual popup
  // BleepApp classname is required because Modal puts the element outside of the root
  // this would otherwise cause missing styles
  let className = ' BleepApp popup'  
  // add has/no image class
  className += image ? ' has-image' : ' no-image'
  // maybe center content
  className += center ? ' center' : ''
  // add (border)container classes
  className += ' container borders'
  // has bar?
  className += bar ? ' has-bar' : ''

  // close popup
  const onClosePopup = ( e ) =>
  {
    // set local state
    setShowPopup( false )    
    
    // maybe call external close handler
    if ( onClose ) onClose()    

    e.stopPropagation()
  }

  // render modal / popup
  return ( 
    <Modal 
        isOpen={ showPopup } 
        onRequestClose={ onClosePopup }        
        shouldCloseOnOverlayClick={ true }
        closeTimeoutMS={ transitionTime } // for close animation
        style={ { '--transition-time': transitionTime + 'ms' } }
        ref={ popupRef }
        { ...rest }
      >   

      { /* Use an actual element ( not a pseudo element ) to block click-through */ }
      <div key="bg" className="popup-bg" onClick={ e => e.stopPropagation() }></div>

      { before }

      <div key="popup" className={ className }>  
      
        { /* top bar, has fixed position */ }
        <div className="bar">
          { bar }
          <div className="close-wrap">
            <div className="close button-round icon-x" onClick={ onClosePopup }></div>
          </div>
        </div> 

        <div className="wrap">
        
          { image ? <div className="image"><img src={ image } alt="Popup"/></div> : null }

          { /* content area, everything in here is scrollable */ }
          <div className="content" >

            { header ? <div className="header">{ header }</div> : null }

            <div className="scroller">              
              { children }
            </div>
            
            { footer ? <div className="footer">{ footer }</div> : null }

          </div>

        </div>

      </div>
      
      { after }
      
    </Modal>
  )
}

export default Popup