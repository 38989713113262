import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getAuth } from '../redux/auth/selectors'

/**
 * Private route component, will render component if user is authenticated
 * @param {component} Component to render when the user is authenticated
 */
const PrivateRoute = ( { component: Component, ...rest } ) =>
{
    const redirectTo = '/login'
    const auth = useSelector( getAuth )

    return (
        // don't do anything until user is validated, this prevents redirecting to the login page at the start of the app
        // the splash screen hides the content when user is not validated so screen is 'blank' anyway
        !auth.validated ? null :
            <Route 
                { ...rest } 
                render = { ( props ) => (
                    auth.authenticated ? <Component {...props} /> : <Redirect to={ redirectTo } />
                ) }
            />
    )
}

export default PrivateRoute