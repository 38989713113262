import React from 'react'
import { useLocation } from "react-router-dom"

const RoutedClass = ( { className = '', children, ...rest } ) => 
{
  const location = useLocation()
  className += ' location location' + location.pathname.replace( '/', '-' )
  return <div className={ className } { ...rest }>{ children }</div>
}

export default RoutedClass