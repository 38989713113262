import React from 'react'

const IcomoonIcon = ( { icon = 'none', right = false, className = '', ...rest } ) => 
{
  className += ' icomoon-icon icon icon-' + icon
  if ( right ) className += ' right'
  return <div className={ className } { ...rest } />
}

export default IcomoonIcon
