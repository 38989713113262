import { testOnline, testOffline, testOnlineSkipped } from './actions'
import { getToken } from './token'

/**
 * Validate user once on rehydrate when online
 * @param {*} store 
 */
export const validateAuthOnline = ( { getState, dispatch, unsubscribe } ) =>
{
  const { online, offline } = getState()

  // when state gets rehydrated and we're online
  if ( online.rehydrated && offline.online )
  {
    // remove event before dispatch
    unsubscribe()

    // try to get existing token
    const token = getToken()
    if ( token )
    {
      // test existing auth token
      dispatch( testOnline() )
    }
    else
    {
      // run rollback to reset test states
      dispatch( testOnlineSkipped() )
    }
  }
}

/**
 * Validate user once on rehydrate when offline
 * @param {*} store 
 */
export const validateAuthOffline = ( { getState, dispatch, unsubscribe } ) =>
{
  const { online, offline } = getState()  

  // when state gets rehydrated and we're NOT online
  if ( online.rehydrated && !offline.online )
  {
    // remove event before dispatch
    unsubscribe()

    // test existing auth token
    dispatch( testOffline() )
  }
}