import { actionTypes } from './types'

// initial state is the user object itself
const INITIAL_STATE = {
    processing: false,
    success: false
}

/**
 * Merge loaded pathways with the existing ones
 * @param {*} state 
 * @param {*} action 
 */
const getPathwaysSuccess = ( state, action ) => {

    if ( action.payload.data.success && action.meta.org_id )
    {
        // new pathways
        const pathways = {}
        for( const key in action.payload.data.data )
        {
                  // rename - to _ in keys
            const new_key = key.replace( '-', '_' ),
                  // get pathway
                  pathway = action.payload.data.data[ key ]

            // store pathway data
            pathways[ new_key ] = pathway
        }

        // store update pathways in an object with the organization ID as its key
        return {
            ...state,
            ...{ [ action.meta.org_id ]: pathways },
            processing: false,
            success: true
        }
    }
    
    return state
}

/**
 * User reducer to be exported
 * @param {*} state 
 * @param {*} action 
 */
const pathwayReducer = ( state = INITIAL_STATE, action ) =>
{    
    switch ( action.type )
    {
        case actionTypes.GET_PATHWAYS:
            return {
                ...state,
                processing: true
            }

        case actionTypes.GET_PATHWAYS_SUCCESS:
            return getPathwaysSuccess( state, action )

        case actionTypes.GET_PATHWAYS_ROLLBACK:
            return {
                ...state,
                processing: false,
                success: false
            }
        
        default:
            return state;
    }
}

export default pathwayReducer