import React from 'react'
import { timestamp2day, timestamp2month, timestamp2string, timestamp2year } from '../tools/date';

export const DateBlock = ( { timestamp = 0, className = '', ...rest } ) =>  (      
  <div className={ 'date-block ' + className } >
    <div className="day">{ timestamp2day( timestamp ) }</div>
    <div className="month">{ timestamp2month( timestamp ) }</div>
    <div className="year">{ timestamp2year( timestamp ) }</div>
  </div>
)

export const DateText = ( { timestamp = 0, className = '', ...rest } ) => (
   <span className={ 'date-text ' + className }>{ timestamp2string( timestamp ) }</span>
)

