import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getAuth } from '../redux/auth/selectors'

// auth and api
import { login } from '../redux/auth/actions'

// components
import { Form, Button, LabeledInput } from '../components/form'
import Html from '../components/html-content'
import { Container, SpeechContainer } from '../components/containers'
import Notice from '../components/notice'
import Page from '../components/page'

// translations
import { useTranslation } from 'react-i18next';
import { BleepLogoText } from '../components/bleep-logo';

const Login = ( props ) => 
{    
    const auth = useSelector( getAuth )
    const dispatch = useDispatch()

    // get translation stuff
    const { t } = useTranslation()

    const isDev = process.env.NODE_ENV !== 'production'

    // do state stuff
    const   [ username, setUserName ] = useState( isDev ? 'apper' : '' ),
            [ password, setPassword ] = useState( isDev ? 'apper' : '' )

    // post login details
    const postLogin = ( e )  =>
    {
        dispatch( login( username, password ) )
        e.preventDefault()
        return false
    }
    
    // go home when logged in
    if ( auth.authenticated )
    {
        return <Redirect to="/"/>;
    }

    let notice = null
    let message = ''
    let icon = ''

    if ( !auth.success && auth.error )
    {        
        // check for error messages
        switch ( auth.error )
        {
            case 'network error':
              icon = "connection"
              message = t( 'login.network_error', "Could not reach the server.<br/>Are you connected to the internet?" );
              break;
            case 'invalid':  
                message = t( 'login.invalid', 'The username or password is incorrect, please try again.' );
                break;
            default:
                message = t( 'login.error', 'Something went wrong, please try again.' );
        }
        notice = <Notice type="error" message={ message } icon={ icon } />
    }
    else if ( auth.message )
    {
        // check for success messages
        switch ( auth.message )
        {
            case 'loggedout':  
                message = t( 'login.loggedout', 'You have been logged out' );
                break;
            default:
                message = '';
        }

        if ( message )
        {
            notice = <Notice type="success" message={ message } icon={ icon } />
        }
    }

    // form autocomplete is off, unless when in development mode
    const autoComplete = process && process.env.NODE_ENV === 'development' ? 'on' : 'off'

    return (

        <Page page="login">

            { /* Putting the logo here fixes a bug where the logo is not displayed in the splashscreen */}
            <BleepLogoText className="preload"/>

            <Container center>
            
                <Html tag='h1'>{ t('login.title', 'Welcome to Bleep!') }</Html>
                <Html tag='h3'>{ t('login.subtitle', 'Login and start realizing your dreams.') }</Html>
                <Html tag='p'>{ t('login.intro', 'Please enter your username and password below.') }</Html>

                <SpeechContainer>
                    <Form onSubmit={ postLogin } className={ auth.processing ? 'busy' : '' } autoComplete={ autoComplete }>                    

                        { notice /* maybe show notice / error */ }      

                        <LabeledInput 
                            placeholder={ t('login.username_placeholder', 'Username') } 
                            required={ true }
                            value={ username }
                            onChange={ e => {
                                setUserName( e.target.value );
                            } }
                        >{ t('login.username_label', 'Your username') }</LabeledInput>

                        <LabeledInput  
                            type='password'
                            placeholder={ t('login.password_placeholder', 'Password') } 
                            required={ true }
                            value={ password }
                            onChange={ e => {
                                setPassword( e.target.value );
                            } }
                        >{ t('login.password_label', 'Your password') }</LabeledInput>                  

                        <Button key="submit" fullwidth={ true } type="submit">{ t('login.submit_button', 'Login') }</Button>
                    </Form>
                </SpeechContainer>
            </Container>
        </Page>
    )
}

export default Login