import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// redux
import { useDispatch, useSelector } from 'react-redux'
import { getBookmarks } from '../redux/users/selectors'

// components
import { Container } from '../components/containers'
import Page from '../components/page'
import { AssetButton } from '../components/toggle-buttons'
import PathwayIcon from '../components/pathway-icon'
import { Button } from '../components/form'
import { DropdownList, DropdownListItem } from '../components/dropdown-list'

import { removeBookmark, setPathwayPopup } from '../redux/users/actions'
import { useHistory } from 'react-router'
import { DateBlock } from '../components/date'
import FilterList from '../components/filter-list'
import Confirm from '../components/confirm'
import IcomoonIcon from '../components/icomoon-icon'
import BookmarkButton from '../components/bookmark-button'

// Bookmarks page component
const Bookmarks = () => 
{
  // hooks
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [ popup, setPopup ] = useState( null )

  // get all bookmarks from user
  const bookmarks = useSelector( getBookmarks )

  console.log( bookmarks )
  
  // pathways filter
  const [ filter_pathway, setFilterPathway ] = useState( '' )

  const onTrash = ( bookmark, e ) => 
  {
    setPopup( bookmark.step )
    e.stopPropagation()     
  }

  const onTrashConfirm = ( bookmark ) =>
  {
    setPopup( '' )
    dispatch( removeBookmark( bookmark.pathway, bookmark.step ) )  
  }

  const onTrashCancel = () =>
  {
    setPopup( '' )
  }

  // visit bookmark
  const onVisit = ( bookmark ) => 
  {
    // instruct popup we're opening from a bookmark
    dispatch( setPathwayPopup( bookmark.pathway, bookmark.step, 'bookmark' ) )
    // go to the pathway step
    history.push( '/pathways/' + bookmark.pathway + '/' + bookmark.step )
  }

  // get unique pathway ids and titles
  const pathways = {}
  bookmarks.forEach( bookmark => pathways[ bookmark.pathway ] = bookmark.pathway_title )

  // get pathways items for the dropdown
  const getPathwayItems = () =>
  {
    // add 'all' item
    const items = [ 
      <DropdownListItem 
        key='all' 
        value='' 
        asset={ <PathwayIcon pathway="none"/> } 
        label={ t( 'tasks.filter_pathways_all', 'All pathways') }
      />
    ]

    // add an item per pathway
    for( const pathway in pathways )
    {
      items.push( 
        <DropdownListItem 
          key={ pathway } 
          value={ pathway } 
          label={ pathways[ pathway ] } 
          asset={ <PathwayIcon pathway={ pathway } /> } 
        />
      )
    }
    return items
  }

  // filter tasks
  const filtered_bookmarks = bookmarks.filter( bookmark => {
      let show = true
  
      // filter pathway
      if ( filter_pathway )
      {
          show &= filter_pathway === bookmark.pathway
      }
  
      return show
  } )

  // reset filters
  const onReset = () => 
  {
    setFilterPathway( '' )
  }

  const getBar = ( bookmark ) => 
  (
    <React.Fragment>
      <IcomoonIcon icon="fav-on"/>
      { t( 'bookmarks.trash_title', 'Remove bookmark' ) } 
    </React.Fragment>
  )

  // render bookmark buttons
  const getBookMarks = () => 
  {
    if ( !bookmarks || !bookmarks.length )
    {
      return (
        <div className="empty">
          <h2>{ t( 'bookmarks.empty_title', 'You have no favorites yet' ) }</h2>
          <p>{ t( 'bookmarks.empty_text', 'Click on the star button at the top of an article to add it to this list.' ) }</p>          
          <BookmarkButton className="dummy secondary large">{ t( 'pathways.bookmark_button_add', 'Add to favorites' ) }</BookmarkButton>
        </div>
      )
    }

    if ( !filtered_bookmarks || !filtered_bookmarks.length )
    {
      return (
        <div className="empty">
          <h2>{ t( 'bookmarks.filter_empty_title', 'No favorites found' ) }</h2>
          <p>{ t( 'bookmarks.filter_empty_text', 'Please use a different combination of filters.' ) }</p>          
        </div>
      )
    }

    return (
      filtered_bookmarks.map( ( bookmark, index ) => (
        <AssetButton
          key={ index }
          onClick={ () => onVisit( bookmark ) }
          asset={ <PathwayIcon pathway={ bookmark.pathway } /> }
        >
          { /* Wrap text elements */}
          <div className="text">
            <div className="title">{ bookmark.step_title }</div>
            <div className="summary">{ 
              bookmark.pathway_title
              // bookmark.step_intro.slice( 0, 200 ) 
            }</div>
          </div>
          <DateBlock timestamp={ bookmark.date }/>
          <Button key="trash" icon="trash" round onClick={ ( e ) => onTrash( bookmark, e ) }/>
          <Confirm 
           show={ popup === bookmark.step } 
           bar={ getBar( bookmark ) }
           onConfirm={ () => onTrashConfirm( bookmark ) }           
           onCancel={ onTrashCancel }           
           >
            { t( 'bookmarks.trash_confirm', 'Are you sure you want to delete this bookmark?' ) }
          </Confirm>
        </AssetButton>
      ) )
    )
  }

  // get filter dropdowns
  const getFilters = () => (    
    <DropdownList 
      /* prefix={ t( 'tasks.show_prefix', 'Show') } */
      className="filter filter-pathway" 
      onChange={ setFilterPathway }
      value={ filter_pathway }
    >
      { getPathwayItems() }
    </DropdownList>    
  )

  // render tasks page
  return (

    <Page page="bookmarks">

      <Container center={ true } large={ true }>

        <h1 className="icon-fav-on page-title">{ t( 'bookmarks.page_title', 'Favorites' ) }</h1>
        <p>{ t( 'bookmarks.page_description', 'All your favorite articles in one place.' ) }</p>

        <FilterList filters={ getFilters() } onReset={ onReset } className="bookmarks-list" disabled={ !bookmarks || !bookmarks.length }>
          { getBookMarks() }
        </FilterList>

      </Container>      

    </Page>
  )
}

export default React.memo( Bookmarks )