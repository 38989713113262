import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '../components/containers'
import { Button } from '../components/form'
import Page from '../components/page'
import config from '../config/'

import pen from '../assets/pen.min.jpg'

// Bookmarks page component
const Notes = () => 
{
  // hooks
  const { t } = useTranslation()
  return (

    <Page page="notes">

      <Container center={ true } large={ true }>

        <h1 className="icon-notes page-title">{ t( 'notes.page_title', 'Notes' ) }</h1>

        <div className="text">
          <p>{ t( 'notes.page_description', 'You can access your notes at the Bleep website. Click to button below to go there.' ) }</p>
          <a href={ config.remote.server + '/notes/' } target="_blank" rel="noopener noreferrer" className="notes-link">
            <img src={ pen } alt="Pen" className="pen"/>
            <Button>{ t( 'notes.button_visit', 'Show notes' ) }</Button>
          </a>
          
        </div>
        
      </Container>      

    </Page>
  )
}

export default React.memo( Notes )