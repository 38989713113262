import { actionTypes } from './types'

/**
 * Set user updates the user data from Auth
 * Data is received after login or, in case of a valid token, the test request
 * @param {*} user 
 */
export const setUser = ( user ) => ({
    type: actionTypes.USER_SET,    
    payload: { user }
})

/**
 * Update the avatar on server
 * @param {*} avatar 
 */
export const setAvatar = ( avatar ) => ( {
    type: actionTypes.USER_SET_AVATAR,    
    payload: avatar,
    meta: {
        offline: {
            effect: {
                url: 'user',
                data: { avatar: avatar }
            }
        }
    }
} )

/**
 * Update goals in pathway progress
 * @param {*} goals 
 */
export const setGoal = ( pathway = '', goal = '' ) => ( {
    type: actionTypes.USER_SET_GOAL,    
    payload: {
        pathway: pathway,
        goal: goal
    }
} )

/**
 * Update steps in user pathway progress
 * @param {*} step 
 */
export const setSteps = ( pathway = '', steps = {} ) => ( {
    type: actionTypes.USER_SET_STEPS,    
    payload: {
        pathway: pathway,
        steps: steps
    }
} )

/**
 * Update history in user pathway progress
 * @param {*} step 
 */
export const setHistory = ( pathway = '', history = [] ) => ( {
    type: actionTypes.USER_SET_HISTORY,    
    payload: {
        pathway: pathway,
        history: history
    }
} )

/**
 * Update current step in user pathway progress
 * @param {*} step 
 */
export const setCurrentStep = ( pathway = '', current = '' ) => ( {
    type: actionTypes.USER_SET_CURRENT_STEP,    
    payload: {
        pathway: pathway,
        current: current
    }
} )

/**
 * Update entire progress object for a specific pathway
 * @param {*} step 
 */
export const setProgress = ( pathway = '', progress = {} ) => ( {
    type: actionTypes.USER_SET_PROGRESS,    
    payload: {
        pathway: pathway,
        progress: progress
    }
} )

/**
 * Take (or start, begin) a step, to complete it at once set done to true. Feedback is optional
 * @param {*} step 
 */
 export const beginStep = ( pathway = '', current_step = '', next_step = '', done = false, feedback = 0 ) => ( {
  type: actionTypes.USER_BEGIN_STEP,    
  payload: {
    pathway: pathway,
    step_id: current_step,
    step_next: next_step,
    step_feedback: feedback,
    step_done: done      
  }
} )

/**
 * Complete a step previously started (per beginStep)
 * @param {*} step 
 */
export const completeStep = ( pathway = '', step = '', feedback = 0 ) => ( {
  type: actionTypes.USER_COMPLETE_STEP,    
  payload: {
    pathway: pathway,
    step_id: step,
    step_feedback: feedback,
    step_done: true
  }
} )

export const setPathwayPopup = ( pathway, step_id, show ) => ( {
  type: actionTypes.USER_SET_PATHWAY_POPUP,    
    payload: {
      pathway: pathway,
      step_id: step_id,
      step_popup: show
    }
} )

/**
 * Set pathway order position for a specific pathway
 * @param {*} pathway 
 * @param {*} order 
 */
export const setPathwayOrder = ( pathway = '', order = 0 ) => ( {
  type: actionTypes.USER_SET_PATHWAY_ORDER,    
  payload: {
    pathway: pathway,
    order: order
  }
})

/**
 * (de)select a pathway, ie set the active state 
 * @param {*} pathway 
 * @param {*} order 
 */
 export const selectPathway = ( pathway = '', active = false ) => ( {
  type: actionTypes.USER_SELECT_PATHWAY,    
  payload: {
    pathway: pathway,
    active: active
  }
})

/**
 * Update all user progress
 * @param {*} pathway 
 * @param {*} order 
 */
export const updateProgress = ( progress ) => ( {
  type: actionTypes.USER_UPDATE_PROGRESS,    
  payload: {
    progress: progress
  },
  meta: {
    offline: {
      effect: {
        url: 'user',
        progress: progress
      }
    }
  }
})

/**
 * Add task to user progress
 * @param {*} item = a pathway item 
 */
export const addTask = ( pathway = '', id = '', task = null ) => ( {
  type: actionTypes.USER_ADD_TASK,    
  payload: {
    pathway: pathway,
    step_id: id,
    task: task
  },
})

/**
 * Finish task
 */
 export const finishTask = ( pathway = '', id = '', task = null ) => ( {
  type: actionTypes.USER_FINISH_TASK,    
  payload: {
    pathway: pathway,
    step_id: id,
    task: task
  },
})

/**
 * Remove task
 * @param {*} item = a pathway item 
 */
 export const removeTask = ( pathway = '', id = '' ) => ( {
  type: actionTypes.USER_REMOVE_TASK,    
  payload: {
    pathway: pathway,
    step_id: id,
    task: null
  },
})

/**
 * Add bookmark
 * @param {*} item = a pathway item 
 */
 export const addBookmark = ( pathway = '', id = '' ) => ( {
  type: actionTypes.USER_ADD_BOOKMARK,    
  payload: {
    pathway: pathway,
    step_id: id
  },
})

/**
 * Remove bookmark
 * @param {*} item = a pathway item 
 */
 export const removeBookmark = ( pathway = '', id = '' ) => ( {
  type: actionTypes.USER_REMOVE_BOOKMARK,    
  payload: {
    pathway: pathway,
    step_id: id
  },
})

/**
 * Toggle bookmark
 * @param {*} item = a pathway item 
 */
 export const toggleBookmark = ( pathway = '', id = '' ) => ( {
  type: actionTypes.USER_TOGGLE_BOOKMARK,    
  payload: {
    pathway: pathway,
    step_id: id
  },
})