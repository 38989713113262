// import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import reportWebVitals from './reportWebVitals.js'
// import 'core-js/features/string/repeat'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

// add redux
import { Provider } from 'react-redux'
import store from './redux/store'

// language / translation config
import './i18n/config'

// get token stuff
import { removeToken } from './redux/auth/token'

// App detector
import { Plugins, Capacitor } from '@capacitor/core';

// react modal
import Modal from 'react-modal';

// get root element once
const elRoot = document.getElementById( 'root' ) 

// set modal root once
Modal.setAppElement( elRoot )

// add some attributes for handling x-browser compatibility issues
document.body.setAttribute( 'data-useragent', navigator.userAgent.toLowerCase() );

// check if we're in App or Web to remove token when app closes
if ( Capacitor.isNative )
{
  // get stuff we need
  const { App: AppHandler, BackgroundTask } = Plugins

  // listen for app close in Capacitor
  AppHandler.addListener( 'appStateChange', ( state ) => 
  {     
    // when app goed to the background ..
    if ( !state.isActive ) 
    {
      // schedule a task right before exit
      const taskId = BackgroundTask.beforeExit( async () => {
        // and remove the token
        removeToken()
        // finish the background task
        BackgroundTask.finish( { taskId } )       
      });
    }  
  } )
}
else
{
  
}

// render app
ReactDOM.render(
  <React.StrictMode> 
  
    { /* Redux provider */ }
    <Provider store={ store }>

      { /* Suspense for loading initial languages */ }
      <Suspense fallback={(<div>Loading</div>)}> 

        { /* The actual Bleep App */ }
        <App />

      </Suspense>   

    </Provider>

  </React.StrictMode>
  , elRoot 
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals();