import React from 'react'
import { useTranslation } from 'react-i18next'

// components
import { IntroButtons, IntroConfirm } from './buttons'
import { ToggleButtonGroup, AssetButton } from '../../components/toggle-buttons'
import PathwayIcon from '../../components/pathway-icon'
import Html from '../../components/html-content'
import PathwayChapters from './pathway-chapters'

// redux
import { useDispatch, useSelector } from 'react-redux'
import { getProgress } from '../../redux/users/selectors'
import { getPathwaysDetails } from '../../redux/pathways/selectors'
import { DownArrowButton, UpArrowButton } from '../../components/form'
import { setPathwayOrder } from '../../redux/users/actions'

const IntroSummary = ( { goPrev = null, doSkip = null }) => 
{
  const { t } = useTranslation()

  // get all pathways from store
  const all_pathways = useSelector( getPathwaysDetails )

  // get existing user goals from store
  const all_progress = useSelector( getProgress ) 

  const dispatch = useDispatch()

  // click sort button
  const onSort = ( pathway, order = 0 ) => 
  {
    dispatch( setPathwayOrder( pathway, order ) )
  }

  // get pathway buttons
  const getButtons = () => 
  {    
    // first get selected pathways
    const pathways = []
    for ( let index in all_progress )
    {
      // convert index to number
      index *= 1

      // get progress object
      const current_progress = all_progress[ index ]
      const pathway_name = current_progress.hasOwnProperty( 'pathway' ) ? current_progress.pathway  : ''
      const pathway_active = current_progress.hasOwnProperty( 'active' ) ? current_progress.active  : false

      if ( pathway_name && pathway_active )
      {
        // get matching pathway, double check if pathway exists
        const current_pathway = 
          all_pathways && all_pathways.hasOwnProperty( pathway_name )    
            ? all_pathways[ pathway_name ] 
            : null

        // check if we have 'em all
        if ( current_pathway )
        {
          pathways.push( current_pathway )
        }
      }
    }

    // then build list of buttons
    return pathways.map( ( pathway, index ) => (
      <AssetButton 
        key={ pathway.pathway }  
        title={ ( index + 1 ) + ': ' + pathway.title } 
        asset={ <PathwayIcon pathway={ pathway.pathway }/> }>

        <PathwayChapters 
          chapters={ pathway.chapters } 
          withCheck={ false } 
          withText={ false } 
          withProgress={ false } 
          headerLevel="4"
        />
        
        <div className="sort">
          <UpArrowButton 
            disabled={ index <= 0 }
            onClick={ () => onSort( pathway.pathway, index - 1 ) }
          />
          <DownArrowButton 
            disabled={ index >= pathways.length - 1 } 
            onClick={ () => onSort( pathway.pathway, index + 1 ) }
          />
        </div>
      </AssetButton> 
    ) )
  }

  return (
    <div className="intro-summary-text"> 

      <h1><Html>{ t( 'intro.pathways_summary_title', 'Your pathways' ) }</Html></h1>
      <p><Html>{ t( 'intro.pathways_summary_text', 'You have chosen your most important pathways. Here they are:' ) }</Html></p>

      <ToggleButtonGroup type='large' noClick={ true }>
        { getButtons() }
      </ToggleButtonGroup>

      <IntroConfirm onClick={ doSkip }>{ t( 'intro.summary_button', "Let's begin!" ) }</IntroConfirm>
      <IntroButtons hideNext={ true } goPrev={ goPrev }/>

    </div> 
  )
}

export default IntroSummary