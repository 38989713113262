import React from 'react'

// basic container 
export const Container = ( props ) =>
{ 
    let { 
        className = '', 
        fullwidth = false, 
        center = false, 
        large = false,
        borders = false,
        speech = false,
        light = false,
        children, 
        ...rest 
    } = props

    className += ' container'
    if ( fullwidth ) className += ' fullwidth'
    if ( center ) className += ' center'
    if ( large ) className += ' large'
    if ( borders ) className += ' borders'
    if ( speech ) className += ' speech'
    if ( light ) className += ' light'

    return <div className={ className } { ...rest }>{ children }</div>
}

// container with rounded buttons
export const BorderContainer = ( { className = '', children, ...rest } ) =>
{
    return <Container borders={ true } className={ className } { ...rest }>{ children }</Container>
}

// speech container with arrow up
export const SpeechContainer = ( { className = '', arrow = 'top', children, ...rest } ) =>
{
    className += ' speech'
    if ( arrow ) className += ' arrow-' + arrow
    return <BorderContainer speech={ true } className={ className } { ...rest }>{ children }</BorderContainer>
}