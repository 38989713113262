import React, { useLayoutEffect, useState }  from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from './form'
import Popup from './popup'

const Confirm = ( { 
  onConfirm = null, 
  onCancel = null, 
  children = null, 
  show = false, 
  className = '', 
  confirmText = '', 
  cancelText = '', 
  bar = null,
  ...rest 
} ) =>
{
  const [ display, setDisplay ] = useState( show )
  const { t } = useTranslation()

  if ( !confirmText ) confirmText = t( 'confirm.yes', 'Yes' )
  if ( !cancelText )  cancelText = t( 'confirm.no', 'No' )

  // update state when show property changes
  useLayoutEffect( () => 
  {    
    // update local state
    setDisplay( show )  

  }, [ show ] )

  const onClickConfirm = ( e ) =>
  {
    setDisplay( false )
    if ( onConfirm ) onConfirm()
    e.stopPropagation()
  }

  const onClickCancel = ( e ) =>
  {
    setDisplay( false )
    if ( onCancel ) onCancel()
    e.stopPropagation()
  }

  const getFooter = () => (
    <React.Fragment>
      <Button className="confirm" onClick={ onClickConfirm }>{ confirmText }</Button>
      <Button className="cancel" secondary large onClick={ onClickCancel }>{ cancelText }</Button>
    </React.Fragment>
  )

  return (
    <Popup 
      bar={ bar }
      footer={ getFooter() } 
      show={ display } 
      className={ className + ' confirm' }
      { ...rest }>
      { children }
    </Popup>
  )
}

export default Confirm