import { actionTypes } from './types'

export const getPathways = ( org_id = '' ) => ({
    type: actionTypes.GET_PATHWAYS,
    payload: {
        // token will automatically be added if it exists
    }, 
    meta: {
        offline: {
            effect: {
                url: 'pathways'
            },
            commit: {
                type: actionTypes.GET_PATHWAYS_SUCCESS,
                meta: {
                    org_id: org_id
                }
            },
            rollback: {
                type: actionTypes.GET_PATHWAYS_ROLLBACK
            }
        }
    }
} )