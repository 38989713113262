import React from 'react'
// import { ReactComponent as Logo } from '../assets/logo/bleep.svg'
import LogoSrc from '../assets/logo/bleep.png'
// import { ReactComponent as LogoText } from '../assets/logo/bleep-text.svg'
import LogoTextSrc from '../assets/logo/bleep-text.png'

// export const BleepLogo = ( { className = '' } ) => <div className={ className + ' bleep-logo' }><Logo/></div>
// export const BleepLogoText = ( { className = '' } ) => <div className={ className + ' bleep-logo-text' }><LogoText/></div>

export const BleepLogo = ( { className = '' } ) => <div className={ className + ' bleep-logo' }><img src={ LogoSrc } alt="Bleep Logo"/></div>
export const BleepLogoText = ( { className = '' } ) => <div className={ className + ' bleep-logo-text' }><img src={ LogoTextSrc } alt="Bleep Logo"/></div>