import React from 'react'
import { Redirect } from 'react-router-dom'

// redux
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../redux/auth/actions'
import { getAuthenticated } from '../redux/auth/selectors'

export const Logout = () => 
{    
    const dispatch = useDispatch() 
    const authenticated = useSelector( getAuthenticated )

    if ( authenticated )
    {         
        dispatch( logout() ) 
    }
    
    return <Redirect to='/' />
}

export default Logout