import React, { useLayoutEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom"
import config from '../../config'

// components
import PathwayPopup from './pathway-popup'
import TimelineItem from './timeline-item'
import { stopSpeech } from '../../components/speech-button'

// redux
import { useDispatch } from 'react-redux'
import { setCurrentStep, beginStep } from '../../redux/users/actions'
import { safeIndex } from '../../tools/array'
import { FilterObject } from '../../tools/object'

/**
 * Timeline with items
 * @param {*} props 
 */
const Timeline = ( props ) => 
{  
  // get stuff from props
  let { 
    className = '',
    updateProgress = null,
    pathway = null,
    ...rest
  } = props 
  
  // get pathway things
  const {
    // progress contains what the user has done
    progress = [],
    // timeline contains a merger of steps, history & progress
    timeline = [],
    // name of the pathway
    pathway_id = ''    
  } = pathway

  // get progress things
  const {
    // history contains the order of the steps the user has taken
    history = [],
  } = progress

  const dispatch = useDispatch()  

  // animation time to be passed to timeline and popups
  const animation_time = 500

  // list popups & animation states
  const [ popups, setPopups ] = useState( {} )

  // get step from params and use them as initial values in state
  const { step } = useParams()

   // use location and history to allow the user to use the back button
   const browser_history = useHistory()

  // timeline animation direction is stored in state
  const [ direction, setDirection ] = useState( 'none' )  

  // find index in the history of the current step, for use in doPrev and doNext
  const history_index = step ? history.findIndex( h => h === step ) : -1
        
  // start timeline animation when index changes, useLayoutEffect is a lot smoother than useEffect
  useLayoutEffect( () => {  
    document.body.classList.remove( 'animate-timeline' )
    // skip if there is no animation direction set
    if ( 'none' !== direction )
    {       
      // start animation
      document.body.classList.add( 'animate-timeline' )   
      // cleanup
      setTimeout( () => {
        document.body.classList.remove( 'animate-timeline' )
        setDirection( 'none' )
      }, animation_time + 100 )  
    }
    // only run this if the history index ( that means the pathway step ) changes
  }, [ history_index ] )
 
  // navigate to previous item in the users step history
  const doTimelinePrev = () => goTo( safeIndex( history, history_index - 1 ), 'prev' )
 
  // navigate to the next item in the users step history
  const doTimelineNext = () => goTo( safeIndex( history, history_index + 1 ), 'next' )

  // navigate to previous item and animate popups
  const doPopupPrev = () => 
  {    
    stopSpeech()

    // get what should be the previous step id
    const prev = safeIndex( history, history_index - 1 )

    // if it's the same there's nothing before this step so ignore it
    if ( prev !== step )
    {
      // set popup states in one go
      setPopups( { [ step ]: 'exit-right', [ prev ]: 'enter-left' } )
    }

    // animate the timeline
    doTimelinePrev()
  }

  // navigate to next chosen item and animate popups
  const doPopupNext = () =>
  {
    stopSpeech()
    
    if ( item_current && item_current.next )
    {
      doStep( item_current.next  )
    }
  }

   // do a step by choosing the next step
  const doStep = ( next_step ) => {

    // set this step as done and go to the next one
    dispatch( beginStep( pathway_id, step, next_step, true ) )  

    // set popup states in one go
    setPopups( { [ step ]: 'exit-left', [ step_progress.next ]: 'enter-right' } )

    // navigate to next step, current prop will be set there
    goTo( step_progress.next, 'next' )
  }

  // go to a specific step
  const goTo = ( step_id = '', dir = '' ) => 
  {    
    // unless we're already there
    if ( step_id && step_id !== step )
    {
      // set animation direction
      setDirection( dir )
      // mark the new step as the current step
      dispatch( setCurrentStep( pathway_id, step_id ) )
      // show in browser url
      browser_history.push( '/pathways/' + pathway_id + '/' + step_id )  
    }
  }

  // what to do if there's no step ID in the url?
  if ( !step )
  {
    // progress object should contain the current step
    setTimeout( () => goTo( progress.current ), 100 )    
    return null
  }

  // what to do on step mismatch
  if ( step !== progress.current )
  {
    // make sure the step in the url is the current step
    setTimeout( () => dispatch( setCurrentStep( pathway_id, step ) ), 100 )
    return null
  }

  // set popup state, remove empty values
  const setPopup = ( id, state = '' ) => {
    // filter out empty string values
    const popup_states = FilterObject( 
      // merge in new state
      { ...popups, [ id ]: state }  ,
      // check for empty strings
      v => v !== '' 
    ) 
    // get a list of popups on the exit 
    const exit_popups = FilterObject( popup_states, v => v.indexOf( 'exit-') === 0 )
    if ( Object.keys( popup_states ).length === Object.keys( exit_popups ).length || !Object.keys( popup_states ).length )
    {
      // delay showing progress until the animation is done
      setTimeout( updateProgress, animation_time * 1.2 )
    }
    setPopups( popup_states )
  }
  
  // get popup display state
  const popupState = id => popups.hasOwnProperty( id ) ? popups[ id ] : ''

  // get progress of the current step
  const step_progress = progress.steps.hasOwnProperty( step ) ? progress.steps[ step ] : {}        

  // config defines how many items should be displayed on screen,
  // +1 for each because we need an extra item both left and right to make animations work
  const max_before = config.pathways.max_before + 1
  const max_after = config.pathways.max_after + 1
  // add one to include the active item
  const max_items = max_before + max_after + 1

  // get the current active item index
  const timeline_index = timeline.findIndex( item => item.current )

  // get a list of sliced items
  const items = timeline.slice( 
    // slice all items off that we don't need to render, don't go lower than 0
    Math.max( 0, timeline_index - max_before ),
    // slice ends needs + 1 otherwise the current index gets slice off
    Math.min( timeline.length, timeline_index + max_after + 1 )
   )

  // where is the current item hiding? this is needed to calculate the items we need to add after the current one
  const item_index = items.findIndex( item => item.current )
  const item_current = item_index >= 0 ? items[ item_index ] : null

  // see how many items need be added before and after the sliced items
  const add_before = Math.max( 0, max_before - item_index )
  const add_after = Math.max( 0, max_items - items.length - add_before )

  // add timeline class, animation direction and number of items displayed
  className += ' timeline  animate-' + direction + ' max-' + ( config.pathways.max_before + config.pathways.max_after + 1 )

  // add a number of dummy / empty items to make sure we always have the same amount of items on screen
  const dummyItems = ( ii, className ) =>
  {
    const els = []
    for ( let i = 0; i<ii; i++ )
    {
      els.push( <TimelineItem key={ 'dummy-' + i } className={ className }/> )
    }
    return els
  }

  // should popup be shown or hidden?
  const showPopup = id => {
    // get state or empty string
    const s = popups.hasOwnProperty( id ) ? popups[ id ] : ''
    // show if state is show or begins with enter
    return s === 'show' || s.indexOf( 'enter-' ) === 0
  }  

  // render the timeline
  return (
    <React.Fragment>
      <div className={ className } style={ { '--transition-time': animation_time + 'ms' }  }>
        {
          // maybe add dummy items before
          dummyItems( add_before, 'before' )
        }
        { 
          // add TimelineItems and popups
          items.map( item => (
            <TimelineItem
              className={ "item-" + item.id }   
              key={ "item-" + item.id } 
              item={ item } 
              doPrev={ doTimelinePrev }
              doNext={ doTimelineNext }
              setPopup={ setPopup }            
              disableNext={ item.index === history.length - 1 || !step_progress.next }
              { ...rest } 
            /> 
          ) )
        }
        {
          // maybe add dummy items after   
          dummyItems( add_after, 'after'  )
        }
      </div>
      { 
        // Render popups outside of the div, this fixes transition issues
        items.map( item => (
          <PathwayPopup 
            data={ item } 
            key={ "popup-" + item.id }
            className={ 'animate-' + popupState( item.id ) }
            show={ showPopup( item.id ) }
            onClose={ () => setPopup( item.id ) }   
            doStep={ doStep }
            animationTime={ animation_time }
            doPrev={ item.index === 0 ? null : doPopupPrev }      
            doNext={ item.next ? doPopupNext : null }
          /> 
      ) ) }
    </React.Fragment>
  )
}

export default React.memo( Timeline )