import React from 'react'
import { BleepLogoText }from './bleep-logo'

const SplashScreen = ( { children = null, className = '' } ) => (
  <div className={ `splash-screen ${className}` }>
    <div className="logo">
      <BleepLogoText/>
    </div>
    <div className="content">
      { children }
    </div>
  </div>
) 

export default SplashScreen