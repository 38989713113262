import React from 'react'
import { useTranslation } from 'react-i18next';

// components
import { Button } from '../../components/form'

/**
 * Fullwidth confirm button
 * @param {*} param0 
 */
export const IntroConfirm = ( { children, className = '', ...rest }) =>
{
  const { t } = useTranslation()
  className += ' intro-confirm'
  return (
    <Button className={ className } { ...rest }>
      { children ? children : t('intro.button_confirm', 'Save and next step' ) }
    </Button> 
  )
}

/**
 * Left and right prev / next buttons
 * @param {*} props 
 */
export const IntroButtons = ( props ) => 
{
    const { t } = useTranslation()
    let { 
      className = '',
      goPrev = null, 
      goNext = null, 
      doSkip = null,
      disablePrev = false, 
      disableNext = false, 
      hidePrev = false, 
      hideNext = false,
      nextText = '',
      prevText = '',
      skipText = '',
    } = props

    className += ' intro-buttons'

    return (
      <div className={ className }>
        <Button 
          key="intro_previous"
          secondary 
          hidden={ hidePrev }
          disabled={ disablePrev }
          left
          icon="prev" 
          onClick={ goPrev }
        >{ prevText ? prevText : t('intro.button_back', 'Previous step' ) }</Button>    

        <Button 
          key="intro_skip"
          secondary 
          hidden={ doSkip === null }
          onClick={ doSkip }
          >{ skipText ? skipText : t('intro.button_skip', 'I want to start right now' ) }</Button>

        <Button 
          key="intro_next"
          secondary 
          hidden={ hideNext }
          disabled={ disableNext } 
          right 
          icon="next" 
          onClick={ goNext }
          >{ nextText ? nextText : t('intro.button_next', 'Next step' ) }</Button>

      </div>
    )
}