import axios from 'axios';
import { getToken } from './auth/token'
import { terminate } from './auth/actions'
import config from '../config'

// App detector
import { Capacitor } from '@capacitor/core';

// set the server path
let serverPath = config.remote.server + config.remote.api

// when in web mode, try to use the current domain as server
if ( !Capacitor.isNative )
{
  // exclude localhost, check for 'local' as our localhost domain is using subdomains within the 'local' domain
  if ( window.location.hostname.indexOf( 'local' ) < 0 )
  {
    // assume app is the subdomain where the app is placed in
    const server = window.location.hostname.replace( 'app.', '' )
    serverPath = server + config.remote.api
  }
}

// maybe prefix with https if there is no prefix yet, 
// check for http at the beginning of the string so we capture both http:// and https:// being present in the remote server config
if ( serverPath.indexOf( 'http' ) !== 0 )
{
  serverPath = 'https://' + serverPath
}

// make a server call
export const Request = ( props ) =>
{
  const {
    url = '',
    method = 'POST',
    data = null,
    dispatch = ( e ) => { console.error( 'No dispatch available' ) }
  } = props;  

  const args = {
      method: method,
      timeout: 10 * 1000, // timeout in ms
      headers: { 
        // no other headers or axios will be trying a preflight check with OPTIONS which fails
        'content-type': 'application/x-www-form-urlencoded'
      },
      data: JSON.stringify( {
        // start with blank object
        ...{},
        // merge in new data (or null)
        ...data,
        // add the auth token for auto validation
        ...{ auth_token: getToken() }
       } ),
      // add a random value to prevent caching
      url: serverPath + url + '?r=' + Date.now()
      // maybe solve character encoding issues
      //responseType: 'arraybuffer',
      //reponseEncoding: 'binary'
  };  

  // handle API calls with axios
  const result = axios( args ) 

  // check for invalidated tokens
  result
    .then( e => {
      // negative code forbidden means you are not or no longer authorized
      if ( e.hasOwnProperty( 'code' ) && -1 === e.code )
      {
        // terminate session
        dispatch( terminate() )
      }
    } )
    .catch( e => {
      // terminate session
      dispatch( terminate() )
    } )

  // show debug info, but never in production mode
  if ( config.store.log_requests && process.env.NODE_ENV !== 'production' )
  {
    console.log( 'API Request started', args )
    result
      .then( e => console.log( 'API Request success', e ) )
      .catch( e => console.log( 'API Request error', e ) )
  }

  // return the promise
  return result
}

// export the general request method as a default
export default Request