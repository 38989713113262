export const actionTypes = {
  AUTH_LOGIN: 'AUTH_LOGIN',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_ROLLBACK: 'AUTH_LOGIN_ROLLBACK',

  AUTH_TEST_ONLINE: 'AUTH_TEST_ONLINE',
  AUTH_TEST_ONLINE_SUCCESS: 'AUTH_TEST_ONLINE_SUCCESS',
  AUTH_TEST_ONLINE_ROLLBACK: 'AUTH_TEST_ONLINE_ROLLBACK', 

  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
  AUTH_LOGOUT_ROLLBACK: 'AUTH_LOGOUT_AUTH_LOGOUT_ROLLBACK',

  AUTH_TEST_OFFLINE: 'AUTH_TEST_OFFLINE',
  AUTH_TERMINATE: 'AUTH_TERMINATE',

  AUTH_USER_DATA_SUCCESS: 'AUTH_USER_DATA_SUCCESS'
}