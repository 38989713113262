import React from 'react'

const ProgressBar = ( { percentage = 0, className = '', temp_percentage = 0, withCheck = false } ) => 
{
  // limit percentage between 0 and 100 and round it
  const progress = Math.max( 0, Math.min( 100, Math.round( percentage ) ) )
  // temporary percentage is optional
  const temp_progress = Math.max( 0, Math.min( 100, Math.round( temp_percentage ) ) )
  // add classnames
  className += ' progress-bar'
  // rounded progress number
  className += ' progress-' +  Math.round( progress ) 
  // stepped progress number; useful for color changes
  className += ' progress-step-' + Math.round( progress / 10 ) 
  // rounded temp progress number
  className += ' temp-progress' + Math.round( temp_progress )
   // stepped temp progress number; useful for color changes
  className += ' temp-progress-step' + Math.round( temp_progress / 10 )
  // checkbox icon when done
  className += withCheck && percentage === 100 ? ' icon-done' : ''

  // render the progress bar
  return (
    <div className={ className }>
      <div className="track temp" style={ { width: temp_progress + '%' } }></div>
      <div className="track" style={ { width: progress + '%' } }></div>
    </div>
  )
}

export default ProgressBar