import { between } from "./number";

/**
 * Return an item in an array
 * - return value at index position if if exists
 * - return first value if index is too small
 * - return last value if index is too large
 * @param {*} array 
 * @param {*} index 
 * @returns 
 */
export const safeIndex = ( array, index ) => array[ between( index, 0, array.length - 1 ) ]